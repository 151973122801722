import { AnchorLink, Layout, Text } from "@kidslivesafe/sc-react";
import { StateAbbreviation } from "./stateConstants";

export const renderStateAutoContent = (
  state?: StateAbbreviation,
): JSX.Element => {
  if (!state) {
    return <div>State abbreviation is missing or invalid</div>;
  }

  switch (state) {
    case "AL":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">ALABAMA</Text>
          <Text wordBreak="break-word">
            Submit a records request along with any required documents through
            the AL MyDMV Vehicle Records Portal via the provided link:
            <AnchorLink
              href="https://mydmv.revenue.alabama.gov/TAP/?link=RecordReq"
              wordBreak="break-word"
              inline
            >
              https://mydmv.revenue.alabama.gov/TAP/?link=RecordReq
            </AnchorLink>{" "}
            After the request is reviewed and approved, you will receive an
            email stating that the record is available for payment. There is a
            $10 charge for each record. Government and Law Enforcement agencies
            are not charged. There is also no fee for records retrieved for
            court purposes. For any questions call 334-242-9000 Option 2.
          </Text>
        </>
      );
    case "AK":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">ALASKA</Text>
          <Text wordBreak="break-word">
            Persons eligible under the Driver Privacy Protection Act and Alaska
            statutes may obtain an Alaska Motor Vehicle Record. Complete a
            Request for Vehicle Record Form 851, sign, and date the form, submit
            payment and any required documents to the Alaska DMV. There is a $10
            fee for each record. Government agencies are not charged for this
            service.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Division of Motor Vehicles <br />
              Attn: Research <br />
              1300 W Benson Blvd, Ste. 200 <br />
              Anchorage, AK 99506-3600
            </Text>
            <Text>By Email:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="mailto:doa.dmv.research@alaska.gov"
            >
              doa.dmv.research@alaska.gov
            </AnchorLink>
            <Text>Forms URL:</Text>
            <AnchorLink
              href="https://dmv.alaska.gov/home/"
              wordBreak="break-word"
            >
              DEPARTMENT OF ADMINISTRATION (alaska.gov)
            </AnchorLink>
          </Layout>
        </>
      );
    case "AZ":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">ARIZONA</Text>
          <Text wordBreak="break-word">
            If you are the owner of record on the Arizona title, you may
            purchase a Title and Registration Motor Vehicle Record (MVR):
          </Text>
          <ul>
            <li>
              <Text>
                Online through the Motor Vehicle Record-Title and Registration
                request service on{" "}
                <AnchorLink href="https://azmvdnow.gov/home" inline>
                  AZ MVD Now
                </AnchorLink>
              </Text>
            </li>
            <li>
              <Text>
                By mail using the Motor Vehicle Record Request form{" "}
                <AnchorLink
                  href="https://apps.azdot.gov/files/mvd/mvd-forms-lib/46-4416.pdf"
                  inline
                >
                  46-4416
                </AnchorLink>
              </Text>
            </li>
          </ul>
          <Text wordBreak="break-word">
            If you are a prospective buyer of a vehicle currently titled in
            Arizona, you may ask the vehicle owner to provide you with an
            Arizona MVR prior to purchase of the vehicle.
          </Text>
          <ul>
            <li>
              <Text>
                Vehicles that have not been titled or registered in Arizona
                during the last 5 years may not be eligible for an MVR
              </Text>
            </li>
          </ul>
          <Text>
            A Title and Registration MVR contains the following information:
            Owner, vehicle, lien, and brand.
          </Text>
        </>
      );
    case "AR":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">ARKANSAS</Text>
          <Text wordBreak="break-word">
            Arkansas is under the federal Driver’s Privacy Protection Act and
            will not give out information belonging to someone else. If you need
            information from when you owned the vehicle you may send in a
            written request along with a copy of your driver’s license to
            request the information. Your request must contain the following:
          </Text>
          <ul>
            <li>
              <Text>
                Vehicle specifics such as description of vehicle, VIN, license
                plate number or name with address for an alpha search.
              </Text>
            </li>
            <li>
              <Text>Reason for requesting the information.</Text>
            </li>
            <li>
              <Text>
                Fees are $1.00 for owner/lien holder information and $1.00 for
                each copy of documents requested such as bill of sale,
                contracts, etc.
              </Text>
            </li>
          </ul>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Arkansas Office of Motor Vehicles Records Unit, Room 1100 <br />
              PO Box 1272
              <br />
              LittleRock, AR 72203
            </Text>
            <Text>By Phone:</Text>
            <Text>501.682.4692</Text>
            <Text>By Email:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="mailto:MVInformation@dfa.arkansas.gov"
            >
              MVInformation@dfa.arkansas.gov
            </AnchorLink>
          </Layout>
        </>
      );
    case "CA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">CALIFORNIA</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="https://www.dmv.ca.gov/portal/file/request-for-record-information-inf-70-pdf/"
            >
              State of California Department of Motor Vehicles
            </AnchorLink>
          </Text>
        </>
      );
    case "CO":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">COLORADO</Text>
          <Text wordBreak="break-word">
            Customers can request vehicle record information by completing a
            DR2489A and submitting $2.20 for the record search. Information can
            be found at
            <AnchorLink
              wordBreak="break-word"
              inline
              href="https://dmv.colorado.gov/vehicle-record-searches"
            >
              Vehicle Record Searches | Department of Revenue - Motor Vehicle
              (colorado.gov)
            </AnchorLink>
          </Text>
        </>
      );
    case "CT":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">CONNECTICUT</Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              State of Connecticut Dept of Motor Vehicles Records Information{" "}
              <br />
              60 State St <br />
              Wethersfield, CT 06161
            </Text>
            <Text>By Phone:</Text>
            <Text>860.263.5700</Text>
          </Layout>
        </>
      );
    case "DE":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">DELAWARE</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="https://www.dmv.de.gov/VehicleServices/index.shtml"
            >
              Vehicle Services - Division of Motor Vehicles (de.gov)
            </AnchorLink>
          </Text>
        </>
      );
    case "DC":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">DISTRICT OF COLUMBIA</Text>
          <Text wordBreak="break-word">
            Persons eligible under D.C. Official Code §1401.01b (the D.C. Driver
            Privacy Protection Act (“DPPA”) (D.C. Official Code §1401.01b)), may
            obtain an unredacted Motor Vehicle Record by mail by submitting the
            request, along with payment and any required documents in accordance
            with the DPPA by mail.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              D.C. DMV <br />
              PO Box 90120 <br />
              Washington, DC 20090
            </Text>
          </Layout>
          <Text wordBreak="break-word">
            Or, a request can be made under the same eligibility requirements
            through the Freedom of Information Act (“FOIA”), which should
            include supporting documentation
            <AnchorLink
              wordBreak="break-word"
              inline
              href="https://dmv.dc.gov/page/open-government-and-freedom-information-act-foia"
            >
              DC Department of Motor Vehicles Open Government and the Freedom of
              Information Act.
            </AnchorLink>
            If the FOIA request is made by mail, payment should also be
            included. If the request is made on-line, the requestor will be
            informed as to how payment is to be made. There is a $7.00 fee for a
            title information request. Government agencies are not charged a
            fee.
          </Text>
        </>
      );
    case "FL":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">FLORIDA</Text>
          <Text wordBreak="break-word">
            To request a Motor Vehicle record:
            <AnchorLink
              wordBreak="break-word"
              href="https://www.flhsmv.gov/pdf/forms/90510.pdf"
            >
              90510 (flhsmv.gov)
            </AnchorLink>
            For Motor Vehicle information check:
            <AnchorLink
              wordBreak="break-word"
              href="https://services.flhsmv.gov/MVCheckWeb/"
            >
              Vehicle Information Check (flhsmv.gov)
            </AnchorLink>
          </Text>
        </>
      );
    case "GA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">GEORGIA</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="https://dor.georgia.gov/form-mv-20-request-motor-vehicle-data"
            >
              Form MV-20 Request for Motor Vehicle Data | Georgia Department of
              Revenue
            </AnchorLink>
          </Text>
        </>
      );
    case "ID":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">IDAHO</Text>
          <Text wordBreak="break-word">
            An Idaho Motor Vehicle Record is available to individuals and
            businesses who can provide the vehicle identification number (VIN)
            and title number, and are authorized to receive this information
            under the federal Driver Privacy Protection Act and the provisions
            of Idaho Code §49-203.
          </Text>
          <Text wordBreak="break-word">
            Complete a Request for Vehicle Record online at
            <AnchorLink
              wordBreak="break-word"
              inline
              href="https://www.accessidaho.org/itd/driver/mvr/index"
            >
              Idaho Transportation Department Online Services
            </AnchorLink>
            or by completing and submitting a Motor Vehicle Request
            <AnchorLink
              wordBreak="break-word"
              inline
              href="https://www.accessidaho.org/itd/driver/mvr/index"
            >
              Form 3374
            </AnchorLink>
            with a signature, date, and payment. Credit Card orders may be sent
            via fax or email.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Idaho Department of Transportation <br />
              Attn: Motor Vehicle Records <br />
              PO Box 34 <br />
              Boise, ID 83707-0034 <br />
            </Text>
            <Text>By Email:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="mailto:DMVHelp@itd.idaho.gov"
            >
              DMVHelp@itd.idaho.gov
            </AnchorLink>
            <Text>Forms URL:</Text>
            <AnchorLink
              href="https://itd.idaho.gov/wp-content/uploads/2016/06/3374.pdf"
              wordBreak="break-word"
            >
              Vehicle Services (idaho.gov)
            </AnchorLink>
          </Layout>
        </>
      );
    case "IL":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">ILLINOIS</Text>
          <Text wordBreak="break-word">
            Illinois Persons eligible under the Driver Privacy Protection Act
            and Illinois statutes may obtain an Illinois Motor Vehicle Record.
            Inquiries about registration and title records should be mailed.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Secretary of State <br />
              Motor Vehicle Records Division, Record Inquiry <br />
              Section 501 S. Second St., Rm. 408 <br />
              Springfield, IL 62756 <br />
            </Text>
            <Text>Forms URL:</Text>
            <AnchorLink
              href="http://www.cyberdriveillinois.com/publications/pdf_publications/vsd375.pdf"
              wordBreak="break-word"
            >
              Secretary of State Record Inquiry Section Information Request Form
            </AnchorLink>
          </Layout>
        </>
      );
    case "IN":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">INDIANA</Text>
          <Text wordBreak="break-word">
            <AnchorLink
              wordBreak="break-word"
              href="https://www.in.gov/bmv/titles/title-forms/"
            >
              Indiana Bureau of Motor Vehicles Title Forms;
            </AnchorLink>
            Under the heading: “Obtaining a Vehicle or Watercraft Record” select
            <AnchorLink
              wordBreak="break-word"
              href="https://forms.in.gov/Download.aspx?id=13697"
            >
              “Request for Certified Records – State Form 53789”
            </AnchorLink>
          </Text>
        </>
      );
    case "IA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">IOWA</Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Iowa Department of Transportation <br />
              Central Programs Bureau <br />
              P.O. Box 9278 <br />
              Des Moines, IA 50306-9278
            </Text>
          </Layout>
          <Text wordBreak="break-word">
            Vehicle Information:
            <AnchorLink
              wordBreak="break-word"
              inline
              href="mailto:Central.Vehicle@iowadot.us"
            >
              Central.Vehicle@iowadot.us
            </AnchorLink>
            Records Searches:
            <AnchorLink
              wordBreak="break-word"
              inline
              href="mailto:Mvd.Electronicapplicationrequest@iowadot.us"
            >
              Mvd.Electronicapplicationrequest@iowadot.us
            </AnchorLink>
            Website:
            <AnchorLink
              wordBreak="break-word"
              inline
              href="http://www.iowadot.gov/mvd/vehicleregistration/records"
            >
              https://Iowa Department of Transportation Vehicle
              Registration/Records
            </AnchorLink>
          </Text>
        </>
      );
    case "KS":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">KANSAS</Text>
          <Text wordBreak="break-word">
            Vehicle Records may be obtained by going online to Make a
            <AnchorLink
              wordBreak="break-word"
              href="https://pay.payitgov.com/ikan-request-to-access-vehicle-records"
            >
              Kansas Request to Access Vehicle Records Payment - Powered by
              PayIt (payitgov.com)
            </AnchorLink>
            where a Vehicle Registration Record ($15), Vehicle Title History
            ($30) or Certified Title History ($40) can be purchased.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text wordBreak="break-word">
              Customers may also mail in a request
              <AnchorLink
                wordBreak="break-word"
                href="https://www.ksrevenue.gov/pdf/trdl302.pdf"
              >
                (ksrevenue.gov/pdf/trdl302.pdf)
              </AnchorLink>
              with check/money order to: KDOR <br />
              Titles & Registration <br />
              PO BOX 2505 <br />
              Topeka, KS 66611
            </Text>
          </Layout>
        </>
      );
    case "KY":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">KENTUCKY</Text>
          <Text wordBreak="break-word">
            Use form TC96-16G-Request for Motor Vehicle or Boat Record that
            Includes Personal Information. Government Agency Use Only found at
            <AnchorLink
              wordBreak="break-word"
              href="https://transportation.ky.gov/Organizational-Resources/Forms/TC%2096-16g.pdf"
            >
              Request for Motor Vehicle or Boat Record that Includes Personal
              Information (ky.gov).
            </AnchorLink>
            Submit according to mail-in directions on form. Current record
            retention is 5 years.
          </Text>
        </>
      );
    case "LA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">LOUISIANA</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://www.expresslane.org/"
            >
              Louisiana Office of Motor Vehicles
            </AnchorLink>
          </Text>
        </>
      );
    case "ME":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MAINE</Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              InforME <br />
              45 Commerce Drive, Suite 10 <br />
              Augusta, Maine 04330 <br />
            </Text>
            <Text>By Phone:</Text>
            207.621.2600 or 207.512.5605 or Toll-Free 877.212.6500
            <Text>By Email:</Text>
            <AnchorLink wordBreak="break-word" href="mailto:info@informe.org">
              info@informe.org
            </AnchorLink>
          </Layout>
          <Text wordBreak="break-word">
            For subscriber information: 207.621.2600 extension 038 or
            <AnchorLink
              wordBreak="break-word"
              href="http://www.maine.gov/sos/online_services/index.html"
            >
              https://Department of the Secretary of State, State of Maine,
              Online Services.
            </AnchorLink>
          </Text>
        </>
      );
    case "MD":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MARYLAND</Text>
          <Text wordBreak="break-word">
            Maryland motor vehicle record information may be requested by
            submitting, by mail, an MVA Request for Record found on our website
            at
            <AnchorLink
              wordBreak="break-word"
              href="https://mva.maryland.gov/Documents/DR-057.pdf"
            >
              DR-057.pdf (maryland.gov).
            </AnchorLink>
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              MDOT MVA <br />
              6601 Ritchie Hwy NE <br />
              Glen Burnie MD 21062 <br />
              Attn: Room 145 (for driving records) <br />
              or Room 202 (for vehicle records) <br />
            </Text>
          </Layout>
          <Text wordBreak="break-word">
            Instructions on how to submit the application can be found on the
            Maryland Department of Transportation Motor Vehicle Administration’s
            website at
            <AnchorLink
              wordBreak="break-word"
              href="https://mva.maryland.gov/Pages/driver-record-questions.aspx#:~:text=A%20business%20may%20download%20the,202%20(for%20vehicle%20records)."
            >
              Driver and Vehicle Information - Reports and Documents - Pages
              (maryland.gov).
            </AnchorLink>
          </Text>
        </>
      );
    case "MA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MASSACHUSETTS</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="https://www.mass.gov/how-to/request-a-title-history-search"
            >
              Request a title history search | Mass.gov
            </AnchorLink>
          </Text>
        </>
      );
    case "MI":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MICHIGAN</Text>
          <Text wordBreak="break-word">
            Driver and vehicle records are released in compliance with Driver
            Privacy Protection Laws. For more information, visit the Michigan
            Department of State website at
            <AnchorLink
              wordBreak="break-word"
              href="http://www.michigan.gov/sos"
            >
              Michigan Department of State.
            </AnchorLink>
            Individual or non-account holder record requests are now submitted
            online by visiting
            <AnchorLink
              wordBreak="break-word"
              href="http://www.michigan.gov/sos"
            >
              Michigan Department of State.
            </AnchorLink>
            Select Online Services, and under the Additional Services box,
            select Record Sales Services and then select Request Records.
            Account holder record requests are now submitted online by visiting
            <AnchorLink
              wordBreak="break-word"
              href="http://www.michigan.gov/sos"
            >
              Michigan Department of State.
            </AnchorLink>
            Select Online Services, and under the Additional Services box,
            select Record Sales Services and then select Log in for Record
            Sales. Records are $11 per searched record ($12 for a certified
            record) and provided at no charge to Michigan Government agencies
            with an approved account. Bulk data is available through the List
            Sales Program. Address inquiries to
            <AnchorLink
              wordBreak="break-word"
              href="mailto:ListSales@Michigan.gov"
            >
              ListSales@Michigan.gov.
            </AnchorLink>
            Bulk records are record extracts (primarily header information) and
            do not contain driving, title, or registration history.
          </Text>
        </>
      );
    case "MN":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MINNESOTA</Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>Online:</Text>
            <Text>
              <AnchorLink
                wordBreak="break-word"
                href="https://dps.mn.gov/divisions/dvs/forms-documents/Pages/records-forms.aspx"
              >
                Online: Forms/Documents/Manuals - Records Forms (mn.gov)
              </AnchorLink>
            </Text>
            <Text>By Phone:</Text>
            DVS Records Office <br />
            651.296.2940 <br />
          </Layout>
        </>
      );
    case "MS":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MISSISSIPPI</Text>
          <Text>
            <AnchorLink wordBreak="break-word" href="https://www.dor.ms.gov/">
              Home | DOR (ms.gov
            </AnchorLink>
          </Text>
        </>
      );
    case "MO":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MISSOURI</Text>
          <Text wordBreak="break-word">
            If you are the owner of record on the Missouri title, you may
            purchase a title and/or registration motor vehicle record by
            completing request form 5499
            <AnchorLink
              wordBreak="break-word"
              href="https://dor.mo.gov/motor-vehicle/"
            >
              Missouri Department of Revenue.
            </AnchorLink>
            Form and fee may be submitted by visiting a Missouri Motor Vehicle
            licensing office. Office listing and business information may be
            found at
            <AnchorLink wordBreak="break-word" href="https://dor.mo.gov/">
              Missouri Department of Revenue.
            </AnchorLink>
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Missouri Department of Revenue, <br />
              Motor Vehicle Bureau-Record Center <br />
              PO Box 2048 <br />
              Jefferson City, MO 65105-2048 <br />
            </Text>
            <Text>By E-Mail:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="mailto:mvrecords@dor.mo.gov"
            >
              mvrecords@dor.mo.gov
            </AnchorLink>
          </Layout>
          <Text wordBreak="break-word">
            Vehicle owner will receive documentation containing requested owner,
            vehicle, lien, and/or registration information; however, this
            documentation may not be used in place of the Missouri title for
            transfer of ownership. For information on obtaining a duplicate
            Missouri title, visit
            <AnchorLink
              wordBreak="break-word"
              href="http://dor.mo.gov/motorv/titlerep.php%20or%20call%20573.526.3669"
            >
              Missouri Department of Revenue Missing Titles/Applying for a
              Duplicate Title.
            </AnchorLink>
            If you are the not owner of record on the Missouri title, Missouri
            record information may be requested by submitting the request form
            and fee for each record. If personal information is requested, the
            requestor will submit Request for
            <AnchorLink
              wordBreak="break-word"
              href="https://dor.mo.gov/forms/5091.pdf"
            >
              Form 5091 - Request for Motor Vehicle Records with Personal
              Information.
            </AnchorLink>
            When no personal information is requested, the completion of form
            <AnchorLink
              wordBreak="break-word"
              href="https://dor.mo.gov/forms/4803.pdf"
            >
              Form 4803 - Request for Information (mo.gov)
            </AnchorLink>
            is required. Refer to form for fee assessment.
          </Text>
          <Text wordBreak="break-word">
            Public Records Request Review - Prior to release, public record
            requests are reviewed for permissible uses allowed by federal and
            state laws. DOL will either provide you with the record information
            you seek or notification in writing with an explanation that
            prevents release of the record you seek.
          </Text>
        </>
      );
    case "MT":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">MONTANA</Text>
          <Text wordBreak="break-word">
            Complete a Release of Motor Vehicle Records form MV210 and submit
            payment.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text wordBreak="break-word">
              Motor Vehicle Division <br />
              Vehicle Services Bureau <br />
              PO Box 201431 <br />
              Helena MT 59620 <br />
            </Text>
            <Text>By E-Mail:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="mailto:mvdtitleinfo@mt.gov"
            >
              mvdtitleinfo@mt.gov
            </AnchorLink>
            <Text>Forms URL:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="https://mvdmt.gov/forms-manuals-vehicle-title-registration/"
            >
              Forms &amp; Manuals: Vehicle Title &amp; Registration - MT Motor Vehicle Division
            </AnchorLink>
            <Text>Or go online:</Text>
            <AnchorLink
              wordBreak="break-word"
              href="https://cars.dojmt.gov/app/_/"
            >
              Motor Vehicle Division
            </AnchorLink>
          </Layout>
        </>
      );
    case "NE":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NEBRASKA</Text>
          <Text wordBreak="break-word">
            <AnchorLink
              wordBreak="break-word"
              href="https://dmv.nebraska.gov/dvr/obtaining-vehicle-record"
            >
              Obtaining a Vehicle Record | Nebraska Department of Motor Vehicles
            </AnchorLink>
          </Text>
        </>
      );
    case "NV":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NEVADA</Text>
          <Text wordBreak="break-word">
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dmvnv.com/records.htm"
            >
              Nevada Department of Motor Vehicles Public Records Access
            </AnchorLink>
          </Text>
        </>
      );
    case "NH":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NEW HAMPSHIRE</Text>
          <Text wordBreak="break-word">
            <AnchorLink
              wordBreak="break-word"
              href="http://www.nh.gov/safety/divisions/dmv/forms/documents/dsmv505.pdf"
            >
              New Hampshire Division of Motor Vehicles
            </AnchorLink>
          </Text>
        </>
      );
    case "NJ":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NEW JERSEY</Text>
          <Text wordBreak="break-word">
            Businesses may access applications and obtain information through
            the NJMVC records website located at
            <AnchorLink
              wordBreak="break-word"
              href="http://www.state.nj.us/mvcbiz/Records/index.htm"
            >
              State of New Jersey Motor Vehicle Commission Records.
            </AnchorLink>
            Individuals may submit a written request for a variety of records.
            For additional information visit
            <AnchorLink
              wordBreak="break-word"
              href="http://www.state.nj.us/mvc/vehicles/certitle.htm"
            >
              New Jersey Motor Vehicle Commission Certified Title Search.
            </AnchorLink>
          </Text>
        </>
      );
    case "NM":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NEW MEXICO</Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text wordBreak="break-word">
              New Mexico Motor Vehicle Division <br />
              Joseph Montoya Building <br />
              PO Box 1028 <br />
              1100 South St. Francis Drive <br />
              Santa Fe 87504-1028 <br />
            </Text>
            <Text>By Phone:</Text>
            <Text>888.683.4636 or 888.MVD.INFO</Text>
          </Layout>
        </>
      );
    case "NY":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NEW YORK</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://dmv.ny.gov/get-my-own-vehicle-and-title-record-abstract"
            >
              New York Department of Motor Vehicles Vehicle Registration or
              Title Record Abstract
            </AnchorLink>
          </Text>
        </>
      );
    case "NC":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NORTH CAROLINA</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://www.ncdot.gov/dmv/offices-services/records-reports/Pages/vehicle-records.aspx"
            >
              North Carolina DMV Vehicle Records
            </AnchorLink>
          </Text>
        </>
      );
    case "ND":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">NORTH DAKOTA</Text>
          <Text wordBreak="break-word">
            North Dakota vehicle record information may be requested by
            submitting the Request for Vehicle Information - SFN 51269.
            Instructions and fees are explained on the form. Forms can be found
            on the NDDOT home page web site at
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dot.nd.gov/dotnet/forms/forms.aspx"
            >
              North Dakota Transportation Forms
            </AnchorLink>
            or
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dot.nd.gov/forms/SFN51269.pdf"
            >
              North Dakota Department of Transportation, Motor Vehicle - Request
              for Vehicle Information.
            </AnchorLink>
            Complete and mail to the mailing address provided on this form.
          </Text>
        </>
      );
    case "OH":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">OHIO</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://publicsafety.ohio.gov/links/bmv1173.pdf"
            >
              Ohio BMV Record Request Form
            </AnchorLink>
          </Text>
        </>
      );
    case "OK":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">OKLAHOMA</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://www.ok.gov/tax/documents/769.pdf"
            >
              Oklahoma Tax Commission
            </AnchorLink>
          </Text>
        </>
      );
    case "OR":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">OREGON</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://www.oregon.gov/ODOT/DMV/Pages/Records/index.aspx"
            >
              Oregon Driver & motor Vehicle Services
            </AnchorLink>
          </Text>
        </>
      );
    case "PA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">PENNSYLVANIA</Text>
          <Text wordBreak="break-word">
            If you are the owner of the vehicle, you may purchase a vehicle
            history by downloading a Request for Vehicle Information (DL-135)
            from
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dmv.state.pa.us/"
            >
              PennDOT Driver & Vehicle Services.
            </AnchorLink>
            Click on “Forms and Publications”, “All Motor Vehicle Forms,
            Publicationsand FAQ’s,” and DL-135. Forms must be mailed to PennDOT
            for processing.
          </Text>
        </>
      );
    case "RI":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">RHODE ISLAND</Text>
          <Text>
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dmv.ri.gov/registrations/titles/index.php"
            >
              Rhode Island Registrations, Plates and Titles
            </AnchorLink>
          </Text>
        </>
      );
    case "SC":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">SOUTH CAROLINA</Text>
          <Text wordBreak="break-word">
            If you are a prospective buyer of a vehicle currently titled in
            South Carolina, you may request a South Carolina title history by
            submitting a completed Request for Vehicle Information form
            <AnchorLink
              wordBreak="break-word"
              href="https://www.scdmvonline.com/-/media/Forms/5027-A.ashx"
            >
              (Form 5027-A)
            </AnchorLink>
            located at
            <AnchorLink
              wordBreak="break-word"
              href="http://publicsafety.ohio.gov/links/bmv1173.pdf"
            >
              SCDMV (scdmvonline.com).
            </AnchorLink>
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text wordBreak="break-word">
              South Carolina Department of Motor Vehicles <br />
              PO Box 1498 <br />
              Blythewood, SC 29016-1498 <br />
            </Text>
          </Layout>
        </>
      );
    case "SD":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">SOUTH DAKOTA</Text>
          <Text wordBreak="break-word">
            Please submit to the following:
            <AnchorLink wordBreak="break-word" href="mailto:motorv@state.sd.us">
              motorv@state.sd.us
            </AnchorLink>
          </Text>
        </>
      );
    case "TN":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">TENNESSEE</Text>
          <Text wordBreak="break-word">
            Persons eligible under the Driver Privacy Protection Act and
            Tennessee State statutes may obtain a Tennessee State Motor Vehicle
            Record. Complete a Vehicle Information Request,
            <AnchorLink
              wordBreak="break-word"
              href="https://www.tn.gov/content/dam/tn/revenue/documents/forms/titlereg/f1313801Fill-in.pdf"
            >
              Form RV-F1313801,
            </AnchorLink>
            sign and date the form, submit payment and any required documents to
            the Dept. of Revenue, Vehicle Services Division. The fee depends
            upon what is requested, this information can be found on our form.
            Government agencies are not charged for this service.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text wordBreak="break-word">
              Vehicle Services Division <br />
              500 Deaderick St. <br />
              Nashville, TN 37243 <br />
            </Text>
            <Text>By Email:</Text>
            <Text wordBreak="break-word">
              <AnchorLink
                wordBreak="break-word"
                href="mailto:VehicleServices.Research@tn.gov"
              >
                VehicleServices.Research@tn.gov
              </AnchorLink>
              (only for government agencies)
            </Text>
          </Layout>
        </>
      );
    case "TX":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">TEXAS</Text>
          <Text wordBreak="break-word">
            The consumer must complete and sign the Request for Texas Motor
            Vehicle Information (Form VTR-275) located here
            <AnchorLink
              wordBreak="break-word"
              href="https://www.txdmv.gov/forms"
            >
              Forms | TxDMV.gov.
            </AnchorLink>
            Certify by initialing their intended use of the information on the
            back of the form. Submit a completed and signed Form VTR-275 with a
            copy of the customer’s government issued photo ID, written
            authorization and required documentation, if applicable, by mail. Or
            visit a Texas Department of Motor Vehicles (TxDMV) Regional Service
            Center for assistance. Include payment ($5.75 for title history or
            $6.75 for a certified title history) in the form of a personalcheck,
            money order, or cashier's check made payable to TxDMV. Do not mail
            cash. Fees are not refundable. If the requested information will be
            used in legal purposes, a “certified title history” is recommended.
            To contact the department, please visit
            <AnchorLink
              wordBreak="break-word"
              href="http://www.txdmv.gov/contact-us"
            >
              Texas Department of Motor Vehicles.
            </AnchorLink>
          </Text>
        </>
      );
    case "UT":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">UTAH</Text>
          <Text wordBreak="break-word">
            Persons eligible under the Driver Privacy Protection Act and Utah
            State statutes may obtain a Utah State Motor Vehicle Record.
          </Text>
          <Text wordBreak="break-word">
            Applicants must complete a Release of Protected Record Application
            TC-890, sign and date the form, submit payment and any required
            documents to the Utah Division of Motor Vehicles. There is a fee for
            each record requested (see form for applicable fees). Government
            agencies are not charged for this service.
          </Text>
          <Layout type="grid" gridProps={{ templateColumns: "2-column" }}>
            <Text>By Mail:</Text>
            <Text>
              Utah State Tax Commission <br />
              Attn: Motor Vehicle Contact Center <br />
              P.O. Box 30412 <br />
              Salt Lake City, UT 84130 <br />
            </Text>
            <Text>By Email:</Text>
            <AnchorLink wordBreak="break-word" href="http://dmv.utah.gov">
              dmv.utah.gov
            </AnchorLink>
            <Text>Forms URL:</Text>
            <AnchorLink
              href="https://tax.utah.gov/forms/current/tc-890.pdf"
              wordBreak="break-word"
            >
              https://tax.utah.gov/forms/current/tc-890.pdf
            </AnchorLink>
          </Layout>
        </>
      );
    case "VT":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">VERMONT</Text>
          <Text wordBreak="break-word">
            <AnchorLink
              wordBreak="break-word"
              href="https://dmv.vermont.gov/enforcement-and-safety/driving-records-requests"
            >
              https://dmv.vermont.gov/enforcement-and-safety/driving-records-requests
            </AnchorLink>
          </Text>
        </>
      );
    case "VA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">VIRGINIA</Text>
          <Text wordBreak="break-word">
            For a Prospective Purchaser Inquiry (PPI) on a vehicle titled in
            Virginia go to
            <AnchorLink
              wordBreak="break-word"
              href="https://www.dmv.virginia.gov/"
            >
              Home | Virginia Department of Motor Vehicles.
            </AnchorLink>
            On DMV’s home page, go to the bottom of the page under General
            Information and click on Obtaining DMV Records. Once on the screen
            titled Obtaining DMV Records, look down the list of available
            transactions for Vehicle History Reports and click on the
            Prospective Purchaser Inquiry (PPI).
          </Text>
        </>
      );
    case "WA":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">WASHINGTON</Text>
          <Text wordBreak="break-word">
            Washington State vehicle record information may be requested by
            submitting a Vehicle Record Request form to the Department of
            Licensing. Access the DOL home page web site at
            <AnchorLink wordBreak="break-word" href="http://www.dol.wa.gov/">
              Washington State Department of Licensing.
            </AnchorLink>
            Click on “Get a Form”, located in the center grey part of the home
            page. Click on “…more forms by title” then scroll down the list to
            find the form called “Public Record Request-Public disclosure.” At
            the top of the form, in the first paragraph, click on the link for a
            complete list of public disclosure forms,
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dol.wa.gov/forms/formspd.html"
            >
              DOL Public Disclosure.
            </AnchorLink>
            Scroll down this list to the second heading titled “Vehicle or boat
            information.” Click on the second bullet in the list called “Vehicle
            Record Request.” Complete and email, mail, or fax as indicated on
            the second page of this form. Or call the Vehicle/Vessel Record
            Disclosure unit at 360.359.4002 and we will mail or fax the form to
            you. Your request will be acknowledged within 5 business days of
            receipt.
          </Text>
          <Text wordBreak="break-word">
            Prior to release, Vehicle/Vessel public records requests are
            reviewed for permissible uses allowable by federal and state laws.
            DOL will either provide you with the records you seek or notify you
            in writing with an explanation of the applicable statutory
            exemptions that prevent release of the records, along with an
            explanation of how those exemptions apply, in whole or in part.
          </Text>
        </>
      );
    case "WV":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">WEST VIRGINIA</Text>
          <Text wordBreak="break-word">
            Contact our Records Management Unit at 304.926.3909 or go to
            <AnchorLink
              wordBreak="break-word"
              href="https://transportation.wv.gov/DMV/Forms/Pages/Search-Results.aspx?Title=&DMVFormNumber=dmv-%20100-tr&DMVFormCategory"
            >
              West Virginia DMV.
            </AnchorLink>
            Download and complete form DMV100 “Request for Vehicle Information”
            and either mail or take to our Vehicle Services section at the main
            DMV office in Kanawha City, West Virginia with the applicable fees
          </Text>
        </>
      );
    case "WI":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">WISCONSIN</Text>
          <Text wordBreak="break-word">
            Wisconsin vehicle record information can be requested by submitting
            the Vehicle/Driver Record Information Request form,
            <AnchorLink
              wordBreak="break-word"
              href="https://wisconsindot.gov/Documents/formdocs/mv2896.pdf"
            >
              MV2896
            </AnchorLink>
            and a $5.00 fee for each record. If personal information is being
            requested, the form needs to have one of the boxes checked
            authorizing release of personal information.
            <AnchorLink
              wordBreak="break-word"
              href="http://wisconsindot.gov/Documents/formdocs/mv2896.pdf"
            >
              Vehicle/Driver Record Information Request
            </AnchorLink>
            Additional information related to privacy of Wisconsin motor vehicle
            records can be found at:
            <AnchorLink
              wordBreak="break-word"
              href="http://wisconsindot.gov/Pages/dmv/license-drvs/rcd-crsh-rpt/privacy.aspx"
            >
              State of Wisconsin Department of Transportation Privacy Notice
            </AnchorLink>
          </Text>
        </>
      );
    case "WY":
      return (
        <>
          <Text>How to Request a State Vehicle Record:</Text>
          <Text fontWeight="bold">WYOMING</Text>
          <Text wordBreak="break-word">
            If you are the owner of record on the title, you must contact the
            County Clerk in the county that the title was issued to order a
            duplicate title. If you are the registered owner of record on the
            registration, you must contact the County Treasurer in the county
            that the license plate and registration was issued to order a
            duplicate registration or replace a lost or stolen license plate.
            The County Clerk’s and County Treasurer’s telephone numbers are
            listed on our website at
            <AnchorLink
              wordBreak="break-word"
              href="http://www.dot.state.wy.us/home/titles_plates_registration.html"
            >
              Titles, Plates and Registration.
            </AnchorLink>
            For a title search, we follow the Federal Privacy Disclosure Act. If
            you qualify, you may complete our Privacy Disclosure Agreement and
            pay $5.00 for each title search. The Agreement is found on our
            website at
            <AnchorLink
              wordBreak="break-word"
              href="https://dmv.vermont.gov/enforcement-and-safety/driving-records-requests"
            >
              Title Search
            </AnchorLink>
          </Text>
        </>
      );
    // You can add more cases for other states as needed, I have been using vin: 1FTCF15N5HLA06926 and just changing whatever I am looking at to CA
    default:
      return <div>State not found</div>;
  }
};
