import React, { useState, useEffect } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useHandlePageNavigate,
} from "../../../hooks/hooks";
import { useLocation } from "react-router-dom";

import { selectBrandInfo } from "../../../features/brand/brandSlice";
import {
  selectMemberData,
  selectHomeUrl,
  selectIdProtectData,
} from "../../../features/authentication/authenticationSlice";
import {
  selectPrimarySubscription,
  updateIsInRebill,
} from "../../../features/subscriptions/subscriptionSlice";
import {
  setTab,
  toggleOpen,
} from "../../../features/multisearch/multisearchSlice";
import { capFirstLetter, formatPhoneNumber } from "../../../utils";

import {
  Button,
  Padding,
  ReportTitle,
  Sheet,
  Text,
  Layout,
  CollapsableItemList,
  Container,
  AnchorLink,
  Icon,
  IconList,
  Tab,
  Modal,
  Margin,
} from "@kidslivesafe/sc-react";

import "./my-account.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import PlanBenefits from "./PlanBenefits";
import {
  selectNumberOfMonitoredReports,
  selectTotalAllowedMonitoredReports,
} from "../../../features/monitoring/monitoringSlice";

const MyAccount: React.FC = () => {
  const navigate = useHandlePageNavigate();
  const dispatch = useAppDispatch();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const cardUpdated = query.get("cardUpdated");

  const brandInfo = useAppSelector(selectBrandInfo);
  const memberData = useAppSelector(selectMemberData);
  const homeUrl = useAppSelector(selectHomeUrl);
  const subscriptionDetails = useAppSelector(selectPrimarySubscription);
  const currentBreakpoint = useCurrentBreakpoint();
  const idProtectData = useAppSelector(selectIdProtectData);

  const totalReportsMonitored = useAppSelector(selectNumberOfMonitoredReports);
  const totalAllowedReports = useAppSelector(
    selectTotalAllowedMonitoredReports,
  );

  const [idProtectModal, setIdProtectModal] = useState<boolean>(false);

  const handleSpotlightClick = (tab: Tab) => {
    dispatch(setTab(tab));
    dispatch(toggleOpen());
  };

  const handleIdProtectGetStarted = () => {
    if (idProtectData.isIdProtectAvailable || idProtectData.idProtectMember) {
      navigate("/id-protect");
      return;
    } else if (
      !idProtectData.isIdProtectAvailable ||
      !idProtectData.idProtectMember
    ) {
      setIdProtectModal(true);
      return;
    }
  };

  useEffect(() => {
    if (cardUpdated) {
      dispatch(updateIsInRebill());
    }
  }, [cardUpdated]);

  return (
    <>
      <ReportTitle reportTitle="My Account" />
      <Sheet>
        <Layout
          type="flex"
          flexProps={{
            direction: "column",
            gap: "md",
          }}
        >
          <CollapsableItemList>
            <Layout
              type="flex"
              flexProps={{ direction: "column" }}
              breakpointStyles={{
                tablet: {
                  type: "grid",
                  gridProps: { templateColumns: "2-column" },
                },
                desktop: {
                  type: "grid",
                  gridProps: { templateColumns: "2-column" },
                },
              }}
            >
              <Container customClassName="page-my-account__customer-information sc-collapsable-item-list__item">
                <Padding top="md" left="md" right="md" bottom="lg">
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "lg" }}
                  >
                    <Layout
                      type="flex"
                      flexProps={{ direction: "column", gap: "xs" }}
                    >
                      <Text tag="h2" fontWeight="bold">
                        Customer Information
                      </Text>
                      <Text fontWeight="bold">
                        First Name:{" "}
                        <Text fontWeight="normal" tag="span">
                          {memberData.firstname}
                        </Text>
                      </Text>
                      <Text fontWeight="bold">
                        Last Name:{" "}
                        <Text fontWeight="normal" tag="span">
                          {memberData.lastname}
                        </Text>
                      </Text>
                      <Text fontWeight="bold">
                        Email:{" "}
                        <Text fontWeight="normal" tag="span">
                          {memberData.username}
                        </Text>
                      </Text>
                      <Text fontWeight="bold">
                        Customer ID:{" "}
                        <Text fontWeight="normal" tag="span">
                          {memberData.customerId}
                        </Text>
                      </Text>
                    </Layout>
                    <Button
                      buttonText="Reset Password"
                      handleClick={() => {
                        navigate("/reset-password");
                      }}
                      size="md"
                    />
                  </Layout>
                </Padding>
              </Container>
              <Container customClassName="page-my-account__contact-support">
                <Padding
                  top={currentBreakpoint === "desktop" ? "md" : "lg"}
                  left="md"
                  right="md"
                  bottom="lg"
                >
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "xs" }}
                  >
                    <Text tag="h2" fontWeight="bold">
                      Contact Support
                    </Text>
                    <Text fontWeight="bold">
                      Phone:{" "}
                      <Text fontWeight="normal" tag="span">
                        Call us toll-free at{" "}
                        <AnchorLink
                          inline
                          phoneNumber
                          hoverUnderline
                          href={brandInfo.phoneNumber}
                        >
                          {formatPhoneNumber(brandInfo.phoneNumber)}
                        </AnchorLink>
                        . Our automated system is available 24/7, 365-days a
                        year and live agent support available 7-days a week from
                        5am - 9pm PST.
                      </Text>
                    </Text>
                    <Text fontWeight="bold">
                      Email:{" "}
                      <Text fontWeight="normal" tag="span">
                        Please use the{" "}
                        <AnchorLink
                          size="base"
                          href={`${homeUrl}/help-center/contact`}
                          inline
                          hoverUnderline
                        >
                          Contact Us
                        </AnchorLink>{" "}
                        page to send an email.
                      </Text>
                    </Text>
                  </Layout>
                </Padding>
              </Container>
            </Layout>
            <PlanBenefits />
          </CollapsableItemList>
        </Layout>
      </Sheet>
      {brandInfo.acronym === "pf" ? null : (
        <Container
          altColor="transparent"
          customClassName="page-my-account__id-protect__container"
        >
          <Sheet>
            <Padding top="lg" left="md" right="md" bottom="lg">
              <div className="page-my-account__id-protect__content">
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "lg",
                    direction: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="page-my-account__id-protect__icon">
                    <Icon
                      name={IconList.IconShield}
                      width={currentBreakpoint !== "desktop" ? 40 : 53}
                      height={currentBreakpoint !== "desktop" ? 47 : 60}
                    />
                  </div>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "xxs" }}
                  >
                    <Text
                      fontWeight="bold"
                      size="xxxl"
                      textAlign="center"
                      passThroughClassess="page-my-account__id-protect__header"
                    >
                      ID Protect
                    </Text>
                    <Text
                      fontWeight="semibold"
                      tag="i"
                      size="xl"
                      color={`${brandInfo.acronym === "qpr" ? "base" : "white"}`}
                      textAlign="center"
                    >
                      Safeguard your identity and financial security with ID
                      Protect.
                    </Text>
                  </Layout>
                  <Text
                    fontWeight="normal"
                    textAlign="center"
                    color={`${brandInfo.acronym === "qpr" ? "base" : "white"}`}
                  >
                    Hackers and identity thieves continue to evolve. They are
                    very sophisticated, extremely creative and relentless in
                    their pursuit. Having the right solutions to monitor and
                    respond to identity theft is more important than ever.
                    Protect yourself with identity monitoring and alerts,
                    comprehensive restoration services, $1 million identity
                    theft insurance, and so much more. Get the peace of mind you
                    deserve for a fraction of what the competition charges.
                    Enroll in ID Protect today!
                  </Text>
                  <Button
                    buttonText="Get Started"
                    handleClick={handleIdProtectGetStarted}
                    size="md"
                  />
                </Layout>
              </div>
            </Padding>
          </Sheet>
        </Container>
      )}
      <Sheet>
        <Layout
          type="flex"
          flexProps={{
            direction: "column",
            gap: "md",
          }}
        >
          <Container customClassName="page-my-account__membership-information">
            <Padding top="lg" left="md" right="md" bottom="lg">
              <Layout
                type="flex"
                flexProps={{ direction: "column", gap: "lg" }}
              >
                <Container>
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "xxs" }}
                  >
                    <Text fontWeight="bold">Membership Details</Text>
                    {brandInfo.acronym === "pf" ? (
                      <>
                        {!subscriptionDetails?.cancelDate ? (
                          <Layout
                            type="flex"
                            flexProps={{ direction: "row", gap: "xxs" }}
                          >
                            <Container>
                              <Text fontWeight="bold">Plan: </Text>
                            </Container>
                            <Container>
                              <Text fontWeight="normal" tag="span">
                                {subscriptionDetails?.productName}
                              </Text>
                              {subscriptionDetails &&
                              subscriptionDetails.propertyReportThreshold >
                                0 ? (
                                <Text size="sm" fontWeight="normal" tag="span">
                                  {subscriptionDetails?.propertyReportThreshold}{" "}
                                  Property Reports/Month
                                </Text>
                              ) : null}
                              {subscriptionDetails &&
                              subscriptionDetails.peopleReportThreshold > 0 ? (
                                <Text size="sm" fontWeight="normal" tag="span">
                                  {subscriptionDetails?.peopleReportThreshold}{" "}
                                  People Reports/Month
                                </Text>
                              ) : null}
                              {subscriptionDetails &&
                              subscriptionDetails.phoneReportThreshold > 0 ? (
                                <Text size="sm" fontWeight="normal" tag="span">
                                  {subscriptionDetails?.phoneReportThreshold}{" "}
                                  Phone Reports/Month
                                </Text>
                              ) : null}
                              {subscriptionDetails &&
                              subscriptionDetails.socialReportThreshold > 0 ? (
                                <Text size="sm" fontWeight="normal" tag="span">
                                  {subscriptionDetails?.socialReportThreshold}{" "}
                                  Social Reports/Month
                                </Text>
                              ) : null}
                              {subscriptionDetails &&
                              subscriptionDetails.vehicleReportThreshold > 0 ? (
                                <Text size="sm" fontWeight="normal" tag="span">
                                  {subscriptionDetails?.vehicleReportThreshold}{" "}
                                  Auto Reports/Month
                                </Text>
                              ) : null}
                              <Text size="sm" fontWeight="normal" tag="span">
                                PDF Downloads (Included)
                              </Text>
                              <Text size="sm" fontWeight="normal" tag="span">
                                Report Monitoring (
                                <AnchorLink
                                  role="button"
                                  handleClick={() => {
                                    navigate(
                                      "/#dashboard__monitoring__container",
                                    );
                                  }}
                                  inline
                                  hoverUnderline
                                  size="sm"
                                >
                                  {totalReportsMonitored} used/
                                  {totalAllowedReports}
                                </AnchorLink>
                                )
                              </Text>
                            </Container>
                          </Layout>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {!subscriptionDetails?.cancelDate ? (
                          <Layout
                            type="flex"
                            flexProps={{ direction: "row", gap: "xxs" }}
                          >
                            <Container>
                              <Text fontWeight="bold">Type: </Text>
                            </Container>
                            <Container>
                              <Text fontWeight="normal" tag="span">
                                Multi-Search - Large
                              </Text>
                              <AnchorLink
                                role="button"
                                hoverUnderline
                                handleClick={() => {
                                  handleSpotlightClick("people");
                                }}
                              >
                                People Reports (Included)
                              </AnchorLink>
                              <AnchorLink
                                role="button"
                                hoverUnderline
                                handleClick={() => {
                                  handleSpotlightClick("property");
                                }}
                              >
                                Property Reports (Included)
                              </AnchorLink>
                              <AnchorLink
                                role="button"
                                hoverUnderline
                                handleClick={() => {
                                  handleSpotlightClick("phone");
                                }}
                              >
                                Phone Reports (Included)
                              </AnchorLink>
                              {["pdc"].includes(brandInfo.acronym) ? (
                                <AnchorLink
                                  role="button"
                                  hoverUnderline
                                  handleClick={() => {
                                    handleSpotlightClick("vehicle");
                                  }}
                                >
                                  Vehicle Reports (Included)
                                </AnchorLink>
                              ) : null}
                            </Container>
                          </Layout>
                        ) : null}
                        {!subscriptionDetails?.cancelDate ? (
                          <Layout
                            type="flex"
                            flexProps={{ direction: "row", gap: "xxs" }}
                          >
                            <Container>
                              <Text fontWeight="bold">Plan: </Text>
                            </Container>
                            <Container>
                              <Text fontWeight="normal" tag="span">
                                Unlimited People Searches
                              </Text>
                              <Text fontWeight="normal" tag="span">
                                100 Reports / Month
                              </Text>
                              <Text fontWeight="normal" tag="span">
                                PDF Downloads (Included)
                              </Text>
                              <Text fontWeight="normal" tag="span">
                                Report Monitoring (Included)
                              </Text>
                            </Container>
                          </Layout>
                        ) : null}
                      </>
                    )}
                    <Text fontWeight="bold">
                      Status:{" "}
                      <Text
                        fontWeight="normal"
                        tag="span"
                        color={
                          subscriptionDetails?.cancelDate ? "error" : "base"
                        }
                      >
                        {subscriptionDetails?.cancelDate
                          ? "Canceled"
                          : capFirstLetter(
                              memberData.subscriptionStatus
                                ? memberData.subscriptionStatus
                                : "",
                            )}{" "}
                        {subscriptionDetails?.cancelDate ? null : "("}
                        {subscriptionDetails?.cancelDate ? null : (
                          <AnchorLink
                            size="base"
                            inline
                            role="button"
                            hoverUnderline
                            handleClick={() => {
                              subscriptionDetails?.blueSnapMember
                                ? navigate("/manage-membership")
                                : navigate("/cancel-member");
                              window.scrollTo(0, 0);
                            }}
                          >
                            manage
                          </AnchorLink>
                        )}
                        {subscriptionDetails?.cancelDate ? null : ")"}
                      </Text>
                    </Text>
                    {!subscriptionDetails?.cancelDate ? (
                      <Text fontWeight="bold">
                        Manage Billing:{" "}
                        <AnchorLink
                          inline
                          role="button"
                          hoverUnderline
                          handleClick={() => {
                            navigate("/update-credit-card");
                            window.scrollTo(0, 0);
                          }}
                        >
                          Update Payment Method
                        </AnchorLink>
                      </Text>
                    ) : null}
                    <Text fontWeight="bold">
                      Contact Support:{" "}
                      <AnchorLink
                        inline
                        phoneNumber
                        hoverUnderline
                        href={brandInfo.phoneNumber}
                      >
                        {formatPhoneNumber(brandInfo.phoneNumber)}
                      </AnchorLink>
                    </Text>
                    <Text fontWeight="bold">
                      Support Topics:{" "}
                      <AnchorLink
                        size="base"
                        hoverUnderline
                        href={`${brandInfo.homeUrl}/help-center/faqs`}
                        inline
                      >
                        Help Center
                      </AnchorLink>
                    </Text>
                  </Layout>
                </Container>
                <Container>
                  <Padding top="sm" bottom="sm">
                    <Layout type="flex" flexProps={{ gap: "lg" }}>
                      <div className="page-my-account__membership-information__icon page-my-account__membership-information__icon--download">
                        <Icon name={IconList.IconDownloadReport} size={40} />
                      </div>
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "sm" }}
                      >
                        <Text
                          tag="h3"
                          color={
                            ["pf"].includes(brandInfo.acronym)
                              ? "base"
                              : "accent"
                          }
                        >
                          Download Report as a PDF
                        </Text>
                        <Text fontWeight="normal">
                          Reports can be downloaded as PDFs. Use this feature to
                          keep track of reports over time, allow easy access to
                          reports offline, or print reports for added
                          convenience. It’s fast and easy so take advantage and
                          download reports today!
                        </Text>
                      </Layout>
                    </Layout>
                  </Padding>
                </Container>
              </Layout>
            </Padding>
          </Container>
        </Layout>
      </Sheet>
      <Modal
        open={idProtectModal}
        setOpen={() => {
          setIdProtectModal(!idProtectModal);
        }}
        xClose
        outSideClose
      >
        <Margin top="xxl">
          <Padding top="lg">
            <Layout type="flex" flexProps={{ direction: "column", gap: "lg" }}>
              <Text fontWeight="bold" tag="h2" textAlign="center">
                Sorry, ID Protect is unavailable to purchase at this time
              </Text>
              <Text textAlign="center">
                Please contact member support at{" "}
                <AnchorLink role="link" phoneNumber inline>
                  {formatPhoneNumber(brandInfo.phoneNumber)}
                </AnchorLink>
              </Text>
            </Layout>
          </Padding>
        </Margin>
      </Modal>
    </>
  );
};

export default MyAccount;
