import { useState } from "react";
import {
  Layout,
  Text,
  Padding,
  Icon,
  IconList,
  Spotlight,
  Button,
  Modal,
  AnchorLink,
  ContainerLineItem,
  Input,
} from "@kidslivesafe/sc-react";

import { useAppSelector } from "../../../hooks/hooks";
import {
  CombinedReports,
  selectReportLists,
} from "../../../features/reports/reportsSlice";
import { ReportList } from "../../../features/reports/reportTypes";
import { addReportToListParamBuilder } from "../../../utils";
import {
  useAddReportToListDispatch,
  useRemoveReportFromListDispatch,
  useRenameListDispatch,
} from "../../../hooks/listHook";

export type ListModalMode = "remove-report" | "add-report" | "rename-list";

export interface ListModalProps {
  modalMode: ListModalMode;
  setModalMode: (mode: ListModalMode) => void;
  displayModal: boolean;
  setDisplayModal: (display: boolean) => void;
  report?: CombinedReports;
  list?: ReportList;
  reportId?: number;
}

const ListModal = ({
  modalMode,
  setModalMode,
  displayModal = true,
  setDisplayModal,
  report,
  list,
  reportId,
}: ListModalProps) => {
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [createNewList, setCreateNewList] = useState<boolean>(false);
  const [newListName, setNewListName] = useState<string>("");
  const [newListNameError, setNewListNameError] = useState<string>("");
  const [completedLists, setCompletedLists] = useState<string[]>([]);

  const initialMode = modalMode;

  const addReportToList = useAddReportToListDispatch();
  const removeReportFromList = useRemoveReportFromListDispatch();
  const renameList = useRenameListDispatch();
  const lists = useAppSelector(selectReportLists);
  const favoritesList = (lists || []).find(
    list => list.listName === "Favorites",
  );

  const renderContent = () => {
    switch (modalMode) {
      case "remove-report":
        return (
          <Layout
            type="flex"
            fillVertical
            flexProps={{
              gap: "lg",
              direction: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text tag="h2" textAlign="center">
              Remove Report from List?
            </Text>
            <Text size="sm" textAlign="center">
              Clicking 'Yes, Remove' will remove the report from{" "}
              <Text size="sm" tag="b" fontWeight="semibold">
                {list?.listName}
              </Text>
              . You can always add it to a new or existing list from the report
              page using the star icon.
            </Text>
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                alignItems: "center",
                gap: "xxs",
              }}
              fillHorizontal
            >
              <Button
                handleClick={() => {
                  if (reportId && list) {
                    removeReportFromList({
                      savedReportId: reportId,
                      savedReportsListId: list.savedReportsListId,
                    });
                    setActionCompleted(true);
                    setTimeout(() => {
                      setDisplayModal(false);
                      setActionCompleted(false);
                    }, 2000);
                  }
                }}
                buttonText={actionCompleted ? "Report Removed" : "Yes, Remove"}
                size="md"
                fill="default"
                color="primary"
              />
              {!actionCompleted && (
                <AnchorLink
                  handleClick={() => {
                    setDisplayModal(false);
                  }}
                  size="xs"
                  role="button"
                >
                  No, Cancel
                </AnchorLink>
              )}
            </Layout>
          </Layout>
        );
      case "add-report":
        return (
          <>
            {!createNewList ? (
              <Layout
                type="flex"
                fillVertical={lists && lists.length > 3 ? false : true}
                flexProps={{
                  gap: "lg",
                  direction: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text tag="h2" textAlign="center">
                  Add to List
                </Text>
                <Layout
                  type="flex"
                  flexProps={{ gap: "md", direction: "column" }}
                  fillHorizontal
                >
                  <Spotlight>
                    <ContainerLineItem
                      lineItemHeader="Favorites"
                      lineItemSubHeader={
                        favoritesList
                          ? `${favoritesList.savedReports.length} Reports`
                          : "0 Reports"
                      }
                      lineItemDetails={
                        <Button
                          buttonText={
                            <Layout
                              type="flex"
                              flexProps={{ gap: "xxs", alignItems: "center" }}
                            >
                              {completedLists.includes("Favorites") ? (
                                <Icon name={IconList.IconYes} size={16} />
                              ) : (
                                "Add"
                              )}
                            </Layout>
                          }
                          size="fixed-width--sm"
                          handleClick={() => {
                            const addReportParams = report
                              ? addReportToListParamBuilder(report, "Favorites")
                              : undefined;

                            if (report && addReportParams) {
                              addReportToList(addReportParams);
                            }

                            setCompletedLists(prevCompleted => [
                              ...prevCompleted,
                              "Favorites",
                            ]);

                            setActionCompleted(true);
                            setTimeout(() => {
                              setDisplayModal(false);
                              setActionCompleted(false);
                              setCompletedLists([]);
                            }, 2000);
                          }}
                          color={
                            completedLists.includes("Favorites")
                              ? "success"
                              : actionCompleted
                                ? "disabled"
                                : "primary"
                          }
                        />
                      }
                    />
                  </Spotlight>
                  {lists && lists.length > 0 ? (
                    <>
                      {lists
                        .filter(list => list.listName !== "Favorites")
                        .sort((a, b) => {
                          if (a.lastActivityDate > b.lastActivityDate) {
                            return -1;
                          }
                          if (a.lastActivityDate < b.lastActivityDate) {
                            return 1;
                          }
                          return 0;
                        })
                        .map(list => {
                          const isListItemCompleted = completedLists.includes(
                            list.listName,
                          );
                          return (
                            <Spotlight key={list.listName}>
                              <ContainerLineItem
                                lineItemHeader={list.listName}
                                lineItemSubHeader={`${list.savedReports.length} Reports`}
                                lineItemDetails={
                                  <Button
                                    buttonText={
                                      <Layout
                                        type="flex"
                                        flexProps={{
                                          gap: "xxs",
                                          alignItems: "center",
                                        }}
                                      >
                                        {isListItemCompleted ? (
                                          <>
                                            <Icon
                                              name={IconList.IconYes}
                                              size={16}
                                            />
                                          </>
                                        ) : (
                                          "Add"
                                        )}
                                      </Layout>
                                    }
                                    size="fixed-width--sm"
                                    handleClick={() => {
                                      let addReportParams = report
                                        ? addReportToListParamBuilder(
                                            report,
                                            list.listName,
                                          )
                                        : undefined;
                                      if (report && addReportParams) {
                                        addReportToList(addReportParams);
                                      }

                                      if (!isListItemCompleted) {
                                        setCompletedLists(prevCompleted => [
                                          ...prevCompleted,
                                          list.listName,
                                        ]);
                                      }
                                      setActionCompleted(true);
                                      setTimeout(() => {
                                        setDisplayModal(false);
                                        setActionCompleted(false);
                                        setCompletedLists([]);
                                      }, 2000);
                                    }}
                                    color={
                                      isListItemCompleted
                                        ? "success"
                                        : actionCompleted
                                          ? "disabled"
                                          : "primary"
                                    }
                                  />
                                }
                              />
                            </Spotlight>
                          );
                        })}
                    </>
                  ) : null}
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    alignItems: "center",
                    gap: "xxs",
                  }}
                  fillHorizontal
                >
                  <Padding space="md">
                    {!actionCompleted && (
                      <Button
                        handleClick={() => {
                          setCreateNewList(true);
                        }}
                        buttonText={
                          <Layout
                            type="flex"
                            flexProps={{ gap: "xxs", alignItems: "center" }}
                          >
                            <Icon name={IconList.IconPlus} size={16} />
                            Create New List
                          </Layout>
                        }
                        size="md"
                        fill="default"
                        color="secondary"
                      />
                    )}
                  </Padding>
                </Layout>
              </Layout>
            ) : (
              <Layout
                type="flex"
                fillVertical={true}
                fillHorizontal
                flexProps={{
                  gap: "lg",
                  direction: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text tag="h2" textAlign="center">
                  Create New List
                </Text>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "column",
                    alignItems: "center",
                    gap: "xxs",
                  }}
                  fillHorizontal
                >
                  <Text size="sm">Enter a name for your new list.</Text>
                  <Text size="xxs" tag="i">
                    Please use 15 characters maximum
                  </Text>
                  <Input
                    id={`list-modal-create-new-list`}
                    value={newListName}
                    placeholder="List Name"
                    error={!!newListNameError}
                    fill="fill"
                    handleChange={input => {
                      setNewListName(input);
                      if (newListName.length > 15) {
                        setNewListNameError(
                          "Please use 15 characters maximum.",
                        );
                      } else {
                        setNewListNameError("");
                      }
                    }}
                    type="text"
                  />
                  {newListNameError && (
                    <Text size="xs" color="error">
                      {newListNameError}
                    </Text>
                  )}
                  <Button
                    handleClick={() => {
                      setNewListNameError("");
                      if (newListName.length === 0) {
                        setNewListNameError("Your list requires a name.");
                        return;
                      }
                      if (newListName.length > 15) {
                        setNewListNameError(
                          "Please use 15 characters maximum.",
                        );
                        return;
                      }
                      const listExists =
                        lists &&
                        lists.find(list => list.listName === newListName);
                      if (listExists || newListName === "Favorites") {
                        setNewListNameError("List name already exists.");
                        return;
                      }
                      let addReportParams = report
                        ? addReportToListParamBuilder(report, newListName)
                        : undefined;
                      if (report && addReportParams) {
                        addReportToList(addReportParams);
                      }
                      setActionCompleted(true);
                      setTimeout(() => {
                        setDisplayModal(false);
                        setActionCompleted(false);
                        setCreateNewList(false);
                      }, 2000);
                    }}
                    buttonText={
                      <Layout
                        type="flex"
                        flexProps={{ gap: "xxs", alignItems: "center" }}
                      >
                        {actionCompleted ? (
                          <>
                            <Icon name={IconList.IconYes} size={16} /> Created
                            and Added
                          </>
                        ) : (
                          "Create List and Add Report"
                        )}
                      </Layout>
                    }
                    size="md"
                    fill="fill"
                    color={
                      newListNameError
                        ? "disabled"
                        : actionCompleted
                          ? "success"
                          : "primary"
                    }
                  />

                  {!actionCompleted && (
                    <AnchorLink
                      handleClick={() => {
                        setCreateNewList(false);
                      }}
                      size="xs"
                      role="button"
                    >
                      No, Cancel
                    </AnchorLink>
                  )}
                </Layout>
              </Layout>
            )}
          </>
        );
      case "rename-list":
        return (
          <Layout
            type="flex"
            fillVertical={true}
            fillHorizontal
            flexProps={{
              gap: "lg",
              direction: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text tag="h2" textAlign="center">
              Rename List
            </Text>
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                alignItems: "center",
                gap: "xxs",
              }}
              fillHorizontal
            >
              <Text size="sm">Enter a new name for your new list.</Text>
              <Text size="xxs" tag="i">
                Please use 15 characters maximum
              </Text>
              <Input
                id={`list-modal-create-new-list`}
                value={newListName}
                placeholder="List Name"
                fill="fill"
                handleChange={setNewListName}
                error={!!newListNameError}
                type="text"
              />
              {newListNameError && (
                <Text size="xs" color="error">
                  {newListNameError}
                </Text>
              )}
              <Button
                handleClick={() => {
                  setNewListNameError("");
                  if (newListName.length === 0) {
                    setNewListNameError("Your list requires a name.");
                    return;
                  }
                  if (newListName.length > 15) {
                    setNewListNameError("Please use 15 characters maximum.");
                    return;
                  }
                  const listExists =
                    lists && lists.find(list => list.listName === newListName);
                  if (listExists || newListName === "Favorites") {
                    setNewListNameError("List name already exists.");
                    return;
                  }
                  if (list && newListName) {
                    renameList({
                      savedReportsListId: list.savedReportsListId,
                      listName: newListName,
                    });
                  }
                  setActionCompleted(true);
                  setTimeout(() => {
                    setDisplayModal(false);
                    setActionCompleted(false);
                  }, 2000);
                }}
                buttonText={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    {actionCompleted ? "Renamed" : "Rename"}
                  </Layout>
                }
                size="md"
                fill="fill"
                color={actionCompleted ? "success" : "primary"}
              />
              {!actionCompleted && (
                <AnchorLink
                  handleClick={() => {
                    setDisplayModal(false);
                  }}
                  size="xs"
                  role="button"
                >
                  No, Cancel
                </AnchorLink>
              )}
            </Layout>
          </Layout>
        );
    }
  };

  return (
    <Modal
      open={displayModal}
      setOpen={() => {
        setDisplayModal(false);
        setActionCompleted(false); // reset all local state on close
        setCreateNewList(false);
        setNewListName("");
        setNewListNameError("");
        setModalMode(initialMode);
      }}
      size="base"
      outSideClose
      xClose
      centerContentVertically={
        modalMode === "add-report" &&
        !createNewList &&
        lists &&
        lists?.length > 2
          ? false
          : true
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default ListModal;
