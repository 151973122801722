import { createBrowserRouter, Outlet } from "react-router-dom";
import Login from "./pages/Admin/Login/_Login";
import Logout from "./pages/Admin/Logout/_Logout";
import Dashboard from "./pages/Dashboard/_Dashboard";
import ActivityHistory from "./pages/Admin/ActivityHistory/_ActivityHistory";
import Lists from "./pages/Admin/Lists/_Lists";
import PageLayout, {
  GateKeeperPageLayout,
  FlexAccessLayout,
  BrandedLoginLayout,
  DeepLinkingRedirectLayout,
} from "./pages/_Layouts/PageLayout";
import PeopleReport from "./pages/Reports/PeopleReport/_PeopleReport";
import PeopleSearch from "./pages/Reports/PeopleSearch/_PeopleSearch";
import SocialReport from "./pages/Reports/SocialReport/_SocialReport";
import SocialHome from "./pages/Reports/SocialHome/_SocialHome";
import PropertyReport from "./pages/Reports/PropertyReport/_PropertyReport";
import CancelMember from "./pages/Admin/CancelFunnels/DefaultCancelFunnel/1_CancelMember";
import CancelOnline from "./pages/Admin/CancelFunnels/DefaultCancelFunnel/2_CancelOnline";
import CancelOffer from "./pages/Admin/CancelFunnels/CancelOffer";
import MyAccount from "./pages/Admin/MyAccount/_MyAccount";
import ManageMembership from "./pages/Admin/CancelFunnels/BlueSnapCancelFunnel/1_ManageMembership";
import CancelConfirmation from "./pages/Admin/CancelFunnels/CancelConfirmation";
import OfferConfirmation from "./pages/Admin/CancelFunnels/OfferConfirmation";
import CancelFeedback from "./pages/Admin/CancelFunnels/DefaultCancelFunnel/3_CancelFeedback";
import CancelValue from "./pages/Admin/CancelFunnels/DefaultCancelFunnel/4_CancelValue";
import PhoneReport from "./pages/Reports/PhoneReport/_PhoneReport";
import TestPullPhoneReport from "./components/UtilComponents/TEST_PullPhoneReport";
import TestPullPeopleReport from "./components/UtilComponents/TEST_PullPeopleReport";
import TestPullSocialReport from "./components/UtilComponents/TEST_PullSocialReport";
import ResetPassword from "./pages/Admin/ResetPassword/_ResetPassword";
import NewPassword from "./pages/Admin/NewPassword/_NewPassword";
import TestPullPropertyReport from "./components/UtilComponents/TEST_PullPropertyReport";
import AuthSyncFromLegacy from "./components/UtilComponents/AUTH_SyncFromLegacy";
import UpdateCreditCard from "./pages/Admin/UpdateCreditCard/_UpdateCreditCard";
import VehicleReport from "./pages/Reports/VehicleReport/_VehicleReport";
import NotSupported from "./pages/Admin/NotSupported/_NotSupported";
import TestPullVehicleReport from "./components/UtilComponents/TEST_PullVehicleReport";
import IdProtect from "./pages/Admin/IdProtect/_IdProtect";
import VerifyEmail from "./pages/Admin/EmailVerified/_EmailVerified";
import NeighborhoodReport from "./pages/Reports/NeighborhoodReport/_NeighborhoodReport";
import NeighborhoodSearch from "./pages/Reports/NeighborhoodSearch/_NeighborhoodSearch";
import TestPullNeighborhoodReport from "./components/UtilComponents/TEST_PullNeighborhoodReport";
import TestPullNeighborhoodSearch from "./components/UtilComponents/TEST_PullNeighborhoodSearch";
import TestError from "./pages/TestError/_TestError";
import LoadTestError from "./pages/TestError/_LoadTestError";
import ErrorPage from "./pages/Admin/ErrorPage/ErrorPage";
import PhoneHome from "./pages/Reports/PhoneHome/_PhoneHome";
import PropertyHome from "./pages/Reports/PropertyHome/_PropertyHome";
import PeopleHome from "./pages/Reports/PeopleHome/_PeopleHome";
import NeighborhoodHome from "./pages/Reports/NeighborhoodHome/_NeighborhoodHome";
import VehicleHome from "./pages/Reports/VehicleHome/_VehicleHome";

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: (
      <PageLayout pageName="error" documentTitle="Error Occurred" header>
        <ErrorPage />
      </PageLayout>
    ),
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: (
          <GateKeeperPageLayout
            pageName="dashboard"
            documentTitle="Access Your Personalized Member Account"
            header
            displayButtons
          >
            <Dashboard />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/people-home",
        element: (
          <GateKeeperPageLayout
            pageName="people-home"
            documentTitle="People Home"
            header
            displayButtons
          >
            <PeopleHome />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/people-search",
        element: (
          <GateKeeperPageLayout
            pageName="people-search"
            documentTitle="People Search"
            header
            displayButtons
          >
            <PeopleSearch />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/people-report",
        element: (
          <GateKeeperPageLayout
            pageName="people-report"
            documentTitle="People Report"
            header
            displayButtons
          >
            <PeopleReport />
          </GateKeeperPageLayout>
        ),
        children: [],
      },
      {
        path: "/people-report/:reportToken",
        element: (
          <GateKeeperPageLayout
            pageName="people-report-test"
            documentTitle="People Report Test Route"
            header
            displayButtons
          >
            <TestPullPeopleReport />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/social-report",
        element: (
          <GateKeeperPageLayout
            pageName="social-report"
            documentTitle="Social Report"
            header
            displayButtons
          >
            <SocialReport />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/social-report/:social",
        element: (
          <GateKeeperPageLayout
            pageName="social-report-test"
            documentTitle="Social Report Test Route"
            header
            displayButtons
          >
            <TestPullSocialReport />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/social-home",
        element: (
          <GateKeeperPageLayout
            pageName="social-home"
            documentTitle="Social Homepage"
            header
            displayButtons
          >
            <SocialHome />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/property-home",
        element: (
          <GateKeeperPageLayout
            pageName="property-home"
            documentTitle="Property Home"
            header
            displayButtons
          >
            <PropertyHome />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/property-report",
        element: (
          <GateKeeperPageLayout
            pageName="property-report"
            documentTitle="Property Report"
            header
            displayButtons
          >
            <PropertyReport />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/property-report/test",
        element: (
          <GateKeeperPageLayout
            pageName="property-report-test"
            documentTitle="Property Report Test Route"
            header
            displayButtons
          >
            <TestPullPropertyReport />
          </GateKeeperPageLayout>
        ),
      },

      {
        path: "/phone-home",
        element: (
          <GateKeeperPageLayout
            pageName="phone-home"
            documentTitle="Phone Home"
            header
            displayButtons
          >
            <PhoneHome />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/phone-report",
        element: (
          <GateKeeperPageLayout
            pageName="phone-report"
            documentTitle="Phone Report"
            header
            displayButtons
          >
            <PhoneReport />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/phone-report/:phoneNumber",
        element: (
          <GateKeeperPageLayout
            pageName="phone-report-test"
            documentTitle="Phone Report Test Route"
            header
            displayButtons
          >
            <TestPullPhoneReport />
          </GateKeeperPageLayout>
        ),
      },
      {
        path: "/authx/:uuid",
        element: (
          <PageLayout pageName="auth-x" documentTitle="CrossAuthentication">
            <AuthSyncFromLegacy />
          </PageLayout>
        ),
      },
    ],
  },
  {
    path: "/test-error",
    errorElement: <div>ERROR</div>,
    element: (
      <GateKeeperPageLayout
        pageName="test-error"
        documentTitle="Error"
        header
        displayButtons
      >
        <TestError />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/onload-test-error",
    errorElement: <div>ERROR</div>,
    element: (
      <GateKeeperPageLayout
        pageName="onload-test-error"
        documentTitle="Error"
        header
        displayButtons
      >
        <LoadTestError />
      </GateKeeperPageLayout>
    ),
  },
  /* Vehicle Report */
  {
    path: "/vehicle-report",
    element: (
      <GateKeeperPageLayout
        pageName="vehicle-report"
        documentTitle="Vehicle Report"
        header
        displayButtons
      >
        <VehicleReport />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/vehicle-report/:vin",
    element: (
      <GateKeeperPageLayout
        pageName="vehicle-report-test"
        documentTitle="Vehicle Report"
        header
        displayButtons
      >
        <TestPullVehicleReport />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/vehicle-home",
    element: (
      <GateKeeperPageLayout
        pageName="vehicle-home"
        documentTitle="Vehicle Home"
        header
        displayButtons
      >
        <VehicleHome />
      </GateKeeperPageLayout>
    ),
  },
  /* Neighborhood Report */
  {
    path: "/neighborhood-home",
    element: (
      <GateKeeperPageLayout
        pageName="neighborhood-home"
        documentTitle="Neighborhood Home"
        header
        displayButtons
      >
        <NeighborhoodHome />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/neighborhood-report",
    element: (
      <GateKeeperPageLayout
        pageName="neighborhood-report"
        documentTitle="Neighborhood Report"
        header
        displayButtons
      >
        <NeighborhoodReport />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/neighborhood-report/test",
    element: (
      <GateKeeperPageLayout
        pageName="neighborhood-report-test"
        documentTitle="Neighborhood Report Test Route"
        header
        displayButtons
      >
        <TestPullNeighborhoodReport />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/neighborhood-search",
    element: (
      <GateKeeperPageLayout
        pageName="neighborhood-watch-search"
        documentTitle="Neighborhood Watch Search"
        header
        displayButtons
      >
        <NeighborhoodSearch />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/neighborhood-search/test",
    element: (
      <GateKeeperPageLayout
        pageName="neighborhood-search-test"
        documentTitle="Neighborhood Search Test Route"
        header
        displayButtons
      >
        <TestPullNeighborhoodSearch />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/login",
    element: (
      <BrandedLoginLayout
        pageName="login"
        documentTitle="Secure Login Portal for Members"
      >
        <Login />
      </BrandedLoginLayout>
    ),
  },
  {
    path: "/logout",
    element: (
      <PageLayout
        pageName="logout"
        header
        documentTitle="Logout"
        footerType="min-footer"
        minFooterOptions={{ color: "base" }}
      >
        <Logout />
      </PageLayout>
    ),
  },
  {
    path: "/not-supported",
    element: (
      <PageLayout
        pageName="not-supported"
        documentTitle="NotSupported"
        footerType="min-footer"
        minFooterOptions={{ color: "white" }}
      >
        <NotSupported />
      </PageLayout>
    ),
  },
  {
    path: "/activity-history",
    element: (
      <GateKeeperPageLayout
        pageName="activity-history"
        header
        documentTitle="Activity History"
        displayButtons
      >
        <ActivityHistory />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/lists",
    element: (
      <GateKeeperPageLayout
        pageName="lists"
        header
        documentTitle="Lists Page"
        displayButtons
      >
        <Lists />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/my-account",
    element: (
      <DeepLinkingRedirectLayout
        pageName="my-account"
        header
        documentTitle="Login Your Account"
        displayButtons
        logMemberEvent
      >
        <MyAccount />
      </DeepLinkingRedirectLayout>
    ),
  },
  // Default Cancel Funnel
  {
    path: "/cancel-member",
    element: (
      <GateKeeperPageLayout
        pageName="cancel-member"
        header
        documentTitle="Cancel Member"
        displayButtons
        logMemberEvent
      >
        <CancelMember />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/cancel-online",
    element: (
      <GateKeeperPageLayout
        pageName="cancel-online"
        header
        documentTitle="Cancel Online"
        displayButtons
        logMemberEvent
      >
        <CancelOnline />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/cancel-feedback",
    element: (
      <GateKeeperPageLayout
        pageName="cancel-feedback"
        header
        documentTitle="Cancel Feedback"
        displayButtons
        logMemberEvent
      >
        <CancelFeedback />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/cancel-value",
    element: (
      <GateKeeperPageLayout
        pageName="cancel-value"
        header
        documentTitle="Cancel Value"
        displayButtons
        logMemberEvent
      >
        <CancelValue />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/cancel-offer",
    element: (
      <GateKeeperPageLayout
        pageName="cancel-offer"
        header
        documentTitle="Cancel Offer"
        displayButtons
        logMemberEvent
      >
        <CancelOffer />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/offer-confirmation",
    element: (
      <GateKeeperPageLayout
        pageName="offer-confirmation"
        header
        documentTitle="Offer Confirmation"
        displayButtons
        logMemberEvent
      >
        <OfferConfirmation />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/cancel-confirmation",
    element: (
      <GateKeeperPageLayout
        pageName="cancel-confirmation"
        header
        documentTitle="Cancel Confirmation"
        displayButtons
        logMemberEvent
      >
        <CancelConfirmation />
      </GateKeeperPageLayout>
    ),
  },
  // BlueSnap Cancel Funnel
  {
    path: "/manage-membership",
    element: (
      <GateKeeperPageLayout
        pageName="manage-membership"
        header
        documentTitle="Manage Membership"
        displayButtons
        logMemberEvent
      >
        <ManageMembership />
      </GateKeeperPageLayout>
    ),
  },
  {
    path: "/about",
    element: (
      <GateKeeperPageLayout pageName="about" header documentTitle="About">
        <Outlet />
      </GateKeeperPageLayout>
    ),
    children: [
      {
        path: "/about/",
        element: <div>About index page</div>,
      },
      {
        path: "/about/:id",
        element: <div>Id</div>,
      },
    ],
  },
  {
    path: "/reset-password",
    element: (
      <FlexAccessLayout
        pageName="reset-password"
        header
        documentTitle="Recover or Reset Your Account Password"
        displayButtons
      >
        <ResetPassword />
      </FlexAccessLayout>
    ),
  },
  {
    path: "/new-password",
    element: (
      <FlexAccessLayout
        pageName="new-password"
        header
        documentTitle="New Password"
        displayButtons
      >
        <NewPassword />
      </FlexAccessLayout>
    ),
  },
  {
    path: "/update-credit-card",
    element: (
      <DeepLinkingRedirectLayout
        pageName="update-credit-card"
        header
        documentTitle="Update Credit Card"
        displayButtons
      >
        <UpdateCreditCard />
      </DeepLinkingRedirectLayout>
    ),
  },
  {
    path: "/email-verified",
    element: (
      <DeepLinkingRedirectLayout
        pageName="email-verified"
        header
        documentTitle="Email Verified"
        displayButtons
      >
        <VerifyEmail />
      </DeepLinkingRedirectLayout>
    ),
  },
  {
    path: "*",
    element: (
      <PageLayout pageName="not-found" documentTitle="404 Not Found" header>
        <ErrorPage />
      </PageLayout>
    ),
  },
  {
    path: "/id-protect",
    element: (
      <DeepLinkingRedirectLayout
        pageName="id-protect"
        documentTitle="Id Protect"
        header
        displayButtons
      >
        <IdProtect />
      </DeepLinkingRedirectLayout>
    ),
  },
]);

export default router;
