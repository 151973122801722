import React from "react";
import { AnchorLink, Container, Padding, Text } from "@kidslivesafe/sc-react";
import { useAppSelector } from "../../../hooks/hooks";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

import "./personal-information-disclaimer.css";

export interface PersonalInformationDisclaimerProps {}

const PersonalInformationDisclaimer: React.FC<
  PersonalInformationDisclaimerProps
> = () => {
  const brandInfo = useAppSelector(selectBrandInfo);

  return (
    <Container
      altColor="grey"
      customClassName={
        // if brand is pf, add a class to the container styling in property-report.css
        brandInfo.acronym === "pf" ? "pf-content-container-disclaimer" : ""
      }
    >
      <Padding space="md">
        <Text size="xs">
          <Text size="xs" tag="b">
            Disclaimer:
          </Text>
          {"  "}
          The phone numbers and email addresses provided are sourced from public
          records and may be inaccurate or outdated. Additionally, some owners
          may be on do-not-call lists or have telemarketing restrictions. Use
          responsibly, and remember that our{" "}
          <AnchorLink
            size="xs"
            inline
            href={`${brandInfo.homeUrl}/help-center/terms`}
          >
            Terms
          </AnchorLink>{" "}
          prohibit spam and other marketing activities.
        </Text>
      </Padding>
    </Container>
  );
};

export default PersonalInformationDisclaimer;
