import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Layout,
  CollapsableItemList,
  ContainerLineItem,
  ReportLink,
  IconList,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

import ConfidenceRating from "../../../components/UIComponents/ConfidenceRating/ConfidenceRating";

import {
  generateList,
  parseDate,
  formatAddress,
  convertToTitleCase,
} from "../../../utils";

import {
  selectPhoneReport,
  selectPhoneReportStatus,
} from "../../../features/reports/reportsSlice";

import {
  usePeopleReportDispatch,
  PullPeopleReportParams,
} from "../../../hooks/peopleReportHook";
import PersonalInformationDisclaimer from "../../../components/UIComponents/PersonalInformationDisclaimer/PersonalInformationDisclaimer";

const PossiblePeople: React.FC<{ sectionId: string; printReady?: boolean }> = ({
  sectionId,
  printReady,
}) => {
  //store state
  const pulledReport = useAppSelector(selectPhoneReport);
  const phoneReportStatus = useAppSelector(selectPhoneReportStatus);

  const loading = phoneReportStatus === "loading";

  const pullPeopleReport = usePeopleReportDispatch();

  const rating = (r: number) => {
    if (r >= 86) {
      return 5;
    } else if (r <= 85 && r >= 67) {
      return 4;
    } else if (r <= 66 && r >= 40) {
      return 3;
    } else if (r <= 39 && r >= 20) {
      return 2;
    } else if (r <= 19 && r >= 2) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <>
      {(pulledReport && pulledReport.results.people.length !== 0) || loading ? (
        <Container
          containerTitle={
            loading
              ? "Possible People Results"
              : `Possible People Results (${
                  pulledReport && pulledReport.results.people.length > 0
                    ? pulledReport.results.people.length
                    : 0
                })`
          }
          containerId={sectionId}
        >
          {loading ? (
            <LoadingContainerLineItem
              lineItemHeader
              lineItemSubHeader
              numberToLoad={5}
              children
            />
          ) : pulledReport && pulledReport.results.people.length > 0 ? (
            <>
              <CollapsableItemList isOpen={printReady}>
                {pulledReport.results.people.map((person, index) => (
                  <ContainerLineItem
                    lineItemHeader={
                      <Layout type="flex" flexProps={{ gap: "xxs" }}>
                        {person.reportToken ? (
                          <ReportLink<PullPeopleReportParams>
                            text={
                              person.name[0]
                                ? `${person.name[0].firstName ? person.name[0].firstName : ""} ${person.name[0].middleName ? person.name[0].middleName : ""} ${person.name[0].lastName ? person.name[0].lastName : ""}`
                                : "Unspecified"
                            }
                            inputValue={person.reportToken}
                            handleClick={pullPeopleReport}
                          />
                        ) : (
                          `${person.name[0] ? `${person.name[0].firstName ? person.name[0].firstName : ""} ${person.name[0].middleName ? person.name[0].middleName : ""} ${person.name[0].lastName ? person.name[0].lastName : ""}` : "Unspecified"}`
                        )}
                      </Layout>
                    }
                    lineItemSubHeader={generateList([
                      {
                        value: generateList(
                          [
                            {
                              value: person.dateFirstSeen
                                ? parseDate(person.dateFirstSeen, "year")
                                : "",
                              label: "",
                            },
                            {
                              value: person.dateLastSeen
                                ? parseDate(person.dateLastSeen, "year")
                                : "",
                              label: "",
                            },
                          ],
                          "-",
                        ),
                        label: "",
                      },
                      {
                        value: person.phone[0].phoneType
                          ? person.phone[0].phoneType !== "VoIP"
                            ? convertToTitleCase(person.phone[0].phoneType)
                            : person.phone[0].phoneType
                          : null,
                        label: "",
                      },
                    ])}
                    lineItemSubHeaderLine2={
                      <>
                        {person.phone[0].carrier ? person.phone[0].carrier : ""}
                        {person.phone[0].carrier ? <br /> : null}
                        {formatAddress(person.address[0], "with-postalCode")}
                      </>
                    }
                    key={index}
                    subContent={
                      <ConfidenceRating
                        numberOfColored={rating(person.maxScore)}
                        maxScore={person.maxScore}
                        toolTipContent="Represents data our algorighm predicts could be the most reliable. A higher confidence rating indicates a greater level of certainty."
                        iconType={IconList.IconHelp}
                      />
                    }
                    isOpen={printReady ? true : false}
                  />
                ))}
              </CollapsableItemList>
              <PersonalInformationDisclaimer />
            </>
          ) : null}
        </Container>
      ) : null}
    </>
  );
};

export default PossiblePeople;
