import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";

import "./print.css"; // used for print styling .page-break could be added
import "./property-report.css";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Padding,
  Text,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
  Modal,
  Icon,
  IconList,
  Input,
  Button,
  AnchorLink,
  Tag,
  SectionTitle,
} from "@kidslivesafe/sc-react";

import {
  selectIfReportIsOnList,
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";

import {
  parseDate,
  generatePropertyReportHighlights,
  formatAddress,
} from "../../../utils";

import { selectBrandInfo } from "../../../features/brand/brandSlice";

// Property Report Sections
import LotBuildingDetails from "./LotBuildingDetails";
import PropertyTimeline from "./PropertyTimeline";
import OwnershipHistory from "./OwnershipHistory";
import ResidentHistory from "./ResidentHistory";
import PropertySales from "./PropertySales";
import MarketValues from "./MarketValues";
import ValueTaxes from "./ValueTaxes";
import NeighborhoodOverview from "./NeighborhoodOverview";
import Evictions from "./Evictions";
import Liens from "./Liens";
import BusinessDetails from "./BusinessDetails";
import ProfessionalLicenses from "./ProfessionalLicenses";
import UCCFilings from "./UCCFilings";
import Foreclosures from "./Foreclosures";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";

import { selectPropertySearchParams } from "../../../features/multisearch/multisearchSlice";

import { useTogglePropertyMonitoringDispatch } from "../../../hooks/monitoringHook";
import useSiteJabberDispatch from "../../../hooks/siteJabberHook";

import NoResults from "../../../components/UIComponents/NoResults/NoResults";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";
import { PropertyMonitoringParamsInterface } from "../../../features/monitoring/monitoringAPI";
import {
  checkCurrentlyMonitoredProperty,
  selectMaxMonitoringReached,
  selectPropertyMonitoring,
  selectPropertyMonitoringStatus,
  updatePropertyMonitoringAsync,
} from "../../../features/monitoring/monitoringSlice";
import { selectMemberExperienceData } from "../../../features/authentication/authenticationSlice";
import TimedMonitoringModal from "../../../components/UIComponents/TimedMonitoringModal/TimedMonitoringModal";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import ReportPaddingWrapper from "../../../components/UIComponents/ReportPaddingWrapper/ReportPaddingWrapper";
import ListModal, {
  ListModalMode,
} from "../../../components/UIComponents/ListModal/ListModal";
import CurrentOwnership from "./CurrentOwnership";
import SiteJabberModal from "../../../components/UIComponents/SiteJabberModal/SiteJabberModal";

const PropertyReport: React.FC = () => {
  const propertySearch = useAppSelector(selectPropertySearchParams);
  // local state
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);
  const [openMonitoringModal, setOpenMonitoringModal] =
    useState<boolean>(false);
  const [nickname, setNickname] = useState<string>("");
  const [nicknameError, setNickNameError] = useState<boolean>(false);
  const [printReady, setPrintReady] = useState<boolean>(false);
  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const dispatch = useAppDispatch();

  const [mode, setMode] = useState<ListModalMode>("add-report");
  const [showListModal, setShowListModal] = useState<boolean>(false);

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  const monitoredProperties = useAppSelector(selectPropertyMonitoring);
  const monitoringLimitReached = useAppSelector(selectMaxMonitoringReached);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  const currentBreakpoint = useCurrentBreakpoint();

  useEffect(() => {
    // closes monitoring modal when max number of monitoring areas is hit
    if (monitoringLimitReached === true) {
      setOpenMonitoringModal(false);
    }
  }, [monitoringLimitReached]);

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  const { togglePropertyMonitoring, monitoringSuccess } =
    useTogglePropertyMonitoringDispatch();

  // store state
  const pulledReport = useAppSelector(selectPropertyReport);

  const { foundFlag, list, reportId } = useAppSelector(state =>
    selectIfReportIsOnList(state, pulledReport),
  );
  // this is used when the report is pulled to determine if it is on a list
  useEffect(() => {
    if (list && reportId) {
      setMode("remove-report");
    } else {
      setMode("add-report");
    }
    // eslint-disable-next-line
  }, [pulledReport]);

  // this is used when utilizing the list modal and will switch as it is used
  useEffect(() => {
    if (list && reportId) {
      setTimeout(() => {
        setMode("remove-report");
      }, 2000);
    } else {
      setTimeout(() => {
        setMode("add-report");
      }, 2000);
    }
  }, [list, reportId]);

  const currentlyMonitoring = useAppSelector(state =>
    checkCurrentlyMonitoredProperty(
      state,
      pulledReport && pulledReport.propertyReportSummary
        ? {
            addressLine1: pulledReport.propertyReportSummary.addressLine1,
            addressLine2: pulledReport.propertyReportSummary.addressLine2,
            city: pulledReport.propertyReportSummary.city,
            state: pulledReport.propertyReportSummary.state,
            zip: pulledReport.propertyReportSummary.zip,
          }
        : undefined,
    ),
  );
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const brandInfo = useAppSelector(selectBrandInfo);
  const runSiteJabber = useSiteJabberDispatch();

  const loading = propertyReportStatus === "loading";
  const monitoringLoading =
    useAppSelector(selectPropertyMonitoringStatus) === "loading";

  // container-titles
  const lotBuildingDetailsId = "lot-building-details";
  const propertyTimelineId = "property-timeline";
  const currentOwnershipId = "current-ownership";
  const ownershipHistoryId = "ownership-history";
  const residentHistoryId = "resident-history";
  const propertySalesId = "property-sales";
  const marketValuesId = "market-values";
  const valueTaxesId = "values-taxes";
  const neighborhoodId = "neighborhood-overview";
  const evictionsId = "evictions";
  const liensId = "liens";
  const businessesId = "businesses";
  const professionalLicensesId = "professional-licenses";
  const UccLicensesId = "ucc-licenses";
  const foreclosuresId = "foreclosures";

  // section-titles
  const propertyDetailsId = "property-details";
  const ownershipAndOccupancyId = "ownership-and-occupancy";
  const valuationAndTaxationId = "valuation-and-taxation";
  const legalAndFinancialId = "legal-and-financial";
  const neighborhoodInsightsId = "neighborhood-insights";

  // effects
  /* generate Navigation Props */
  useEffect(() => {
    setNavigateProps([
      {
        navigateToSectionId: propertyDetailsId,
        navigateToSectionText: "Property Details",
        itemCount: pulledReport
          ? (pulledReport.propertyReportSummary?.lotAndBuildingDetailsCount ??
              0) +
            (pulledReport.propertyReportSummary
              ?.propertyTransactionsDisplayCount ?? 0)
          : 0,
        newInfo: false,
        loading: loading,
      },
      {
        navigateToSectionId: ownershipAndOccupancyId,
        navigateToSectionText: "Ownership & Occupancy",
        itemCount: pulledReport
          ? (pulledReport.propertyReportSummary?.ownershipHistoryCount ?? 0) +
            (pulledReport.propertyReportSummary?.residentsCount ?? 0) +
            (pulledReport.propertyReportSummary?.ownershipCount ?? 0)
          : 0,
        newInfo:
          reportDifferences?.ownershipHistories ||
          reportDifferences?.residents ||
          reportDifferences?.ownerships,
        loading: loading,
      },
      {
        navigateToSectionId: valuationAndTaxationId,
        navigateToSectionText: "Valuation & Taxation",
        itemCount: pulledReport
          ? (pulledReport.propertyOwnershipHistory
              ? pulledReport.propertyOwnershipHistory.length
              : 0) +
            (pulledReport.propertyReportSummary?.marketValueCount ?? 0) +
            (pulledReport.propertyAssessments
              ? pulledReport.propertyAssessments.length
              : 0)
          : 0,
        newInfo:
          reportDifferences?.propertyTransactions ||
          reportDifferences?.marketValues,
        loading: loading,
      },
      {
        navigateToSectionId: legalAndFinancialId,
        navigateToSectionText: "Legal & Financial Information",
        itemCount: pulledReport
          ? (pulledReport.uccFilings ? pulledReport.uccFilings.length : 0) +
            (pulledReport.liens ? pulledReport.liens.length : 0) +
            (pulledReport.foreclosures ? pulledReport.foreclosures.length : 0) +
            (pulledReport.businesses ? pulledReport.businesses.length : 0) +
            (pulledReport.evictions ? pulledReport.evictions.length : 0) +
            (pulledReport.professionalLicenses
              ? pulledReport.professionalLicenses.length
              : 0)
          : 0,
        newInfo:
          reportDifferences?.uccFilings ||
          reportDifferences?.liens ||
          reportDifferences?.foreclosures ||
          reportDifferences?.businesses ||
          reportDifferences?.evictions ||
          reportDifferences?.professionalLicenses,
        loading: loading,
      },
      {
        navigateToSectionId: neighborhoodInsightsId,
        navigateToSectionText:
          (["prr"].includes(brandInfo.acronym) &&
            memberExperienceData &&
            memberExperienceData.variant &&
            [
              "m31_prr_/property-report_v1",
              "m31_prr_/property-report_v2",
            ].includes(memberExperienceData.variant)) ||
          ["pf"].includes(brandInfo.acronym) ? (
            <Layout type="flex" flexProps={{ gap: "xs", alignItems: "center" }}>
              <Text>Neighborhood Insights</Text>
              <Tag
                type="ai"
                size="small"
                tagText={
                  <Layout
                    type="flex"
                    flexProps={{ alignItems: "center", gap: "xxxs" }}
                  >
                    <Icon block="inline" name={IconList.IconAi} size="12" />
                    AI
                  </Layout>
                }
              />
            </Layout>
          ) : (
            "Neighborhood Insights"
          ),
        itemCount: pulledReport
          ? (pulledReport.propertyReportSummary?.neighborsCount ?? 0) +
            (pulledReport.neighborhoodSchools?.length ?? 0)
          : 0,
        newInfo: reportDifferences?.neighbors,
        loading: loading,
      },
    ]);
  }, [
    pulledReport,
    loading,
    reportDifferences,
    brandInfo,
    memberExperienceData,
  ]);

  useEffect(() => {
    // Show SiteJabber prompt only for select brands
    if (!loading) {
      runSiteJabber();
    }
  }, [loading]);

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Property Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport && pulledReport.propertyDetails?.fullAddress
              ? formatAddress(pulledReport.propertyDetails)
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport && pulledReport.propertyDetails
            ? `${originalTitle} - ${pulledReport.propertyDetails.fullAddress}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-container-line-item__content--closed",
          );
          const showMoreElements = document.querySelectorAll(
            ".sc-show-more__icon--closed",
          );
          if (elements.length === 0 && showMoreElements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  const handleSubmitNickName = (nickname: string) => {
    if (nickname.length > 15 || nickname.length === 0) {
      setNickNameError(true);
    } else {
      setNickNameError(false);
    }
  };

  return (
    <>
      {pulledReport || loading ? (
        <PrintableComponent
          ref={contentToPrint}
          printReady={printReady}
          reportType="property"
          header={
            pulledReport && pulledReport.propertyDetails
              ? formatAddress(pulledReport.propertyDetails, "with-postalCode")
              : undefined
          }
          subtitle={
            pulledReport && pulledReport.propertyReportSummary
              ? generatePropertyReportHighlights(pulledReport)
              : undefined
          }
          date={new Date().toLocaleDateString()}
        >
          <ReportTitle
            reportTitle="Property Report"
            reportType="property"
            handleAddToList={() => setShowListModal(true)}
            addedToList={loading ? false : foundFlag}
            handleDownload={
              loading
                ? () => {
                    console.log("Wait for loading");
                  }
                : () => {
                    sendMemberEvent();
                    handlePrint(null, () => null);
                  }
            }
          />
          <Sheet>
            <Padding
              space="none"
              bottom="md"
              breakpointStyles={{
                desktop: {
                  space: "md",
                  top: "none",
                },
              }}
            >
              <Layout
                type="grid"
                gridProps={{ gap: "md", templateColumns: "1-column" }}
                breakpointStyles={{
                  desktop: {
                    type: "grid",
                    gridProps: { templateColumns: componentFormat, gap: "md" },
                  },
                }}
                shrinkChildren
              >
                <>
                  <Layout
                    type="flex"
                    flexProps={{ gap: "md", direction: "column" }}
                    passThroughClassess="page-property-report__left-column"
                  >
                    <Sticky
                      space="sm"
                      turnOffAtBreakPoint={["mobile"]}
                      direction="header-offset"
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <ReportSummary<PropertyMonitoringParamsInterface>
                          reportDate={
                            loading ? (
                              <LoadingPlaceholder
                                height={"16px"}
                                width={"10rem"}
                              />
                            ) : (
                              `REPORT DATE ${parseDate(
                                new Date().toLocaleDateString(), // change to pulled report date
                                "MM/DD/YYYY",
                              )}`
                            )
                          }
                          reportTitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"25px"}
                                width={"15rem"}
                              />
                            ) : pulledReport &&
                              pulledReport.propertyDetails?.fullAddress ? (
                              <>
                                <Text size="xl" textAlign="center" tag="span">
                                  {pulledReport.propertyDetails.fullAddress}
                                </Text>
                                <br />
                                <Text size="xl" textAlign="center" tag="span">
                                  {pulledReport.propertyDetails.city},{" "}
                                  {pulledReport.propertyDetails.state}{" "}
                                  {pulledReport.propertyDetails.postalCode}
                                </Text>
                              </>
                            ) : (
                              ""
                            )
                          }
                          reportSubtitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"14px"}
                                width={"10rem"}
                              />
                            ) : pulledReport &&
                              pulledReport.propertyReportSummary
                                ?.residenceOverview ? (
                              pulledReport.propertyReportSummary
                                .residenceOverview
                            ) : (
                              ""
                            )
                          }
                          reportHighlights={
                            loading ? (
                              <LoadingPlaceholder
                                height={"18px"}
                                width={"12rem"}
                              />
                            ) : pulledReport ? (
                              generatePropertyReportHighlights(pulledReport)
                            ) : null
                          }
                          newInfo={
                            reportDifferences &&
                            Object.entries(reportDifferences).some(
                              ([key, value]) =>
                                key !== "versionA" &&
                                key !== "versionB" &&
                                value === true,
                            )
                          }
                          monitoringInput={
                            pulledReport && pulledReport.propertyReportSummary
                              ? {
                                  addressLine1:
                                    pulledReport.propertyReportSummary
                                      .addressLine1,
                                  addressLine2:
                                    pulledReport.propertyReportSummary
                                      .addressLine2,
                                  city: pulledReport.propertyReportSummary.city,
                                  nickname: `Property ${monitoredProperties.length + 1}`,
                                  state:
                                    pulledReport.propertyReportSummary.state,
                                  zip: pulledReport.propertyReportSummary.zip,
                                  version:
                                    pulledReport.propertyReportSummary.version,
                                  bedrooms:
                                    pulledReport.propertyDetails?.bedrooms,
                                  totalBaths:
                                    pulledReport.propertyDetails?.totalBaths,
                                  sqft: pulledReport.propertyDetails
                                    ?.buildingSquareFeet,
                                  lotAcres: pulledReport.propertyDetails?.acres,
                                }
                              : undefined
                          }
                          monitoringInitialState={
                            loading ? false : currentlyMonitoring
                          }
                          handleMonitoring={address => {
                            if (loading) {
                              console.log("Wait for loading");
                            } else {
                              if (currentlyMonitoring) {
                                togglePropertyMonitoring(address);
                              } else {
                                togglePropertyMonitoring(address);
                                setOpenMonitoringModal(true);
                              }
                            }
                          }}
                          monitoringSuccess={monitoringSuccess}
                          monitoringWiggle
                        />

                        {currentBreakpoint === "desktop" && (
                          <NavigateToSection
                            items={navigateProps}
                            tooltipContent={`The ${brandInfo.name} Property Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                          />
                        )}
                      </Layout>
                    </Sticky>
                  </Layout>
                  <Layout type="flex" flexProps={{ direction: "column" }}>
                    <Padding
                      space="none"
                      breakpointStyles={{ desktop: { top: "md" } }}
                    >
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        {/* property report sections */}
                        {/* Property Details */}
                        <ReportPaddingWrapper>
                          <SectionTitle
                            sectionTitle="Property Details"
                            sectionId={propertyDetailsId}
                          />
                        </ReportPaddingWrapper>
                        <LotBuildingDetails
                          containerId={lotBuildingDetailsId}
                          printReady={printReady}
                        />
                        {currentBreakpoint !== "desktop" && (
                          <ReportPaddingWrapper>
                            <NavigateToSection
                              items={navigateProps}
                              tooltipContent={`The ${brandInfo.name} Property Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                            />
                          </ReportPaddingWrapper>
                        )}
                        <PropertyTimeline
                          containerId={propertyTimelineId}
                          printReady={printReady}
                        />
                        {/* Ownership & Occupancy */}
                        <ReportPaddingWrapper>
                          <SectionTitle
                            sectionTitle="Ownership & Occupancy"
                            sectionId={ownershipAndOccupancyId}
                          />
                        </ReportPaddingWrapper>
                        {/* Do NOT show this section on print */}
                        {printReady ? null : (
                          <CurrentOwnership
                            containerId={currentOwnershipId}
                            printReady={printReady}
                          />
                        )}
                        <OwnershipHistory
                          containerId={ownershipHistoryId}
                          printReady={printReady}
                        />
                        <ResidentHistory
                          containerId={residentHistoryId}
                          printReady={printReady}
                        />
                        {/* Valuation & Taxation */}
                        <ReportPaddingWrapper>
                          <SectionTitle
                            sectionTitle="Valuation & Taxation"
                            sectionId={valuationAndTaxationId}
                          />
                        </ReportPaddingWrapper>
                        <PropertySales
                          containerId={propertySalesId}
                          printReady={printReady}
                        />
                        <MarketValues
                          containerId={marketValuesId}
                          printReady={printReady}
                        />
                        <ValueTaxes
                          containerId={valueTaxesId}
                          printReady={printReady}
                        />
                        {/* Legal & Financial Information */}
                        <ReportPaddingWrapper>
                          <SectionTitle
                            sectionTitle="Legal & Financial Information"
                            sectionId={legalAndFinancialId}
                          />
                        </ReportPaddingWrapper>
                        <UCCFilings
                          containerId={UccLicensesId}
                          printReady={printReady}
                        />
                        <Liens containerId={liensId} printReady={printReady} />
                        <Foreclosures
                          containerId={foreclosuresId}
                          printReady={printReady}
                        />
                        <BusinessDetails
                          containerId={businessesId}
                          printReady={printReady}
                        />
                        <Evictions
                          containerId={evictionsId}
                          printReady={printReady}
                        />
                        <ProfessionalLicenses
                          containerId={professionalLicensesId}
                          printReady={printReady}
                        />
                        {/* Neighborhood Insights */}
                        <NeighborhoodOverview
                          containerId={neighborhoodId}
                          sectionTitleId={neighborhoodInsightsId}
                          printReady={printReady}
                        />
                        {/* end of property report sections */}
                      </Layout>
                    </Padding>
                  </Layout>
                </>
              </Layout>
            </Padding>
          </Sheet>
        </PrintableComponent>
      ) : (
        <>
          <ReportTitle reportTitle="Property Report" reportType="property" />
          <NoResults
            searchType="property"
            header={
              <Text textAlign="center" fontWeight="bold" tag="h2">
                No results for
              </Text>
            }
            subHeader={
              <Text textAlign="center">
                We’ve run searches and found no data for this input. In some
                cases this may be due to privacy preferences or regulations.
              </Text>
            }
            noResultText={
              <Text tag="h2" fontWeight="bold" textAlign="center">
                {propertySearch?.addressLine1}{" "}
                {propertySearch?.addressLine2
                  ? propertySearch.addressLine2
                  : null}{" "}
                <br /> {propertySearch?.city ? propertySearch.city : null},{" "}
                {propertySearch?.state ? propertySearch.state : null}{" "}
                {propertySearch?.zip ? propertySearch.zip : null}
              </Text>
            }
            soloTab={["pf"].includes(brandInfo.acronym) ? true : false}
          />
        </>
      )}
      <Modal
        open={openMonitoringModal}
        setOpen={() => {
          setOpenMonitoringModal(!openMonitoringModal);
          setNickname("");
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Padding top="md" bottom="md">
          <Layout
            type="flex"
            flexProps={{ direction: "column", gap: "lg", alignItems: "center" }}
          >
            <Text tag="h2">Property Monitoring Set</Text>
            <Text textAlign="center">
              If we detect changes, we'll notify you with a{" "}
              <Text tag="span" color="monitoring">
                <Icon name={IconList.IconMonitoring} size={20} block="inline" />
              </Text>{" "}
              and send you an alert.
            </Text>
            <Layout
              type="flex"
              fillHorizontal
              flexProps={{
                gap: "sm",
                direction: "column",
                alignItems: "center",
              }}
            >
              <Layout
                type="flex"
                fillHorizontal
                flexProps={{
                  gap: "xxs",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <Text size="sm">Set a name for your report (optional)</Text>
                <Text size="xxs" tag="i">
                  Please use 15 characters maximum
                </Text>
              </Layout>
              <Layout
                type="flex"
                fillHorizontal
                flexProps={{
                  gap: "xs",
                  direction: "column",
                  alignItems: "center",
                }}
              >
                <Input
                  id="neighborhood-report__monitoring-modal"
                  value={nickname}
                  handleChange={input => {
                    setNickname(input);
                    handleSubmitNickName(input);
                  }}
                  type="text"
                  placeholder="Property 1 (optional)"
                  fill="fill"
                  error={nicknameError}
                />
                <Button
                  buttonText="Set Name"
                  fill="fill"
                  disabled={monitoringLoading || nicknameError}
                  handleClick={() => {
                    if (
                      currentlyMonitoring &&
                      pulledReport &&
                      pulledReport.propertyReportSummary
                    ) {
                      if (nickname.length > 0) {
                        dispatch(
                          updatePropertyMonitoringAsync({
                            addressLine1:
                              pulledReport.propertyReportSummary.addressLine1,
                            addressLine2:
                              pulledReport.propertyReportSummary.addressLine2,
                            city: pulledReport.propertyReportSummary.city,
                            state: pulledReport.propertyReportSummary.state,
                            zip: pulledReport.propertyReportSummary.zip,
                            nickname: nickname,
                          }),
                        );

                        setOpenMonitoringModal(false);
                        setNickname("");
                      } else {
                        setNickNameError(true);
                      }
                    }
                  }}
                />
                <AnchorLink
                  role="button"
                  size="xs"
                  handleClick={() => {
                    setOpenMonitoringModal(false);
                    setNickname("");
                  }}
                >
                  No Thanks, Exit
                </AnchorLink>
              </Layout>
            </Layout>
          </Layout>
        </Padding>
      </Modal>
      {pulledReport && pulledReport.propertyReportSummary && (
        <TimedMonitoringModal<PropertyMonitoringParamsInterface>
          reportTitle={
            pulledReport.propertyDetails?.fullAddress ? (
              <>
                <Text size="xl" textAlign="center" tag="span">
                  {pulledReport.propertyDetails.fullAddress}
                </Text>
                <br />
                <Text size="xl" textAlign="center" tag="span">
                  {pulledReport.propertyDetails.city},{" "}
                  {pulledReport.propertyDetails.state}{" "}
                  {pulledReport.propertyDetails.postalCode}
                </Text>
              </>
            ) : (
              ""
            )
          }
          toggleMonitoring={togglePropertyMonitoring}
          monitoringInput={{
            addressLine1: pulledReport.propertyReportSummary.addressLine1,
            addressLine2: pulledReport.propertyReportSummary.addressLine2,
            city: pulledReport.propertyReportSummary.city,
            nickname: `Property ${monitoredProperties.length + 1}`,
            state: pulledReport.propertyReportSummary.state,
            zip: pulledReport.propertyReportSummary.zip,
            version: pulledReport.propertyReportSummary.version,
            bedrooms: pulledReport.propertyDetails?.bedrooms,
            totalBaths: pulledReport.propertyDetails?.totalBaths,
            sqft: pulledReport.propertyDetails?.buildingSquareFeet,
            lotAcres: pulledReport.propertyDetails?.acres,
          }}
          currentlyMonitoring={currentlyMonitoring}
          monitoringSuccess={monitoringSuccess}
          loading={loading}
        />
      )}
      <ListModal
        modalMode={mode}
        setModalMode={setMode}
        displayModal={showListModal}
        setDisplayModal={setShowListModal}
        report={pulledReport}
        reportId={reportId ? reportId : undefined}
        list={list ? list : undefined}
      />
      <SiteJabberModal />
      <div className="stjr-instant-feedback"></div>
    </>
  );
};

export default PropertyReport;
