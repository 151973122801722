import { useAppDispatch, useAppSelector, useHandlePageNavigate } from "./hooks";

import {
  pullVehicleReportAsync,
  selectVehicleReportStatus,
} from "../features/reports/reportsSlice";

import { useLocation } from "react-router-dom";
import { PullVehicleReportParams } from "../features/reports/reportTypes";

import {
  setVehicleReportSearch,
  toggleReinitialize,
} from "../features/multisearch/multisearchSlice";

type UseAutoReportHook = () => (data: PullVehicleReportParams) => void;

/* Pull Vehicle Report */
const useVehicleReportDispatch: UseAutoReportHook = () => {
  const dispatch = useAppDispatch();
  const searchStatus = useAppSelector(selectVehicleReportStatus);
  const pageNavigate = useHandlePageNavigate();
  const location = useLocation();

  const pullVehicleReport = (data: PullVehicleReportParams) => {
    if (searchStatus === "loading") return;

    dispatch(pullVehicleReportAsync(data));
    dispatch(setVehicleReportSearch(data));
    dispatch(toggleReinitialize());

    if (location.pathname !== "/vehicle-report") {
      pageNavigate("/vehicle-report");
    } else {
      window.scrollTo(0, 0);
    }
  };

  return pullVehicleReport;
};

export { useVehicleReportDispatch };
