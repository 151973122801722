import {
  Container,
  Layout,
  Text,
  Padding,
  Icon,
  IconList,
  Monitoring,
  Spotlight,
  Button,
  Modal,
  AnchorLink,
} from "@kidslivesafe/sc-react";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectPeopleReport,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";

import ReportPaddingWrapper from "../../../components/UIComponents/ReportPaddingWrapper/ReportPaddingWrapper";

export interface ToggleMonitoringParams {
  reportToken: string;
  version: string;
}

const MonitoringSection: React.FC<{
  toggleMonitoring: (params: ToggleMonitoringParams) => void;
  sectionId?: string;
  printReady?: boolean;
  modal?: boolean;
  currentlyMonitoring: boolean;
  monitoringSuccess: "success" | "failed" | "";
}> = ({
  sectionId,
  printReady,
  modal = false,
  toggleMonitoring,
  currentlyMonitoring,
  monitoringSuccess,
}) => {
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const loading = peopleReportStatus === "loading";

  const [firstVisit, setFirstVisit] = useState<boolean>(false); // set this to true if this is the users first visit after signing up
  const [showMonitoringSection, setShowMonitoringSection] =
    useState<boolean>(!currentlyMonitoring);

  useEffect(() => {
    if (modal) {
      setTimeout(() => {
        setFirstVisit(true);
      }, 10000);
    }
  }, [modal]);

  useEffect(() => {
    setTimeout(() => {
      setShowMonitoringSection(!currentlyMonitoring);
    }, 3000);
  }, [currentlyMonitoring]);

  const handleMonitoring = () => {
    if (
      pulledReport &&
      pulledReport.reportToken &&
      pulledReport.reportSummary.version
    ) {
      toggleMonitoring({
        reportToken: pulledReport.reportToken,
        version: pulledReport.reportSummary.version,
      });
      if (modal) {
        setTimeout(() => {
          setFirstVisit(false);
        }, 3000);
      } else {
        setFirstVisit(false);
      }
    }
  };

  const renderContent = () => {
    return (
      <Padding top="lg" bottom="lg" left="md" right="md">
        <Layout
          type="flex"
          flexProps={{
            direction: "column",
            gap: "lg",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "xxxs",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text tag="h2" fontWeight="bold">
              Ready To Monitor
            </Text>
            <Text textAlign="center" tag="h2" fontWeight="bold">
              {pulledReport && pulledReport.aliases
                ? pulledReport.aliases[0].fullName
                : ""}
              ?
            </Text>
          </Layout>
          <Text textAlign="center">
            If we detect changes, we'll notify you with a{" "}
            <Icon
              block="inline"
              iconColor="#F2B415"
              name={IconList.IconMonitoring}
            ></Icon>{" "}
            and send you an alert.
          </Text>
          <Spotlight pillshape>
            <Padding space="md">
              <Monitoring
                wiggle
                monitoringTitle={"MONITORING"}
                monitoringInput={"nothing"}
                handleMonitoring={() => {}}
                disabled
              ></Monitoring>
            </Padding>
          </Spotlight>
          <Text textAlign="center" size="sm">
            Your account allows you to monitor this report and up to 9 others.
          </Text>
          {!currentlyMonitoring && monitoringSuccess === "failed" ? (
            <Text fontWeight="semibold" textAlign="center" color={"error"}>
              You’ve reached the limit for Monitoring, manage your monitored
              reports{" "}
              <AnchorLink
                target="_self"
                inline
                stayUnderline
                href="/"
                fontWeight="semibold"
                color="error"
              >
                here
              </AnchorLink>
            </Text>
          ) : currentlyMonitoring && monitoringSuccess === "success" ? (
            <Text fontWeight="semibold" textAlign="center" color={"success"}>
              <Icon block="inline" name={IconList.IconMonitoringSuccess}></Icon>{" "}
              Monitoring enabled!
            </Text>
          ) : !currentlyMonitoring ? (
            <Button
              fill="not-fill"
              handleClick={handleMonitoring}
              buttonText="Enable Monitoring"
            />
          ) : null}
        </Layout>
      </Padding>
    );
  };

  if (!modal) {
    return (
      <>
        {!loading && pulledReport && showMonitoringSection ? (
          <ReportPaddingWrapper>
            <Container containerId={sectionId}>{renderContent()}</Container>
          </ReportPaddingWrapper>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        {!loading && pulledReport && showMonitoringSection ? (
          <Modal
            open={firstVisit}
            setOpen={() => {
              setFirstVisit(false);
            }}
            size="fit-content"
            outSideClose
            xClose
          >
            {renderContent()}
          </Modal>
        ) : null}
      </>
    );
  }
};

export default MonitoringSection;
