import React, { useEffect } from "react";
import {
  Padding,
  Sheet,
  Text,
  MultiSearch,
  Container,
  Layout,
} from "@kidslivesafe/sc-react";

import VehicleReportHistoryContainer from "./VehicleReportHistoryContainer";

import "./vehicleHome.css";
import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";

import { scrollToHashElement } from "../../../utils";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import SpacerMultiSearch from "../../../components/UIComponents/SpacerMultiSearch/SpacerMultiSearch";

const VehicleHome: React.FC = () => {
  const currentBreakpoint = useCurrentBreakpoint();

  const multiSearchProps = useMultiSearchProps(
    {
      resetInput: true,
      buttonText: "New Search",
      tabs: ["vehicle"],
      showCancel: false,
      openTab: "vehicle",
    },
    "vehicle-home",
  );

  //if there is an anchor hash, will scroll
  useEffect(() => {
    const scrollHeight = currentBreakpoint === "desktop" ? 75 : 80;

    // Scroll only if there's a hash in the URL
    const handleScroll = () => {
      // error occuriong where querySelector trying to be performed when it shouldnt inside of scrollToHashElement
      // ex. '#!/login'. '!' throws error.
      if (window.location.hash && !window.location.hash.includes("!")) {
        // Ensure a delay so that the page is fully rendered
        setTimeout(() => {
          scrollToHashElement(scrollHeight);
        }, 700);
      }
    };

    // Call on initial render or breakpoint change
    handleScroll();

    return () => {
      // Cleanup the event listener
      window.removeEventListener("hashchange", handleScroll);
    };
  }, [currentBreakpoint]); // Re-run effect when breakpoint changes

  return (
    <>
      <Container>
        <Sheet>
          <Padding left="md" right="md" top="md">
            <Layout type="flex" flexProps={{ gap: "xs", direction: "column" }}>
              <Text tag="h3" textAlign="center">
                Vehicle Search
              </Text>
              <Text size="sm" textAlign="center">
                Search over 200 million vehicle records to uncover key, detailed
                insights for informed car research.
              </Text>
            </Layout>
          </Padding>
        </Sheet>
      </Container>
      <MultiSearch {...multiSearchProps} />
      <Sheet>
        <Padding top="md" bottom="md">
          <VehicleReportHistoryContainer />
        </Padding>
        <SpacerMultiSearch options={["people", "property", "phone"]} />
      </Sheet>
    </>
  );
};

export default VehicleHome;
