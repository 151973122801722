import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Tab } from "@kidslivesafe/sc-react";
import { SearchPeopleParams } from "../../hooks/peopleReportHook";
import {
  PullVehicleReportParams,
  PullNeighborhoodReportParams,
  PullNeighborhoodSearchParams,
  PullPhoneReportParams,
  PullPropertyReportParams,
} from "../reports/reportTypes";
import { SearchSocialParams } from "../../hooks/socialSearchHook";
import { searchPeopleAsync } from "../reports/reportsSlice";
import { getSubscriptionDetailsAsync } from "../subscriptions/subscriptionSlice";
import { logoutAsync } from "../authentication/authenticationSlice";

export interface MultiSearchState {
  isOpen: boolean;
  tab: Tab;
  peopleSearchParams?: SearchPeopleParams;
  phoneSearchParams?: PullPhoneReportParams;
  propertySearchParams?: PullPropertyReportParams;
  socialSearchParams?: SearchSocialParams;
  vehicleSearchParams?: PullVehicleReportParams;
  neighborhoodReportSearchParams?: PullNeighborhoodReportParams;
  neighborhoodNameSearchParams?: PullNeighborhoodSearchParams;
  multiSearchError?: string;
  reinitialize?: boolean;
}

const initialState: MultiSearchState = {
  isOpen: false,
  tab: "people",
};

export const multiSearchSlice = createSlice({
  name: "multiSearch",
  initialState,
  reducers: {
    toggleOpen: state => {
      state.isOpen = !state.isOpen;
    },
    closeMultiSearch: state => {
      state.isOpen = false;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setPeopleSearch: (state, action) => {
      state.peopleSearchParams = action.payload;
    },
    setPhoneSearch: (state, action) => {
      state.phoneSearchParams = action.payload;
    },
    setPropertySearch: (state, action) => {
      state.propertySearchParams = action.payload;
    },
    setSocialSearch: (state, action) => {
      state.socialSearchParams = action.payload;
    },
    setVehicleReportSearch: (state, action) => {
      state.vehicleSearchParams = action.payload;
    },
    setNeighborhoodReportSearch: (state, action) => {
      state.neighborhoodReportSearchParams = action.payload;
    },
    setNeighborhoodNameSearch: (state, action) => {
      state.neighborhoodNameSearchParams = action.payload;
    },
    toggleReinitialize: state => {
      state.reinitialize = !state.reinitialize;
    },
  },
  extraReducers: builder => {
    builder
      /* logout */
      .addCase(logoutAsync.fulfilled, state => {
        state.isOpen = false;
        state.tab = "people";
        state.peopleSearchParams = undefined;
        state.phoneSearchParams = undefined;
        state.propertySearchParams = undefined;
        state.socialSearchParams = undefined;
        state.vehicleSearchParams = undefined;
        state.neighborhoodReportSearchParams = undefined;
        state.neighborhoodNameSearchParams = undefined;
        state.multiSearchError = undefined;
        state.reinitialize = undefined;
      })
      /* GetSubscriptionDetails */
      .addCase(getSubscriptionDetailsAsync.fulfilled, (state, action) => {
        if (action.payload.accountType === "auto") {
          state.tab = "vehicle";
        }
      })
      /* SearchPeopleAsync */
      .addCase(searchPeopleAsync.pending, state => {
        state.multiSearchError = undefined;
      })
      .addCase(searchPeopleAsync.fulfilled, (state, action) => {
        if (
          action.payload.message &&
          action.payload.message.startsWith("Unknown city:")
        ) {
          state.multiSearchError =
            "City not found.  Please check city spelling or try a different city name.";
        } else if (
          action.payload.message &&
          action.payload.message.startsWith("More than")
        ) {
          //what to do for too many results
          state.multiSearchError = "Too many results found";
        } else {
          state.multiSearchError = undefined;
        }
      });
  },
});

export const {
  toggleOpen,
  setTab,
  closeMultiSearch,
  setPeopleSearch,
  setPhoneSearch,
  setPropertySearch,
  setVehicleReportSearch,
  setNeighborhoodReportSearch,
  setNeighborhoodNameSearch,
  setSocialSearch,
  toggleReinitialize,
} = multiSearchSlice.actions;

export const selectIsOpen = (state: RootState) => state.multiSearch.isOpen;
export const selectTab = (state: RootState) => state.multiSearch.tab;

export const selectPeopleSearchParams = (state: RootState) =>
  state.multiSearch.peopleSearchParams;
export const selectPhoneSearchParams = (state: RootState) =>
  state.multiSearch.phoneSearchParams;
export const selectPropertySearchParams = (state: RootState) =>
  state.multiSearch.propertySearchParams;
export const selectSocialSearchParams = (state: RootState) =>
  state.multiSearch.socialSearchParams;
export const selectVehicleSearchParams = (state: RootState) =>
  state.multiSearch.vehicleSearchParams;
export const selectNeighborhoodReportSearchParams = (state: RootState) =>
  state.multiSearch.neighborhoodReportSearchParams;
export const selectNeighborhoodNameSearchParams = (state: RootState) =>
  state.multiSearch.neighborhoodNameSearchParams;
export const selectReinitialize = (state: RootState) =>
  state.multiSearch.reinitialize;
export const selectMultiSearchError = (state: RootState) =>
  state.multiSearch.multiSearchError;

export default multiSearchSlice.reducer;
