import React from "react";

import {
  AnchorLink,
  Container,
  Icon,
  IconList,
  Layout,
  Padding,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Button,
} from "@kidslivesafe/sc-react";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { useAppSelector } from "../../../hooks/hooks";
import { selectBrand } from "../../../features/brand/brandSlice";

import "./FcraCourtRecord.css";

const FcraCourtRecord: React.FC<{
  handleClick: () => void;
  sectionId: string;
}> = ({ handleClick, sectionId }) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const brandInfo = useAppSelector(selectBrand);
  return (
    <Container containerId={sectionId} topStroke bottomStroke>
      <Padding top="lg" bottom="lg" left="md" right="md">
        <Layout type="flex" flexProps={{ gap: "lg", direction: "column" }}>
          {/* header  */}
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
              alignItems: "center",
            }}
          >
            <Layout
              type="flex"
              flexProps={{ direction: "row", alignItems: "center", gap: "sm" }}
            >
              <Text fontWeight="bold" size="xxl">
                Court Records
              </Text>
              <Text tag="div" color="primary">
                <Tooltip>
                  <TooltipTrigger className="sc-tooltip__trigger">
                    <Icon name={IconList.IconHelp} size={16} />
                  </TooltipTrigger>
                  <TooltipContent className="sc-tooltip__content">
                    A Warrant is a legal document authorizing specific actions,
                    an Arrest is the physical act of taking someone into
                    custody, and criminal Charges are formal accusations brought
                    against an individual alleging their involvement in a crime.
                    There may be overlap between these records.
                  </TooltipContent>
                </Tooltip>
              </Text>
            </Layout>
            <Text fontWeight="semibold" size="sm" textAlign="center">
              To view ANY criminal and/or financial records you must confirm
              that your use is permitted.
            </Text>
          </Layout>
          {/* line */}
          <div className="page_fcraCourtRecords-section-line"></div>
          {/* content  */}
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            <Layout
              type="flex"
              flexProps={{
                direction: "row",
                alignItems: "center",
                gap: "sm",
              }}
            >
              <Text color="error">
                <Icon name={IconList.IconWarning} size={21} width={26} />
              </Text>
              <Text fontWeight="semibold">
                You are expressly prohibited from using {brandInfo.name} for any
                of the following:
              </Text>
            </Layout>
            <Layout type="flex" flexProps={{ direction: "column", gap: "xs" }}>
              <Padding left="md">
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "sm",
                  }}
                >
                  <Text color="error">
                    <Icon name={IconList.IconCircleX} size={12} height={20} />
                  </Text>
                  <Text size="sm">
                    <Text size="sm" tag="span" fontWeight="semibold">
                      Employment screening
                    </Text>{" "}
                    when evaluating a person for employment, reassignment,
                    promotion or retention.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "sm",
                  }}
                >
                  <Text color="error">
                    <Icon name={IconList.IconCircleX} size={12} height={20} />
                  </Text>
                  <Text size="sm">
                    <Text size="sm" tag="span" fontWeight="semibold">
                      Tenant screening
                    </Text>{" "}
                    including (but not limited to) leasing a residential or
                    commercial space.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "sm",
                  }}
                >
                  <Text color="error">
                    <Icon name={IconList.IconCircleX} size={12} height={20} />
                  </Text>
                  <Text size="sm">
                    <Text size="sm" tag="span" fontWeight="semibold">
                      Hiring of household workers
                    </Text>{" "}
                    including (but not limited to) nannies and domestic workers.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "sm",
                  }}
                >
                  <Text color="error">
                    <Icon name={IconList.IconCircleX} size={12} height={20} />
                  </Text>
                  <Text size="sm">
                    <Text size="sm" tag="span" fontWeight="semibold">
                      Educational qualifications
                    </Text>{" "}
                    including (but not limited to) a persons qualification for
                    an educational program or scholarship.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "sm",
                  }}
                >
                  <Text color="error">
                    <Icon name={IconList.IconCircleX} size={12} height={20} />
                  </Text>
                  <Text size="sm">
                    <Text size="sm" tag="span" fontWeight="semibold">
                      Business transactions initiated by an individual customer
                    </Text>{" "}
                    to review a personal customer account to determine whether
                    the person continues to meet the terms of such account.
                  </Text>
                </Layout>
                <Layout
                  type="flex"
                  flexProps={{
                    direction: "row",
                    gap: "sm",
                  }}
                >
                  <Text color="error">
                    <Icon name={IconList.IconCircleX} size={12} height={20} />
                  </Text>
                  <Text size="sm">
                    Any other reason prohibited by the{" "}
                    <AnchorLink
                      size="sm"
                      role="link"
                      href={`${brandInfo.homeUrl}/help-center/fcra`}
                      inline
                      target="_blank"
                    >
                      Fair Credit Reporting Act.
                    </AnchorLink>
                  </Text>
                </Layout>
              </Padding>
            </Layout>
          </Layout>
          {/* button */}
          <Container bodyBorderRadius altColor="alt">
            <Padding space="sm">
              <Layout
                type="flex"
                flexProps={{
                  gap: "md",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text size="sm" fontWeight="bold">
                  Confirm my use is permitted
                </Text>
                <Button
                  buttonText={
                    <Text fontWeight="normal" size="xs">
                      Confirm & View
                    </Text>
                  }
                  handleClick={handleClick}
                  fill="not-fill"
                  passThroughClassess="page_FcraCourtRecord-button-width"
                />
              </Layout>
            </Padding>
          </Container>
          {/* broder line */}
          <div className="page_fcraCourtRecords-section-line"></div>
          {/* disclaimer */}
          <Layout type="flex" flexProps={{ alignContent: "center" }}>
            <Text size="xxs">
              I acknowledge, agree, understand, and represent that, by clicking
              "Confirm and View" above, I am affirming that I am NOT using, and
              under no circumstances will use, any information obtained from 
              {brandInfo.name} in a manner that is subject to the{" "}
              <AnchorLink
                size="xxs"
                role="link"
                href={`${brandInfo.homeUrl}/help-center/fcra`}
                inline
                target="_blank"
              >
                Fair Credit Reporting Act
              </AnchorLink>
              , or for any other purpose prohibited by the{" "}
              <AnchorLink
                size="xxs"
                role="link"
                href={`${brandInfo.homeUrl}/help-center/terms`}
                inline
                target="_blank"
              >
                Terms of Service.
              </AnchorLink>
            </Text>
          </Layout>
        </Layout>
      </Padding>
    </Container>
  );
};

export default FcraCourtRecord;
