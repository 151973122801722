import React, { useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectVehicleReport,
  selectVehicleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Layout,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";

import {
  VehicleReportType,
  Warranty,
} from "../../../features/reports/reportTypes";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { generateList } from "@kidslivesafe/sc-foundation";

const Warranties: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const [warranties, setWarranties] = React.useState<Warranty[]>([]);

  let pulledReport = useAppSelector(selectVehicleReport) as VehicleReportType;
  const vehicleReportStatus = useAppSelector(selectVehicleReportStatus);

  const loading = vehicleReportStatus === "loading";

  useEffect(() => {
    if (pulledReport && pulledReport.warranties) {
      setWarranties(pulledReport.warranties);
    }
  }, [pulledReport]);

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Warranties ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.warranties
                  ? pulledReport.warranties.length
                  : 0
              })`
        }`}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={3}
          />
        ) : pulledReport &&
          pulledReport.warranties &&
          pulledReport.warranties.length > 0 ? (
          <>
            <CollapsableItemList
              numberToShow={5}
              isOpen={printReady ? true : false}
            >
              {warranties.map((warranty, index) => (
                <ContainerLineItem
                  key={index}
                  lineItemHeader={
                    <Layout
                      type="flex"
                      flexProps={{ gap: "xs", alignItems: "center" }}
                    >
                      <Text>{warranty.type}</Text>
                    </Layout>
                  }
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: warranty.months,
                        label: "s",
                        order: "reverse",
                      },
                      {
                        value: warranty.miles,
                        label: "s",
                        order: "reverse",
                      },
                    ],
                    "/",
                  )}
                />
              ))}
            </CollapsableItemList>
            <Container altColor="grey">
              <Padding space="md">
                <Text size="xs">
                  <Text size="xs" tag="b">
                    Disclaimer:
                  </Text>
                  {"  "}
                  The warranty data provided reflects manufacturer warranties
                  available at the time of sale. It does not guarantee current
                  warranty status, coverage, or transferability.
                </Text>
              </Padding>
            </Container>
          </>
        ) : (
          <Padding space="md">
            <Text size="sm" fontWeight="bold">
              No results found for Warranties
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};

export default Warranties;
