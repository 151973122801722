import React from "react";
import {
  Layout,
  Button,
  ReportSummary,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";

import "./ReportTeaser.css";
import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";

export interface ReportTeaserProps<T = any> {
  reportType:
    | "people"
    | "property"
    | "phone"
    | "social"
    | "vehicle"
    | "neighborhood"
    | "loading";
  reportTitle: string | React.ReactNode;
  reportHighlights: React.ReactNode;
  reportDetails?: React.ReactNode;
  inputValue: T;
  handleClick: (input: T) => void;
  imgUrl?: string;
  loading?: boolean;
}

const ReportTeaser: React.FC<ReportTeaserProps> = ({
  reportType,
  reportTitle,
  reportHighlights,
  reportDetails,
  handleClick,
  inputValue,
  imgUrl = "https://via.placeholder.com/352x139",
  loading = false,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();

  return (
    <div className="report-teaser">
      <Layout
        type="block"
        breakpointStyles={{
          desktop: {
            type: "grid",
            gridProps: {
              gap: "xs",
              templateColumns:
                imgUrl && imgUrl.length === 0 ? "1-column" : "2-column",
            },
          },
        }}
      >
        <Layout
          type="flex"
          flexProps={{
            direction: "column",
            gap: "md",
            justifyContent: "center",
            alignItems: "center",
          }}
          passThroughClassess="page-dashboard__left-column"
        >
          <ReportSummary
            reportTitle={reportTitle}
            reportTitleWordBreak={
              reportType === "social" ? "break-word" : undefined
            }
            reportHighlights={reportHighlights}
            reportDetails={reportDetails}
            reportType={loading ? "loading" : reportType}
          />
          {currentBreakpoint !== "desktop" && imgUrl ? (
            <div className="report-teaser__map">
              {loading ? (
                <div className="report-teaser__map--loading">
                  <LoadingPlaceholder height={"11.875rem"} width={"100%"} />
                </div>
              ) : (
                <img src={imgUrl} alt="location map" />
              )}
            </div>
          ) : null}
          <Button
            buttonText="View Report"
            handleClick={() => handleClick(inputValue)}
            disabled={loading}
          />
        </Layout>
        {currentBreakpoint === "desktop" && imgUrl ? (
          <div className="report-teaser__map">
            {loading ? (
              <div className="report-teaser__map--loading">
                <LoadingPlaceholder height={"100%"} width={"100%"} />
              </div>
            ) : (
              <img src={imgUrl} alt="location map" />
            )}
          </div>
        ) : null}
      </Layout>
    </div>
  );
};

export default ReportTeaser;
