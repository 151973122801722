import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useVehicleReportDispatch } from "../../hooks/vehicleReportHook";

interface TESTPullVehicleReportProps {}

const TestPullVehicleReport: React.FC<TESTPullVehicleReportProps> = () => {
  const pullVehicleReport = useVehicleReportDispatch();
  const { vin } = useParams();
  const hasFetchedReport = useRef(false);

  useEffect(() => {
    if (vin && !hasFetchedReport.current) {
      pullVehicleReport({ vin: vin });
      hasFetchedReport.current = true;
    }
  }, [vin, pullVehicleReport]);

  return <div>Pulling Report</div>;
};

export default TestPullVehicleReport;
