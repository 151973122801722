import React, { useEffect } from "react";

import {
  Padding,
  Layout,
  Sheet,
  Container,
  CollapsableItemList,
  ReportTitle,
  Text,
  Spotlight,
  MultiSearch,
  Tab,
} from "@kidslivesafe/sc-react";

import "./activityhistory.css";

import { useAppSelector } from "../../../hooks/hooks";
import {
  selectAllReportHistory,
  selectReportHistoryStatus,
} from "../../../features/reports/reportsSlice";
import ActivityHistoryLineItem from "../../../components/UIComponents/ActivityHistory/ActivityHistoryLineItem";
import { useMultiSearchProps } from "../../../hooks/multiSearchPropsHook";
import { selectAccountType } from "../../../features/authentication/authenticationSlice";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

const ActivityHistory: React.FC = () => {
  const [tabs, setTabs] = React.useState<Tab[]>([
    "people",
    "property",
    "phone",
    "social",
  ]);

  const accountType = useAppSelector(selectAccountType);
  const brandInfo = useAppSelector(selectBrandInfo);

  useEffect(() => {
    const whichTabs = (type: Tab | null) => {
      switch (type) {
        case "vehicle":
          setTabs(["vehicle"]);
          break;
        case "property":
          brandInfo.acronym === "pf"
            ? setTabs(["property"])
            : setTabs(["people", "property", "phone", "social"]);
          setTabs(["property"]);
          break;
        default:
          brandInfo.acronym === "pdc"
            ? setTabs(["people", "property", "phone", "vehicle"])
            : setTabs(["people", "property", "phone", "social"]);
          break;
      }
    };
    whichTabs(accountType);
  }, [accountType, brandInfo]);

  const multiSearchProps = useMultiSearchProps(
    { footer: false, resetInput: true, buttonText: "NEW SEARCH", tabs: tabs },
    "activity-history",
  );
  const reportHistory = useAppSelector(selectAllReportHistory);

  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);

  const loading = reportHistoryStatus === "loading";

  return (
    <>
      <ReportTitle reportTitle="Activity History" />
      <Sheet>
        <Padding space="md" bottom="lg">
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            <Container>
              <CollapsableItemList numberToShow={100}>
                {loading ? (
                  Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <ActivityHistoryLineItem
                        report={undefined}
                        loading={loading}
                        loadingKey={index}
                        key={index}
                      />
                    ))
                ) : reportHistory && reportHistory.length > 0 ? (
                  reportHistory.map(report =>
                    report ? (
                      <ActivityHistoryLineItem
                        report={report}
                        loadingKey={report.score}
                        key={report.score}
                      />
                    ) : null,
                  )
                ) : (
                  <Padding space="md">
                    <Text>No Activity History</Text>
                  </Padding>
                )}
              </CollapsableItemList>
            </Container>
          </Layout>
        </Padding>
        {accountType === "vehicle" ? null : (
          <Padding
            space="md"
            top="none"
            breakpointStyles={{ mobile: { space: "none" } }}
          >
            <Container>
              <Padding left="md" right="md" top="xl" bottom="xl">
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "lg",
                    alignItems: "center",
                    direction: "column",
                  }}
                >
                  <Text tag="h2">Ready to run another search?</Text>
                  {["pf"].includes(brandInfo.acronym) ? null : (
                    <Text textAlign="center">
                      Your account allows you to run People, Property, Phone and{" "}
                      {brandInfo.acronym === "pdc" ? "Vehicle" : "Social"}{" "}
                      searches.
                    </Text>
                  )}
                  <Spotlight passThroughClasses="activity-history__spotlight">
                    <MultiSearch {...multiSearchProps} />
                  </Spotlight>
                </Layout>
              </Padding>
            </Container>
          </Padding>
        )}
      </Sheet>
    </>
  );
};

export default ActivityHistory;
