import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

import {
  addNote,
  cancelSubscription,
  downsellSubscription,
  getSubscriptionDetails,
  retryPayment,
} from "./subscriptionAPI";
import {
  logoutAsync,
  clearExpired,
} from "../authentication/authenticationSlice";

export interface PrimarySubscription {
  isPrimary: boolean;
  active: boolean;
  price: number;
  billingInterval: number;
  billingDescriptor: string;
  cancelDate?: string;
  blueSnapMember: boolean;
  productName: string;
  peopleReportThreshold: number;
  propertyReportThreshold: number;
  phoneReportThreshold: number;
  socialReportThreshold: number;
  vehicleReportThreshold: number;
  subscriptionId: number;
  isInRebill: boolean;
}

export interface IDPSubscription {
  active: boolean;
  price: number;
  billingDate: string;
}

export interface DownsellDetails {
  downSellPrice: number;
  downSellProductId: number;
  isDownsold: boolean;
  downSellPeopleReportThreshold: number;
  downSellPropertyReportThreshold: number;
  downSellPhoneReportThreshold: number;
  downSellSocialReportThreshold: number;
  downSellVehicleReportThreshold: number;
}

export interface SubscriptionDetails {
  status: "idle" | "loading" | "failed";
  downsellDetails?: DownsellDetails;
  primarySubscription?: PrimarySubscription;
  idpSubscription?: IDPSubscription;
}

const initialState: SubscriptionDetails = {
  status: "idle",
};

export const getSubscriptionDetailsAsync = createAsyncThunk<any, void, {}>(
  "subscription/getSubscriptionDetails",
  async () => {
    return getSubscriptionDetails();
  },
);

// used on cancel-feedback page to send reason and feedback, no state updates
export const sendNoteAsync = createAsyncThunk<any, { content: string }, {}>(
  "subscription/sendNote",
  async ({ content }) => {
    return addNote({ content });
  },
);

export const downsellSubscriptionAsync = createAsyncThunk<any, void, {}>(
  "subscription/downsellSubscription",
  async () => {
    return downsellSubscription();
  },
);

export const cancelSubscriptionAsync = createAsyncThunk<any, void, {}>(
  "subscription/cancelSubscription",
  async () => {
    return cancelSubscription();
  },
);

export const retryPaymentAsync = createAsyncThunk<any, void, {}>(
  "subscription/retryPayment",
  async () => {
    return retryPayment();
  },
);

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateIsInRebill: state => {
      if (state.primarySubscription) {
        state.primarySubscription.isInRebill = false;
      }
    },
  },
  extraReducers: builder => {
    builder
      /* getSubscriptionDetailsAsync */
      .addCase(getSubscriptionDetailsAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(getSubscriptionDetailsAsync.fulfilled, (state, action) => {
        state.status = "idle";

        // set primary subscription on state
        if (action.payload.primarySubscription) {
          state.primarySubscription = {
            isPrimary: action.payload.primarySubscription.isPrimary,
            active: action.payload.primarySubscription.active,
            price: action.payload.primarySubscription.price,
            billingInterval: action.payload.primarySubscription.billingInterval,
            billingDescriptor:
              action.payload.primarySubscription.billingDescriptor,
            cancelDate: action.payload.primarySubscription.cancelDate,
            blueSnapMember: action.payload.primarySubscription.blueSnapMember,
            productName: action.payload.primarySubscription.productName,
            peopleReportThreshold:
              action.payload.primarySubscription.peopleReportThreshold,
            propertyReportThreshold:
              action.payload.primarySubscription.propertyReportThreshold,
            phoneReportThreshold:
              action.payload.primarySubscription.phoneReportThreshold,
            socialReportThreshold:
              action.payload.primarySubscription.socialReportThreshold,
            vehicleReportThreshold:
              action.payload.primarySubscription.autoReportThreshold,
            subscriptionId: action.payload.primarySubscription.subscriptionId,
            isInRebill: action.payload.primarySubscription.isInRebill,
          };
        }

        // set id protect subscription on state
        if (action.payload.idpSubscription) {
          state.idpSubscription = {
            active: action.payload.idpSubscription.active,
            price: action.payload.idpSubscription.price,
            billingDate: action.payload.idpSubscription.billingDate,
          };
        }

        // set downsell price on state
        state.downsellDetails = action.payload.downsellData && {
          downSellPrice: action.payload.downsellData.downsellPrice,
          downSellProductId: action.payload.downsellData.downsellProductId,
          isDownsold: action.payload.downsellData.isDownsold,
          downSellPeopleReportThreshold:
            action.payload.downsellData.downsellPeopleReportThreshold,
          downSellPropertyReportThreshold:
            action.payload.downsellData.downsellPropertyReportThreshold,
          downSellPhoneReportThreshold:
            action.payload.downsellData.downsellPhoneReportThreshold,
          downSellSocialReportThreshold:
            action.payload.downsellData.downsellSocialReportThreshold,
          downSellVehicleReportThreshold:
            action.payload.downsellData.downsellVehicleReportThreshold,
        };
      })
      .addCase(getSubscriptionDetailsAsync.rejected, state => {
        state.status = "failed";
      })
      /* retry Payment */
      .addCase(retryPaymentAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(retryPaymentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        clearExpired();
        //if is a duplicate retry will set isInRebill to false to close dashboard modal
        if (action.payload.duplicateRetry && state.primarySubscription) {
          state.primarySubscription.isInRebill = false;
        }
      })
      .addCase(retryPaymentAsync.rejected, state => {
        state.status = "failed";
      })
      /* downsell */
      .addCase(downsellSubscriptionAsync.pending, state => {
        state.status = "loading";
      })
      .addCase(downsellSubscriptionAsync.fulfilled, state => {
        state.status = "idle";
        if (state.downsellDetails) {
          state.downsellDetails.isDownsold = true;
        }
      })
      .addCase(downsellSubscriptionAsync.rejected, state => {
        state.status = "failed";
      })
      /* handle logout */
      .addCase(logoutAsync.pending, () => {})
      .addCase(logoutAsync.fulfilled, state => {
        state.primarySubscription = undefined;
        state.downsellDetails = undefined;
        state.idpSubscription = undefined;
      })
      .addCase(logoutAsync.rejected, () => {});
  },
});

/* actions */
export const { updateIsInRebill } = subscriptionSlice.actions;

/* Getters */
export const selectPrimarySubscription = (state: RootState) =>
  state.subscription.primarySubscription;

export const selectIDPSubscription = (state: RootState) =>
  state.subscription.idpSubscription;

export const selectDownsellDetails = (state: RootState) =>
  state.subscription.downsellDetails;

export const selectSubscriptionStatus = (state: RootState) =>
  state.subscription.status;

export default subscriptionSlice.reducer;
