import React, { useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectVehicleReport,
  selectVehicleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  AnchorLink,
  CollapsableItemList,
  Container,
  ContainerLineItem,
  Layout,
  Margin,
  Modal,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";

import { VehicleReportType } from "../../../features/reports/reportTypes";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import {
  convertStateAbbreviation,
  formatNumberCommas,
  parseDate,
  renderStateAutoContent,
  StateAbbreviation,
} from "../../../utils";

const TitleRecordHistory: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  let pulledReport = useAppSelector(selectVehicleReport) as VehicleReportType;
  const vehicleReportStatus = useAppSelector(selectVehicleReportStatus);
  const [openPdfModal, setOpenPdfModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);

  const loading = vehicleReportStatus === "loading";

  const renderMilage = (milage: string, units?: string): string => {
    if (milage === "EXEMPT") {
      return "Exempt";
    }

    const milageNumber = parseInt(milage);

    var displayUnits = units && units === "K" ? "KMs" : "Miles";
    return formatNumberCommas(milageNumber) + " " + displayUnits;
  };

  return (
    <Padding
      space="none"
      breakpointStyles={{
        mobile: {
          left: "md",
          right: "md",
        },
      }}
    >
      <Container
        containerId={containerId}
        containerTitle={`Title Records ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.vehicleSummary
                  ? pulledReport.vehicleSummary.titleCount
                  : 0
              })`
        }`}
      >
        <Padding space="md" bottom="none">
          <Text size="xs">Source: NMVTIS</Text>
        </Padding>
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            lineItemSubHeaderLine2
            lineItemDetails
            numberToLoad={5}
          />
        ) : pulledReport &&
          pulledReport.vehicleTitle &&
          pulledReport.vehicleTitle.currentTitleInformation &&
          pulledReport.vehicleTitle.currentTitleInformation.length > 0 ? (
          <CollapsableItemList numberToShow={5} isOpen={printReady}>
            {pulledReport?.vehicleTitle?.currentTitleInformation &&
              pulledReport?.vehicleTitle?.currentTitleInformation.map(
                (currentItem, index) => (
                  <ContainerLineItem
                    key={index}
                    lineItemHeader={
                      <Layout
                        type="flex"
                        flexProps={{ gap: "xxs", alignItems: "center" }}
                      >
                        <Text size="base">
                          {currentItem.vehicleOdometerReadingMeasure ===
                          "UNKNOWN"
                            ? "Unknown"
                            : renderMilage(
                                currentItem.vehicleOdometerReadingMeasure,
                                currentItem.vehicleOdometerReadingUnitCode,
                              )}
                        </Text>
                      </Layout>
                    }
                    lineItemSubHeader={
                      <Layout
                        type="flex"
                        flexProps={{
                          direction: "row",
                          alignItems: "flex-start",
                          gap: "xxs",
                        }}
                      >
                        <Text size="xs" fontWeight="semibold">
                          Title Issue Date:
                        </Text>
                        <Text size="xs" fontWeight="semibold">
                          {parseDate(
                            currentItem.titleIssueDate.date,
                            "utc:month-day-year",
                          )}
                        </Text>
                      </Layout>
                    }
                    lineItemSubHeaderLine2={
                      currentItem.vehicleIdentification.identificationID
                    }
                    subContent={
                      <AnchorLink
                        role="button"
                        handleClick={() => {
                          setModalContent(
                            renderStateAutoContent(
                              currentItem.titleIssuingAuthorityName as StateAbbreviation,
                            ),
                          );
                          setOpenPdfModal(true);
                        }}
                        size="xs"
                        hoverUnderline
                      >
                        {convertStateAbbreviation(
                          currentItem.titleIssuingAuthorityName,
                        )}
                      </AnchorLink>
                    }
                    isOpen={printReady || index === 0 ? true : false}
                  >
                    {pulledReport &&
                      currentItem.historyInformation &&
                      currentItem.historyInformation.length > 0 && (
                        <Padding space="md" top="none">
                          <Container altColor="grey">
                            <Padding space="sm" bottom="none">
                              <Text
                                size="xs"
                                textAlign="center"
                                fontWeight="bold"
                              >
                                Title Record History
                              </Text>
                            </Padding>
                            <CollapsableItemList>
                              {currentItem.historyInformation.map(
                                (historyItem, index) => (
                                  <ContainerLineItem
                                    key={index}
                                    lineItemHeader={
                                      <Layout
                                        type="flex"
                                        flexProps={{
                                          gap: "xxs",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Text size="base">
                                          {renderMilage(
                                            historyItem.vehicleOdometerReadingMeasure,
                                            historyItem.vehicleOdometerReadingUnitCode,
                                          )}
                                        </Text>
                                      </Layout>
                                    }
                                    lineItemSubHeader={
                                      historyItem.vehicleIdentification
                                        .identificationID
                                    }
                                    lineItemSubHeaderLine2={
                                      <AnchorLink
                                        role="button"
                                        handleClick={() => {
                                          setModalContent(
                                            renderStateAutoContent(
                                              historyItem.titleIssuingAuthorityName as StateAbbreviation,
                                            ),
                                          );
                                          setOpenPdfModal(true);
                                        }}
                                        size="xs"
                                        hoverUnderline
                                      >
                                        {convertStateAbbreviation(
                                          historyItem.titleIssuingAuthorityName,
                                        )}
                                      </AnchorLink>
                                    }
                                    lineItemDetails={
                                      <Layout
                                        type="flex"
                                        flexProps={{
                                          direction: "column",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <Text size="xs">Title Issue Date:</Text>
                                        <Text size="xs" fontWeight="semibold">
                                          {parseDate(
                                            historyItem.titleIssueDate.date,
                                            "utc:month-day-year",
                                          )}
                                        </Text>
                                      </Layout>
                                    }
                                  ></ContainerLineItem>
                                ),
                              )}
                            </CollapsableItemList>
                          </Container>
                        </Padding>
                      )}
                  </ContainerLineItem>
                ),
              )}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text size="sm" fontWeight="bold">
              No results found for Title Records
            </Text>
          </Padding>
        )}
      </Container>
      <Modal
        open={openPdfModal}
        setOpen={() => {
          setOpenPdfModal(!openPdfModal);
        }}
        size="fit-content"
        xClose
        outSideClose
      >
        <Margin top="xxl" bottom="xl">
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "lg",
            }}
          >
            {modalContent}
          </Layout>
        </Margin>
      </Modal>
    </Padding>
  );
};

export default TitleRecordHistory;
