import { CombinedReports } from "../features/reports/reportsSlice";
import { CombinedAddReportToList } from "../features/reports/reportTypes";
import { formatAddress } from "./formatAddress";
import { formatPhoneNumber } from "./formatPhoneNumber";
import { fullCapsText } from "./fullCapsText";
import { generateList } from "./generateList";
import {
  generateNeighborhoodReportHighlights,
  generatePeopleReportHighlights,
  generatePhoneReportHighlights,
  generatePropertyReportHighlights,
  generateSocialReportHighlights,
  generateVehicleReportHighlights,
} from "./generateReportHighlights";
import { renderSocialReportType } from "./renderSocialReportType";

export const addReportToListParamBuilder = (
  report: CombinedReports,
  listName: string,
): CombinedAddReportToList | undefined => {
  if (report) {
    switch (report?.reportType) {
      case "people": {
        return {
          listName: listName,
          reportType: "people",
          displayName: report.aliases ? report.aliases[0].fullName : "",
          reportDetails: generatePeopleReportHighlights(report),
          reportParams: {
            reportToken: report.reportToken ? report.reportToken : "",
          },
        };

        break;
      }
      case "phone": {
        return {
          listName: listName,
          displayName: formatPhoneNumber(report.searchCriteria.phone),
          reportDetails: generatePhoneReportHighlights(report, false) as string,
          reportType: "phone",
          reportParams: {
            phone: report?.searchCriteria.phone,
          },
        };
        break;
      }
      case "social": {
        if (
          report.socialReportSummary.email ||
          report.socialReportSummary.phone ||
          report.socialReportSummary.twitter
        ) {
          const displayName = generateList([
            {
              value: report.socialReportSummary.email,
              label: "",
            },
            {
              value: report.socialReportSummary.phone,
              label: "",
            },
            {
              value: report.socialReportSummary.twitter,
              label: "",
            },
          ]);
          return {
            listName: listName,
            displayName: fullCapsText(displayName),
            reportDetails: generateSocialReportHighlights(
              report,
              renderSocialReportType(report.socialReportSummary),
            ),
            reportType: "social",
            reportParams: {
              socialToken: displayName,
            },
          };
        }
        break;
      }
      case "property": {
        if (report.propertyDetails) {
          return {
            listName: listName,
            displayName: fullCapsText(
              formatAddress(report.propertyDetails, "with-postalCode"),
            ),
            reportDetails: generatePropertyReportHighlights(report),
            reportType: "property",
            reportParams: {
              addressLine1: report.propertyDetails.addressLine1
                ? report.propertyDetails.addressLine1
                : "",
              addressLine2: report.propertyDetails.addressLine2,
              city: report.propertyDetails.city
                ? report.propertyDetails.city
                : "",
              state: report.propertyDetails.state
                ? report.propertyDetails.state
                : "",
              zip: report.propertyDetails.postalCode
                ? report.propertyDetails.postalCode
                : "",
            },
          };
        }
        break;
      }
      case "vehicle": {
        if (report.vehicleDetails) {
          return {
            listName: listName,
            displayName: report.vehicleSummary.vehicleDescription
              ? fullCapsText(report.vehicleSummary.vehicleDescription)
              : "",
            reportDetails: generateVehicleReportHighlights(report),
            reportType: "vehicle",
            reportParams: {
              vin: report.vehicleSummary.vin,
            },
          };
        }
        break;
      }
      case "neighborhood": {
        if (report.addressObject) {
          return {
            listName: listName,
            displayName: fullCapsText(report.addressSearch),
            reportDetails: generateNeighborhoodReportHighlights(report),
            reportType: "neighborhood",
            reportParams: {
              addressLine1: report.addressObject.address
                ? report.addressObject.address
                : "",
              addressLine2: report.addressObject.addressLine2,
              city: report.addressObject.city ? report.addressObject.city : "",
              state: report.addressObject.state
                ? report.addressObject.state
                : "",
              zip: report.addressObject.zipCode
                ? report.addressObject.zipCode
                : "",
            },
          };
        }
        break;
      }
    }
  }
};
