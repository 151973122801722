import React, { useEffect, useRef } from "react";
import { Button, Layout, Modal, Padding, Text } from "@kidslivesafe/sc-react";
import {
  selectMemberExperienceData,
  setMemberExperienceDataAsync,
} from "../../../features/authentication/authenticationSlice";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import {
  selectAllReportHistory,
  selectPreSiteJabberModalShown,
  setPreSiteJabberModalShown,
  setShowSiteJabberFromFeedback,
} from "../../../features/reports/reportsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

export interface SiteJabberModalProps {}

const SiteJabberModal: React.FC<SiteJabberModalProps> = () => {
  const [show, setShow] = React.useState(true);
  const dispatch = useAppDispatch();
  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  const preSiteJabberModalShown = useAppSelector(selectPreSiteJabberModalShown);
  const showSiteJabberModal = useRef(false);
  const brandInfo = useAppSelector(selectBrandInfo);
  const allReportHistory = useAppSelector(selectAllReportHistory);

  useEffect(() => {
    let showModalFromReportHistory = false;
    let showModalFromMemberExperience = true;
    if (allReportHistory) {
      const filteredArray = allReportHistory.filter(report => {
        if (report.type === "people") {
          if (report.origin !== "Funnel") {
            return report;
          } else {
            return false;
          }
        } else {
          return report;
        }
      });
      if (filteredArray.length > 2) {
        showModalFromReportHistory = true;
      }

      if (
        memberExperienceData &&
        memberExperienceData["shownPreSiteJabberModal"] === "true"
      ) {
        showModalFromMemberExperience = false;
      }
      showSiteJabberModal.current =
        showModalFromReportHistory &&
        showModalFromMemberExperience &&
        !preSiteJabberModalShown;
    }
  }, [allReportHistory, preSiteJabberModalShown, memberExperienceData]);

  useEffect(() => {
    if (showSiteJabberModal.current) {
      dispatch(setPreSiteJabberModalShown());
      dispatch(
        setMemberExperienceDataAsync({
          key: "shownPreSiteJabberModal",
          value: true,
        }),
      );
    }
  }, [dispatch, showSiteJabberModal]);

  if (!showSiteJabberModal.current) {
    return null;
  } else {
    return (
      <Modal
        open={show}
        setOpen={() => setShow(false)}
        size="base"
        outSideClose
        xClose
        centerContentVertically
      >
        <Layout
          type="flex"
          fillVertical
          flexProps={{
            gap: "xl",
            direction: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Layout
            type="flex"
            flexProps={{
              gap: "lg",
              direction: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text tag="h2" textAlign="center">
              We value your feedback!
            </Text>

            <Text textAlign="center" fontWeight="normal">
              How has your experience with {brandInfo.name} been so far?
            </Text>
          </Layout>

          <Layout
            type="flex"
            flexProps={{
              gap: "md",
              justifyContent: "center",
              alignItems: "center",
              direction: "column",
            }}
            fillHorizontal
          >
            <Button
              handleClick={() => {
                setShow(false);
                dispatch(setShowSiteJabberFromFeedback());
                dispatch(
                  setMemberExperienceDataAsync({
                    key: "showSiteJabberFromFeedback",
                    value: true,
                  }),
                );
              }}
              buttonText="I Love It!"
              size="md"
              fill="default"
              color="primary"
            />
            <Button
              handleClick={() => {
                setShow(false);
              }}
              buttonText="It Could Be Better"
              size="md"
              fill="default"
              color="secondary"
            />
          </Layout>
        </Layout>
      </Modal>
    );
  }
};

export default SiteJabberModal;
