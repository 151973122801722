import React from "react";
import { useAppSelector } from "../../../hooks/hooks";
// redux getters
import {
  selectPropertyReport,
  selectPropertyReportMonitoringDifferences,
  selectPropertyReportStatus,
} from "../../../features/reports/reportsSlice";
// utils
import {
  generateList,
  parseDate,
  formatName,
  formatPhoneNumber,
  capFirstLetter,
  renderArrayOfObjectsPrep,
} from "../../../utils";

// components
import {
  Container,
  Text,
  CollapsableItemList,
  ContainerLineItem,
  Padding,
  ReportLink,
  Layout,
  Icon,
  IconList,
  Table,
  Tag,
} from "@kidslivesafe/sc-react";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";
import { PropertyCurrentOwnershipNames } from "../../../features/reports/reportTypes";
import PersonalInformationDisclaimer from "../../../components/UIComponents/PersonalInformationDisclaimer/PersonalInformationDisclaimer";
const CurrentOwnership: React.FC<{
  containerId: string;
  printReady?: boolean;
}> = ({ containerId, printReady }) => {
  const pulledReport = useAppSelector(selectPropertyReport);
  const propertyReportStatus = useAppSelector(selectPropertyReportStatus);
  const reportDifferences = useAppSelector(
    selectPropertyReportMonitoringDifferences,
  );
  const loading = propertyReportStatus === "loading";
  const pullPeopleReport = usePeopleReportDispatch();
  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{
        desktop: {
          space: "none",
        },
      }}
    >
      <Container
        containerTitle={`Current Ownership ${
          loading
            ? ""
            : `(${pulledReport && pulledReport.propertyReportSummary?.ownershipCount ? pulledReport.propertyReportSummary.ownershipCount : 0})`
        }`}
        containerId={containerId}
        tooltipContent="The list below includes potential property owners, with their contact details ranked by confidence level from highest to lowest. Contact information accuracy may vary from one person to the next."
        newInfo={reportDifferences?.ownerships}
      >
        {/* owner-occupied banner */}
        {!loading && pulledReport && pulledReport.propertyCurrentOwnership && (
          <Container altColor="container-header">
            <Padding space="xs" left="lg" top="xxxs">
              <Text tag="div">
                <Layout
                  type="flex"
                  flexProps={{
                    alignItems: "center",
                    gap: "xxs",
                  }}
                >
                  {pulledReport.propertyCurrentOwnership.ownerOccupied ? (
                    <Tag tagText="owner-occupied" type="sale" />
                  ) : (
                    <Tag tagText="not owner-occupied" type="deceased" />
                  )}
                </Layout>
              </Text>
            </Padding>
          </Container>
        )}
        {/* current owners */}
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            lineItemSubHeaderLine2
            numberToLoad={5}
          />
        ) : pulledReport &&
          pulledReport.propertyCurrentOwnership &&
          pulledReport.propertyCurrentOwnership.names &&
          pulledReport.propertyCurrentOwnership.names.length > 0 ? (
          <>
            <CollapsableItemList numberToShow={5} isOpen={printReady}>
              {pulledReport.propertyCurrentOwnership.names.map(
                (owner, index) => {
                  const ownerName = owner.firstName
                    ? formatName(owner)
                    : owner.businessName
                      ? owner.businessName
                      : null;
                  return (
                    <ContainerLineItem
                      key={`${ownerName}-${index}_line-item`}
                      lineItemHeader={
                        owner.reportToken ? (
                          <div key={`${ownerName}-${index}`}>
                            <ReportLink<PullPeopleReportParams>
                              text={`OWNER ${index + 1}: ${ownerName}`}
                              inputValue={owner.reportToken}
                              handleClick={pullPeopleReport}
                            />
                          </div>
                        ) : (
                          <div key={`${ownerName}-${index}`}>
                            <Text>{`OWNER ${index + 1}: ${ownerName}`}</Text>
                          </div>
                        )
                      }
                      lineItemSubHeader={
                        <Text size="xs">
                          <Text size="xs" tag="span" fontWeight="semibold">
                            Aquired property in:
                          </Text>{" "}
                          {pulledReport.propertyCurrentOwnership &&
                          pulledReport.propertyCurrentOwnership.saleDate
                            ? parseDate(
                                pulledReport.propertyCurrentOwnership.saleDate,
                                "year",
                              )
                            : "Unknown"}
                        </Text>
                      }
                      lineItemSubHeaderLine2={
                        <Layout
                          type="flex"
                          flexProps={{
                            gap: "xxxs",
                            alignItems: "center",
                          }}
                        >
                          <Text size="xs">
                            <Text size="xs" tag="span" fontWeight="semibold">
                              Possible Phone:
                            </Text>{" "}
                            {owner.ownerPhoneNumbers &&
                            owner.ownerPhoneNumbers[0]
                              ? generateList([
                                  {
                                    value: formatPhoneNumber(
                                      owner.ownerPhoneNumbers[0].phone,
                                    ),
                                    label: "",
                                  },
                                  {
                                    value: capFirstLetter(
                                      owner.ownerPhoneNumbers[0].carrierType,
                                    ),
                                    label: "",
                                  },
                                  " | ",
                                ])
                              : "Unknown"}
                          </Text>
                          {owner.ownerPhoneNumbers &&
                          owner.ownerPhoneNumbers[0] &&
                          owner.ownerPhoneNumbers[0].carrierType ? (
                            <Text color="success">
                              <Icon name={IconList.IconCircleCheck} size={14} />
                            </Text>
                          ) : null}
                        </Layout>
                      }
                      subContent={
                        <Text size="xs">
                          <Text size="xs" tag="span" fontWeight="semibold">
                            Possible Email:
                          </Text>{" "}
                          {owner.ownerEmailAddresses &&
                          owner.ownerEmailAddresses[0] &&
                          owner.ownerEmailAddresses[0].emailAddress
                            ? owner.ownerEmailAddresses[0].emailAddress
                            : "Unknown"}
                        </Text>
                      }
                    >
                      {(owner.ownerEmailAddresses &&
                        owner.ownerEmailAddresses.length > 1) ||
                      (owner.ownerPhoneNumbers &&
                        owner.ownerPhoneNumbers.length > 1) ? (
                        <Padding left="md" right="md">
                          <Table
                            tableDataObject={
                              renderArrayOfObjectsPrep(
                                [owner],
                                [
                                  "firstName",
                                  "middleName",
                                  "lastName",
                                  "businessName",
                                  "reportToken",
                                  "emailAddress",
                                  "score",
                                  "phone",
                                  "carrierType",
                                ],
                                [
                                  {
                                    key: ["ownerPhoneNumbers"],
                                    keyFormat: () =>
                                      "Additional Possible Phone Numbers",
                                    valueFormat: (
                                      value,
                                      currentObject: PropertyCurrentOwnershipNames,
                                    ) => {
                                      if (
                                        !currentObject.ownerPhoneNumbers ||
                                        currentObject.ownerPhoneNumbers.length <
                                          2
                                      ) {
                                        return null;
                                      }
                                      const jsxElements = [];

                                      const slicedPhoneNumbers =
                                        currentObject.ownerPhoneNumbers.slice(
                                          1,
                                        );
                                      for (
                                        let i = 0;
                                        i < slicedPhoneNumbers.length;
                                        i++
                                      ) {
                                        const phone = slicedPhoneNumbers[i];
                                        jsxElements.push(
                                          <Text
                                            passThroughClassess="sc-table-row__detail"
                                            key={`phone-${i}`}
                                            size="sm"
                                          >
                                            {phone.phone
                                              ? generateList([
                                                  {
                                                    value: formatPhoneNumber(
                                                      phone.phone,
                                                    ),
                                                    label: "",
                                                  },
                                                  {
                                                    value: phone.carrierType
                                                      ? capFirstLetter(
                                                          phone.carrierType,
                                                        )
                                                      : "Unknown",
                                                    label: "",
                                                  },
                                                  " | ",
                                                ])
                                              : "Unknown"}
                                          </Text>,
                                        );
                                      }

                                      return <>{jsxElements}</>;
                                    },
                                  },
                                  {
                                    key: ["ownerEmailAddresses"],
                                    keyFormat: () =>
                                      "Additional Possible Emails",
                                    valueFormat: (
                                      value,
                                      currentObject: PropertyCurrentOwnershipNames,
                                    ) => {
                                      if (
                                        !currentObject.ownerEmailAddresses ||
                                        currentObject.ownerEmailAddresses
                                          .length < 2
                                      ) {
                                        return null;
                                      }
                                      const jsxElements = [];

                                      const slicedEmails =
                                        currentObject.ownerEmailAddresses.slice(
                                          1,
                                        );

                                      for (
                                        let i = 0;
                                        i < slicedEmails.length;
                                        i++
                                      ) {
                                        const email = slicedEmails[i];
                                        jsxElements.push(
                                          <Text
                                            passThroughClassess="sc-table-row__detail"
                                            key={`email-${i}`}
                                            size="sm"
                                          >
                                            {email.emailAddress
                                              ? email.emailAddress
                                              : "Unknown"}
                                          </Text>,
                                        );
                                      }

                                      return (
                                        <Layout
                                          type="flex"
                                          flexProps={{
                                            direction: "column",
                                            gap: "xxs",
                                          }}
                                        >
                                          {jsxElements}
                                        </Layout>
                                      );
                                    },
                                  },
                                ],
                              )[0]
                            }
                            orderOfProperties={[
                              "Additional Possible Phone Numbers",
                              "Additional Possible Emails",
                            ]}
                          />
                        </Padding>
                      ) : null}
                    </ContainerLineItem>
                  );
                },
              )}
            </CollapsableItemList>
            <PersonalInformationDisclaimer />
          </>
        ) : (
          <Padding space="md">
            <Text size="sm" tag="b">
              Property owner information is not currently available for this
              property.
            </Text>
          </Padding>
        )}
      </Container>
    </Padding>
  );
};
export default CurrentOwnership;
