import { useAppDispatch, useAppSelector } from "./hooks";
import {
  addReportToListAsync,
  deleteListAsync,
  getAllListsAsync,
  getSpecificListAsync,
  removeReportFromListAsync,
  renameListAsync,
  selectListStatus,
} from "../features/reports/reportsSlice";
import { CombinedAddReportToList } from "../features/reports/reportTypes";

/* GetAllListDispatch */
const useGetAllListDispatch = () => {
  const dispatch = useAppDispatch();
  const listStatus = useAppSelector(selectListStatus);

  const getAllLists = () => {
    if (listStatus === "loading") return;
    dispatch(getAllListsAsync());
  };

  return getAllLists;
};

/* GetSpecificListDispatch */
const useGetSpecificListDispatch = () => {
  const dispatch = useAppDispatch();
  const listStatus = useAppSelector(selectListStatus);

  const getSpecificList = (specificListName: string) => {
    if (listStatus === "loading") return;
    dispatch(getSpecificListAsync({ specificListName }));
  };

  return getSpecificList;
};

/* AddReportToListDispatch */
const useAddReportToListDispatch = () => {
  const dispatch = useAppDispatch();
  const listStatus = useAppSelector(selectListStatus);

  const addReportToList = (report: CombinedAddReportToList) => {
    if (listStatus === "loading") return;
    dispatch(addReportToListAsync(report)).then(action => {
      if (addReportToListAsync.fulfilled.match(action)) {
        dispatch(getSpecificListAsync({ specificListName: report.listName }));
      }
    });
  };

  return addReportToList;
};

/* RemoveReportFromListDispatch */
const useRemoveReportFromListDispatch = () => {
  const dispatch = useAppDispatch();
  const listStatus = useAppSelector(selectListStatus);

  const removeReportFromList = (report: {
    savedReportsListId: number;
    savedReportId: number;
  }) => {
    if (listStatus === "loading") return;
    dispatch(removeReportFromListAsync(report));
  };

  return removeReportFromList;
};

/* RenameListDispatch */
const useRenameListDispatch = () => {
  const dispatch = useAppDispatch();
  const listStatus = useAppSelector(selectListStatus);

  const renameList = (listValues: {
    savedReportsListId: number;
    listName: string;
  }) => {
    if (listStatus === "loading") return;
    dispatch(
      renameListAsync({
        savedReportsListId: listValues.savedReportsListId,
        listName: listValues.listName,
      }),
    );
  };

  return renameList;
};

/* DeleteListDispatch */
const useDeleteListDispatch = () => {
  const dispatch = useAppDispatch();
  const listStatus = useAppSelector(selectListStatus);

  const deleteList = (listInfo: { savedReportsListId: number }) => {
    if (listStatus === "loading") return;
    dispatch(
      deleteListAsync({ savedReportsListId: listInfo.savedReportsListId }),
    );
  };

  return deleteList;
};

export {
  useGetAllListDispatch,
  useGetSpecificListDispatch,
  useAddReportToListDispatch,
  useRemoveReportFromListDispatch,
  useRenameListDispatch,
  useDeleteListDispatch,
};
