import {
  setSiteJabberShown,
  selectSiteJabberShown,
  selectAllReportHistory,
  selectShowSiteJabberFromFeedback,
} from "../features/reports/reportsSlice";
import { selectBrandInfo } from "../features/brand/brandSlice";
import {
  selectMemberData,
  setMemberExperienceDataAsync,
  selectMemberExperienceData,
} from "../features/authentication/authenticationSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useEffect, useRef } from "react";

const useSiteJabberDispatch = () => {
  const dispatch = useAppDispatch();
  const siteJabberShown = useAppSelector(selectSiteJabberShown);
  const showSiteJabber = useRef(false);
  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  const brandInfo = useAppSelector(selectBrandInfo);
  const memberData = useAppSelector(selectMemberData);
  const allReportHistory = useAppSelector(selectAllReportHistory);
  const showSiteJabberFromFeedback = useAppSelector(
    selectShowSiteJabberFromFeedback,
  );

  useEffect(() => {
    let showPromptFromReportHistory = false;
    let showPromptFromMemberExperience = true;
    let showPromptFromFeedback = false;
    if (allReportHistory) {
      // Use the report history to determine if review prompt should be shown.
      // If the report history length is greater than the 4, then show the prompt.
      const filteredArray = allReportHistory.filter(report => {
        if (report.type === "people") {
          if (report.origin !== "Funnel") {
            return report;
          } else {
            return false;
          }
        } else {
          return report;
        }
      });

      if (filteredArray.length > 4) {
        showPromptFromReportHistory = true;
      }
    }
    // Check the member experience to see if we have shown the prompt to this customer in the past.
    // If so, we dont want to show it again
    if (
      memberExperienceData &&
      memberExperienceData["siteJabberPrompt"] === "true"
    ) {
      showPromptFromMemberExperience = false;
    }
    if (
      memberExperienceData &&
      (memberExperienceData["showSiteJabberFromFeedback"] === "true" ||
        showSiteJabberFromFeedback)
    ) {
      showPromptFromFeedback = true;
    }
    showSiteJabber.current =
      showPromptFromReportHistory &&
      showPromptFromMemberExperience &&
      showPromptFromFeedback &&
      !siteJabberShown;
  }, [siteJabberShown, allReportHistory]);

  const InitializeInstantFeedbackWidget = () => {
    const handleWidgetLoaded = () => {
      const STJRV2 = (window as any).STJRV2;
      if (STJRV2) {
        STJRV2.setConfig({
          language: "en",
          user: {
            first_name: memberData.firstname,
            last_name: memberData.lastname,
            email: memberData.username,
          },
          order_id: memberData.orderId,
        });
      }
    };

    document
      .querySelector("body")
      ?.addEventListener(
        "STJRV2.instantFeedbackWidgetLoaded",
        handleWidgetLoaded,
      );

    // Cleanup
    return () => {
      document
        .querySelector("body")
        ?.removeEventListener(
          "STJRV2.instantFeedbackWidgetLoaded",
          handleWidgetLoaded,
        );
    };
  };

  const LoadSitejabberWidget = () => {
    const rC: string[] = [];
    let r = false;

    // Collect all class names that start with "stjr-"
    document.querySelectorAll('[class*="stjr-"]').forEach(element => {
      rC.push(
        ...Array.from(element.classList).filter(className =>
          /^stjr-/.test(className),
        ),
      );
    });

    const uniqueRC = Array.from(new Set(rC));
    const scriptTag = document.createElement("script");
    scriptTag.id = "sj-widget";
    scriptTag.src =
      `https://www.sitejabber.com/js/v2/${brandInfo.siteJabberId}/widgets.js` +
      (uniqueRC.length
        ? `?widget-classes=${uniqueRC.join("|")}`
        : "?widget-classes=stjr-base");

    // Handle the script load
    scriptTag.onload = () => {
      if (!r) {
        r = true;
        // Callback logic if needed
      }
    };

    // Optional: Handle older browsers using 'readystatechange'
    scriptTag.addEventListener("readystatechange", () => {
      const scriptWithReadyState = scriptTag as HTMLScriptElement & {
        readyState?: string;
      };
      if (
        !r &&
        (scriptWithReadyState.readyState === "complete" ||
          scriptWithReadyState.readyState === "loaded")
      ) {
        r = true;
        // Callback logic if needed
      }
    });

    // Append the script tag to the document
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode?.insertBefore(scriptTag, firstScript);
  };

  const runSiteJabber = () => {
    if (showSiteJabber.current) {
      InitializeInstantFeedbackWidget();
      LoadSitejabberWidget();

      dispatch(setSiteJabberShown());
      dispatch(
        setMemberExperienceDataAsync({ key: "siteJabberPrompt", value: true }),
      );
    }
  };
  return runSiteJabber;
};

export default useSiteJabberDispatch;
