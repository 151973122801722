import React from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Padding,
  Text,
  SectionTitle,
  CollapsableItemList,
  ContainerLineItem,
  Table,
  ReportLink,
  Layout,
} from "@kidslivesafe/sc-react";

import {
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  generateList,
  parseDate,
  renderArrayOfObjectsPrep,
  convertStateAbbreviation,
  formatName,
} from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";
import { NameDetails } from "../../../features/reports/reportTypes";
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

const LifestyleData: React.FC<{ sectionId: string; printReady?: boolean }> = ({
  sectionId,
  printReady,
}) => {
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );

  const pullPeopleReport = usePeopleReportDispatch();

  const loading = peopleReportStatus === "loading";

  /* generate voter Object */
  const voterRegistrationsObject = renderArrayOfObjectsPrep(
    pulledReport?.voterRegistrations || [],
    ["reportToken"],
    [
      {
        key: ["fullName"],
        valueFormat: (value, currentObject: NameDetails) => {
          if (
            currentObject.reportToken &&
            currentObject.reportToken !== pulledReport?.reportToken
          ) {
            return (
              <ReportLink<PullPeopleReportParams>
                smallText
                inputValue={currentObject.reportToken}
                handleClick={pullPeopleReport}
                text={value}
              />
            );
          } else {
            return value;
          }
        },
      },
    ],
  );

  /* generate vehicle Object */
  const vehiclesObject = renderArrayOfObjectsPrep(
    pulledReport?.vehicles || [],
    ["reportToken"],
    [],
  );

  /* generate hunting Object */
  const huntingObject = renderArrayOfObjectsPrep(
    pulledReport?.huntingPermits || [],
    ["reportToken"],
    [],
  );

  /* generate weapon Object */
  const weaponsObject = renderArrayOfObjectsPrep(
    pulledReport?.concealedWeaponPermits || [],
    ["reportToken"],
    [],
  );

  /* generate pilotLicense Object */
  const pilotLicenseObject = renderArrayOfObjectsPrep(
    pulledReport?.pilotLicenses || [],
    ["reportToken"],
    [],
  );

  /* generate driversLicense Object */
  const driversLicenseObject = renderArrayOfObjectsPrep(
    pulledReport?.driversLicenses || [],
    ["reportToken"],
    [],
  );

  return (
    <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
      <SectionTitle
        sectionTitle="Lifestyle Data"
        headingLevel={2}
        sectionId={sectionId}
      />
      <Container
        containerTitle={`Possible Voter & Vehicle Registrations ${
          loading
            ? ""
            : `(${
                (pulledReport && pulledReport.voterRegistrations
                  ? pulledReport.voterRegistrations.length
                  : 0) +
                (pulledReport && pulledReport.vehicles
                  ? pulledReport.vehicles.length
                  : 0)
              })`
        }`}
        newInfo={peopleReportMonitoringDifferences?.voterRegistrations}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : (pulledReport &&
            pulledReport.voterRegistrations &&
            pulledReport.voterRegistrations[0]) ||
          (pulledReport &&
            pulledReport.vehicles &&
            pulledReport.vehicles[0]) ? (
          <CollapsableItemList isOpen={printReady}>
            {pulledReport.voterRegistrations &&
              pulledReport.voterRegistrations.map(
                (voterRegistration, index) => (
                  <ContainerLineItem
                    lineItemHeader={"VOTER REGISTRATION"}
                    lineItemSubHeader={generateList(
                      [
                        {
                          value: voterRegistration.dateOfRegistration
                            ? parseDate(
                                voterRegistration.dateOfRegistration,
                                "year",
                              )
                            : null,
                          label: "",
                        },
                        {
                          value: voterRegistration.state
                            ? convertStateAbbreviation(voterRegistration.state)
                            : null,
                          label: "",
                        },
                      ],
                      " | ",
                    )}
                    key={index}
                    isOpen={printReady ? true : false}
                  >
                    {voterRegistrationsObject[index] ? (
                      <Padding space="md">
                        <Table
                          tableDataObject={voterRegistrationsObject[index]}
                          orderOfProperties={[]}
                        />
                      </Padding>
                    ) : null}
                  </ContainerLineItem>
                ),
              )}
            {pulledReport.vehicles &&
              pulledReport.vehicles.map((vehicle, index) => (
                <ContainerLineItem
                  lineItemHeader={"VEHICLE REGISTRATION"}
                  lineItemSubHeader={parseDate(vehicle.certificateDate, "year")}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {vehiclesObject[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={vehiclesObject[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text tag="b" size="sm">
              No Voter & Vehicle Registrations found{" "}
              {pulledReport
                ? `for ${formatName(pulledReport, "abbreviated")}`
                : null}
            </Text>
          </Padding>
        )}
      </Container>
      <Container
        containerTitle={`Possible Hunting & Weapon Permits ${
          loading
            ? ""
            : `(${
                (pulledReport && pulledReport.huntingPermits
                  ? pulledReport.huntingPermits.length
                  : 0) +
                (pulledReport && pulledReport.concealedWeaponPermits
                  ? pulledReport.concealedWeaponPermits.length
                  : 0)
              })`
        }`}
        newInfo={peopleReportMonitoringDifferences?.huntingPermits}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : (pulledReport &&
            pulledReport.huntingPermits &&
            pulledReport.huntingPermits[0]) ||
          (pulledReport &&
            pulledReport.concealedWeaponPermits &&
            pulledReport.concealedWeaponPermits[0]) ? (
          <CollapsableItemList isOpen={printReady}>
            {pulledReport.huntingPermits &&
              pulledReport.huntingPermits.map((huntingPermit, index) => (
                <ContainerLineItem
                  lineItemHeader={"HUNTING PERMIT"}
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: huntingPermit.dateOfRegistration
                          ? parseDate(huntingPermit.dateOfRegistration, "year")
                          : null,
                        label: "",
                      },
                      {
                        value: huntingPermit.state
                          ? convertStateAbbreviation(huntingPermit.state)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {huntingObject[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={huntingObject[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            {pulledReport.concealedWeaponPermits &&
              pulledReport.concealedWeaponPermits.map((weaponPermit, index) => (
                <ContainerLineItem
                  lineItemHeader={"CONCEALED WEAPON PERMIT"}
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: weaponPermit.issueDate
                          ? parseDate(weaponPermit.issueDate, "year")
                          : null,
                        label: "",
                        // TODO: state isn't on type, verify it can be passed
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {weaponsObject[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={weaponsObject[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text tag="b" size="sm">
              No Hunting & Weapon Permits found{" "}
              {pulledReport
                ? `for ${formatName(pulledReport, "abbreviated")}`
                : null}
            </Text>
          </Padding>
        )}
      </Container>
      <Container
        containerTitle={`Possible Vehicle Licenses ${
          loading
            ? ""
            : `(${
                (pulledReport && pulledReport.pilotLicenses
                  ? pulledReport.pilotLicenses.length
                  : 0) +
                (pulledReport && pulledReport.driversLicenses
                  ? pulledReport.driversLicenses.length
                  : 0)
              })`
        }`}
        newInfo={peopleReportMonitoringDifferences?.pilotLicenses}
        tooltipContent="While vehicle licenses are considered public records, many states prohibit their disclosure."
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
            children
          />
        ) : (pulledReport &&
            pulledReport.pilotLicenses &&
            pulledReport.pilotLicenses[0]) ||
          (pulledReport &&
            pulledReport.driversLicenses &&
            pulledReport.driversLicenses[0]) ? (
          <CollapsableItemList isOpen={printReady}>
            {pulledReport.driversLicenses &&
              pulledReport.driversLicenses.map((driversLicense, index) => (
                <ContainerLineItem
                  lineItemHeader={"DRIVER LICENSE"}
                  lineItemSubHeader={generateList(
                    [
                      {
                        value: driversLicense.state
                          ? convertStateAbbreviation(driversLicense.state)
                          : null,
                        label: "",
                      },
                    ],
                    " | ",
                  )}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {driversLicenseObject[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={driversLicenseObject[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
            {pulledReport.pilotLicenses &&
              pulledReport.pilotLicenses.map((pilotLicense, index) => (
                <ContainerLineItem
                  lineItemHeader={"PILOT LICENSE"}
                  lineItemSubHeader={generateList([
                    {
                      value: pilotLicense.fileDate
                        ? parseDate(pilotLicense.fileDate, "year")
                        : null,
                      label: "",
                    },
                    {
                      value: pilotLicense.state
                        ? convertStateAbbreviation(pilotLicense.state)
                        : null,
                      label: "",
                    },
                  ])}
                  isOpen={printReady ? true : false}
                  key={index}
                >
                  {pilotLicenseObject[index] ? (
                    <Padding space="md">
                      <Table
                        tableDataObject={pilotLicenseObject[index]}
                        orderOfProperties={[]}
                      />
                    </Padding>
                  ) : null}
                </ContainerLineItem>
              ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text tag="b" size="sm">
              No Vehicle Licenses found{" "}
              {pulledReport
                ? `for ${formatName(pulledReport, "abbreviated")}`
                : null}
            </Text>
          </Padding>
        )}
      </Container>
    </Layout>
  );
};

export default LifestyleData;
