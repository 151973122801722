import {
  Container,
  Icon,
  IconList,
  Layout,
  Padding,
  Spotlight,
  Tab,
  Text,
} from "@kidslivesafe/sc-react";
import React, { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useHandlePageNavigate,
} from "../../../hooks/hooks";
import {
  setTab,
  toggleOpen,
} from "../../../features/multisearch/multisearchSlice";

import "./spacerMultiSearch.css";
import { selectAccountType } from "../../../features/authentication/authenticationSlice";
import { selectBrandInfo } from "../../../features/brand/brandSlice";

type SearchOption =
  | "people"
  | "property"
  | "neighborhood"
  | "social"
  | "phone"
  | "vehicle";

interface SpacerMultiSearchProps {
  options?: Array<SearchOption>;
}

const SpacerMultiSearch: React.FC<SpacerMultiSearchProps> = ({
  options = ["people", "property", "neighborhood"],
}) => {
  const dispatch = useAppDispatch();
  const navigate = useHandlePageNavigate();
  const [tabs, setTabs] = useState<Tab[]>([
    "people",
    "property",
    "phone",
    "social",
    "vehicle",
  ]);
  const accountType = useAppSelector(selectAccountType);
  const brandInfo = useAppSelector(selectBrandInfo);

  const handleSpotlightClick = (tab: SearchOption) => {
    if (tabs.includes(tab)) {
      dispatch(setTab(tab));
      dispatch(toggleOpen());
    } else {
      navigate(`/${tab}-home`);
    }
  };

  useEffect(() => {
    const whichTabs = (type: Tab | null) => {
      switch (type) {
        case "vehicle":
          setTabs(["vehicle"]);
          break;
        case "neighborhood":
          setTabs(["neighborhood", "people", "property", "phone"]);
          break;
        case "people":
          brandInfo.acronym === "pdc"
            ? setTabs(["people", "property", "phone", "vehicle"])
            : setTabs(["people", "property", "phone", "social"]);
          break;
        case "phone":
          setTabs(["phone", "people", "property", "social"]);
          break;
        case "property":
          brandInfo.acronym === "pf"
            ? setTabs(["property"])
            : setTabs(["property", "people", "phone", "social"]);
          break;
        // no social funnel so no social accountType
        default: // defaults to "people" accountType
          setTabs(["people", "property", "phone", "social"]);
          break;
      }
    };

    whichTabs(accountType);
  }, [accountType, dispatch, brandInfo]);

  // Spotlight Render Function
  const renderSpotlight = (option: SearchOption) => {
    switch (option) {
      case "people":
        return (
          <Spotlight
            key={option}
            onClick={() => handleSpotlightClick("people")}
          >
            <Padding
              space="md"
              className="page-home_multisearch-spacer_spotlight-content"
            >
              <Layout
                type="flex"
                flexProps={{ gap: "lg", alignItems: "center" }}
                passThroughClassess="page-home_multisearch-spacer_spotlight-content_layout"
              >
                <div className="page-home__membership-information__icon">
                  <Icon name={IconList.IconPeople} size={32} />
                </div>
                <Layout type="flex" flexProps={{ direction: "column" }}>
                  <Text tag="h3" color="primary">
                    People Search
                  </Text>
                  <Text size="xs" tag="i">
                    Easily reconnect with individuals and find answers using our
                    robust database.
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Spotlight>
        );
      case "property":
        return (
          <Spotlight
            key={option}
            onClick={() => handleSpotlightClick("property")}
          >
            <Padding
              space="md"
              className="page-home_multisearch-spacer_spotlight-content"
            >
              <Layout
                type="flex"
                flexProps={{ gap: "lg", alignItems: "center" }}
                passThroughClassess="page-home_multisearch-spacer_spotlight-content_layout"
              >
                <div className="page-home__membership-information__icon">
                  <Icon name={IconList.IconProperty} size={32} />
                </div>
                <Layout type="flex" flexProps={{ direction: "column" }}>
                  <Text tag="h3" color="primary">
                    Property Search
                  </Text>
                  <Text size="xs" tag="i">
                    Discover property owners, deeds, values, and transactions
                    effortlessly.
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Spotlight>
        );
      case "phone":
        return (
          <Spotlight key={option} onClick={() => handleSpotlightClick("phone")}>
            <Padding
              space="md"
              className="page-home_multisearch-spacer_spotlight-content"
            >
              <Layout
                type="flex"
                flexProps={{ gap: "lg", alignItems: "center" }}
                passThroughClassess="page-home_multisearch-spacer_spotlight-content_layout"
              >
                <div className="page-home__membership-information__icon">
                  <Icon name={IconList.IconPhone} size={32} />
                </div>
                <Layout type="flex" flexProps={{ direction: "column" }}>
                  <Text tag="h3" color="primary">
                    Phone Search
                  </Text>
                  <Text size="xs" tag="i">
                    Identify the source of unknown or unwanted calls and texts.
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Spotlight>
        );
      case "neighborhood":
        return (
          <Spotlight
            key={option}
            onClick={() => handleSpotlightClick("neighborhood")}
          >
            <Padding
              space="md"
              className="page-home_multisearch-spacer_spotlight-content"
            >
              <Layout
                type="flex"
                flexProps={{ gap: "lg", alignItems: "center" }}
                passThroughClassess="page-home_multisearch-spacer_spotlight-content_layout"
              >
                <div className="page-home__membership-information__icon">
                  <Icon name={IconList.IconNeighborhoodWatch} size={32} />
                </div>
                <Layout type="flex" flexProps={{ direction: "column" }}>
                  <Text tag="h3" color="primary">
                    Neighborhood Watch
                  </Text>
                  <Text size="xs" tag="i">
                    Search for and monitor registered offenders in your area.
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Spotlight>
        );
      case "social":
        return (
          <Spotlight
            key={option}
            onClick={() => handleSpotlightClick("social")}
          >
            <Padding
              space="md"
              className="page-home_multisearch-spacer_spotlight-content"
            >
              <Layout
                type="flex"
                flexProps={{ gap: "lg", alignItems: "center" }}
                passThroughClassess="page-home_multisearch-spacer_spotlight-content_layout"
              >
                <div className="page-home__membership-information__icon">
                  <Icon name={IconList.IconSocial} size={32} />
                </div>
                <Layout type="flex" flexProps={{ direction: "column" }}>
                  <Text tag="h3" color="primary">
                    Social Search
                  </Text>
                  <Text size="xs" tag="i">
                    Get to know people beyond their public record. We scour
                    social media to find publicly available profiles.
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Spotlight>
        );
      case "vehicle":
        return (
          <Spotlight
            key={option}
            onClick={() => handleSpotlightClick("vehicle")}
          >
            <Padding
              space="md"
              className="page-home_multisearch-spacer_spotlight-content"
            >
              <Layout
                type="flex"
                flexProps={{ gap: "lg", alignItems: "center" }}
                passThroughClassess="page-home_multisearch-spacer_spotlight-content_layout"
              >
                <div className="page-home__membership-information__icon">
                  <Icon name={IconList.IconVehicle} size={32} />
                </div>
                <Layout type="flex" flexProps={{ direction: "column" }}>
                  <Text tag="h3" color="primary">
                    Vehicle Search
                  </Text>
                  <Text size="xs" tag="i">
                    Verify ownership, title history, branded status, and other
                    impactful information to avoid hidden risks.
                  </Text>
                </Layout>
              </Layout>
            </Padding>
          </Spotlight>
        );
      default:
        return null;
    }
  };

  return (
    <Padding
      left="md"
      right="md"
      breakpointStyles={{ mobile: { space: "none" } }}
    >
      <Container containerId="page-home__spacer-multi-search__container">
        <Padding space="md">
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            <Text size="sm" textAlign="left">
              Explore billions of data points across millions of records with
              these premium account search options.
            </Text>
            <Layout
              type="flex"
              flexProps={{ direction: "column", gap: "md" }}
              breakpointStyles={{
                desktop: {
                  type: "grid",
                  gridProps: { templateColumns: "2-column", gap: "md" },
                },
              }}
            >
              {options.map(option => renderSpotlight(option))}
            </Layout>
          </Layout>
        </Padding>
      </Container>
    </Padding>
  );
};

export default SpacerMultiSearch;
