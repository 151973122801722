import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";

import {
  Container,
  Layout,
  Padding,
  Tag,
  Text,
  SectionTitle,
  CollapsableItemList,
  ContainerLineItem,
  ReportLink,
} from "@kidslivesafe/sc-react";

import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import {
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";
import {
  generateList,
  FormattedRelative,
  sortedAssignGenerationsAndCombine,
  formatName,
} from "../../../utils";
import LoadingContainerLineItem from "../../../components/UIComponents/LoadingContainerLineItem/LoadingContainerLineItem";

const PeopleConnections: React.FC<{
  sectionId: string;
  printReady?: boolean;
}> = ({ sectionId, printReady }) => {
  const [formattedRelatives, setFormattedRelatives] = useState<
    FormattedRelative[]
  >([]);
  const pulledReport = useAppSelector(selectPeopleReport);
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );

  const loading = peopleReportStatus === "loading";

  // generate relative level
  useEffect(() => {
    if (pulledReport) {
      let formattedRelatives = sortedAssignGenerationsAndCombine(
        pulledReport.relatives,
      );

      setFormattedRelatives(formattedRelatives);
    }
  }, [pulledReport]);

  const pullPeopleReport = usePeopleReportDispatch();

  return (
    <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
      <SectionTitle
        sectionTitle="People Connections"
        headingLevel={2}
        sectionId={sectionId}
      />
      <Container
        containerTitle={`Possible Relatives ${
          loading
            ? ""
            : `(${formattedRelatives ? formattedRelatives.length : 0})`
        }`}
        newInfo={peopleReportMonitoringDifferences?.relatives}
        tooltipContent="First Level Relations are primarily based on the close family connections of the report subject, including immediate family and close relatives who share the same last name. Relatives through marriage may be considered less closely related and ranked lower in level."
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
          />
        ) : formattedRelatives && formattedRelatives[0] ? (
          <CollapsableItemList isOpen={printReady}>
            {formattedRelatives.map((relative, index) => (
              <ContainerLineItem
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    {relative.relativeToken ? (
                      <ReportLink<PullPeopleReportParams>
                        text={formatName(relative)}
                        inputValue={relative.relativeToken}
                        handleClick={pullPeopleReport}
                      />
                    ) : (
                      formatName(relative)
                    )}
                    {relative.dateOfDeath ? (
                      <Tag type="deceased" tagText="deceased" />
                    ) : null}
                  </Layout>
                }
                lineItemSubHeader={generateList([
                  {
                    value: relative.age ? relative.age : null,
                    label: "Age ",
                  },
                  {
                    value: relative.generation ? relative.generation : null,
                    label: " Level Relation",
                    order: "reverse",
                  },
                ])}
                isOpen={printReady ? true : false}
                key={index}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text tag="b" size="sm">
              No Relatives Found{" "}
              {pulledReport
                ? `for ${formatName(pulledReport, "abbreviated")}`
                : null}
            </Text>
          </Padding>
        )}
      </Container>
      <Container
        containerTitle={`Possible Associates ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.associates
                  ? pulledReport.associates.length
                  : 0
              })`
        }`}
        newInfo={peopleReportMonitoringDifferences?.associates}
        tooltipContent="Associates are individuals who are potentially linked based on shared addresses, co-occurrences, or other connections in the available records. These might include friends, family members, roommates, or business partners."
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            numberToLoad={5}
          />
        ) : pulledReport &&
          pulledReport.associates &&
          pulledReport.associates[0] ? (
          <CollapsableItemList isOpen={printReady}>
            {pulledReport.associates.map((associate, index) => (
              <ContainerLineItem
                lineItemHeader={
                  <Layout
                    type="flex"
                    flexProps={{ gap: "xxs", alignItems: "center" }}
                  >
                    {associate.associateToken ? (
                      <ReportLink<PullPeopleReportParams>
                        text={formatName(associate)}
                        inputValue={associate.associateToken}
                        handleClick={pullPeopleReport}
                      />
                    ) : (
                      formatName(associate)
                    )}
                    {associate.dateOfDeath ? (
                      <Tag type="deceased" tagText="deceased" />
                    ) : null}
                  </Layout>
                }
                lineItemSubHeader={generateList(
                  [
                    {
                      value: associate.age ? associate.age : null,
                      label: "Age ",
                    },
                    {
                      value:
                        associate.city && associate.state
                          ? `${associate.city}, ${associate.state}`
                          : associate.city
                            ? associate.city
                            : associate.state
                              ? associate.state
                              : null,
                      label: "",
                    },
                  ],
                  " | ",
                )}
                isOpen={printReady ? true : false}
                key={index}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text tag="b" size="sm">
              No Associates Found{" "}
              {pulledReport
                ? `for ${formatName(pulledReport, "abbreviated")}`
                : null}
            </Text>
          </Padding>
        )}
      </Container>
      <Container
        containerTitle={`Possible Neighbors ${
          loading
            ? ""
            : `(${
                pulledReport && pulledReport.neighbors
                  ? pulledReport.neighbors.length
                  : 0
              })`
        }`}
        newInfo={peopleReportMonitoringDifferences?.neighbors}
      >
        {loading ? (
          <LoadingContainerLineItem
            lineItemHeader
            lineItemSubHeader
            lineItemSubHeaderLine2
            numberToLoad={5}
          />
        ) : pulledReport &&
          pulledReport.neighbors &&
          pulledReport.neighbors[0] ? (
          <CollapsableItemList isOpen={printReady}>
            {pulledReport.neighbors.map((neighbor, index) => (
              <ContainerLineItem
                lineItemHeader={
                  <Layout type="flex" flexProps={{ gap: "xxs" }}>
                    {neighbor.neighborToken ? (
                      <ReportLink<PullPeopleReportParams>
                        text={formatName(neighbor)}
                        inputValue={neighbor.neighborToken}
                        handleClick={pullPeopleReport}
                      />
                    ) : (
                      formatName(neighbor)
                    )}
                    {neighbor.dateOfDeath ? (
                      <Tag type="deceased" tagText="deceased" />
                    ) : null}
                  </Layout>
                }
                lineItemSubHeader={generateList(
                  [
                    {
                      value: neighbor.age ? neighbor.age : null,
                      label: "Age ",
                    },
                    {
                      value: neighbor.currentNeighbor ? "Current" : "Previous",
                      label: " Neighbor",
                      order: "reverse",
                    },
                  ],
                  " | ",
                )}
                lineItemSubHeaderLine2={
                  neighbor.subjectAddress
                    ? `${neighbor.subjectAddress.split("-")[0]}`
                    : null
                }
                key={index}
              />
            ))}
          </CollapsableItemList>
        ) : (
          <Padding space="md">
            <Text tag="b" size="sm">
              No Neighbors Found{" "}
              {pulledReport
                ? `for ${formatName(pulledReport, "abbreviated")}`
                : null}
            </Text>
          </Padding>
        )}
      </Container>
    </Layout>
  );
};

export default PeopleConnections;
