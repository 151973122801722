import {
  Layout,
  Text,
  Padding,
  Icon,
  IconList,
  Monitoring,
  Spotlight,
  Button,
  Modal,
  AnchorLink,
} from "@kidslivesafe/sc-react";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/hooks";

// redux state
import { selectAllReportHistory } from "../../../features/reports/reportsSlice";
import { selectMemberData } from "../../../features/authentication/authenticationSlice";

export interface TimedMonitoringModalProps<T = any> {
  toggleMonitoring: (params: T) => void;
  monitoringInput: T;
  reportTitle: React.ReactNode;
  currentlyMonitoring: boolean;
  loading: boolean;
  monitoringSuccess: "success" | "failed" | "";
}

const TimedMonitoringModal = <T,>({
  reportTitle,
  toggleMonitoring,
  currentlyMonitoring,
  loading,
  monitoringSuccess,
  monitoringInput,
}: TimedMonitoringModalProps<T>) => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(!currentlyMonitoring);
  const allReportHistory = useAppSelector(selectAllReportHistory);

  // required for legacy logins
  let { fromLegacy } = useAppSelector(selectMemberData);

  useEffect(() => {
    if (
      allReportHistory &&
      allReportHistory.length < 4 &&
      !currentlyMonitoring
    ) {
      let originFlag = false;

      const reportsFromMembersite = allReportHistory.filter(report => {
        // only people reports have origin as of right now
        if (report.type === "people") originFlag = true;
        return report.origin !== "Funnel";
      });

      if (
        // this updates based on current pulled report, so its < 3 for non-legacy users
        reportsFromMembersite.length < (originFlag ? 2 : 3) ||
        (fromLegacy && reportsFromMembersite.length < 4)
      ) {
        const showModalTimeout = setTimeout(() => {
          setDisplayModal(true);
        }, 10000);

        return () => clearTimeout(showModalTimeout);
      }
    }
  }, [allReportHistory, currentlyMonitoring, fromLegacy]);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(!currentlyMonitoring);
    }, 2000);
  }, [currentlyMonitoring]);

  const handleMonitoring = () => {
    if (monitoringInput) {
      toggleMonitoring(monitoringInput);
      setTimeout(() => {
        setDisplayModal(false);
      }, 2000);
    }
  };

  const renderContent = () => {
    return (
      <Padding top="lg" bottom="lg" left="md" right="md">
        <Layout
          type="flex"
          flexProps={{
            direction: "column",
            gap: "lg",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Layout
            type="flex"
            flexProps={{
              direction: "column",
              gap: "xxxs",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text tag="h2" fontWeight="bold">
              Ready To Monitor
            </Text>
            <Text textAlign="center" tag="h2" fontWeight="bold">
              {reportTitle}?
            </Text>
          </Layout>
          <Text textAlign="center">
            If we detect changes, we'll notify you with a{" "}
            <Text color="monitoring" tag="span">
              <Icon block="inline" name={IconList.IconMonitoring} />
            </Text>{" "}
            and send you an alert.
          </Text>
          <Spotlight pillshape>
            <Padding space="md">
              <Monitoring
                wiggle
                monitoringTitle={"MONITORING"}
                monitoringInput={"nothing"}
                handleMonitoring={() => {}}
                disabled
              ></Monitoring>
            </Padding>
          </Spotlight>
          <Text textAlign="center" size="sm">
            Your account allows you to monitor this report and up to 9 others.
          </Text>
          {!currentlyMonitoring && monitoringSuccess === "failed" ? (
            <Text fontWeight="semibold" textAlign="center" color={"error"}>
              You’ve reached the limit for Monitoring, manage your monitored
              reports{" "}
              <AnchorLink
                target="_self"
                inline
                stayUnderline
                href="/"
                fontWeight="semibold"
                color="error"
              >
                here
              </AnchorLink>
            </Text>
          ) : currentlyMonitoring && monitoringSuccess === "success" ? (
            <Text fontWeight="semibold" textAlign="center" color={"success"}>
              <Icon block="inline" name={IconList.IconMonitoringSuccess}></Icon>{" "}
              Monitoring enabled!
            </Text>
          ) : !currentlyMonitoring ? (
            <Button
              fill="not-fill"
              handleClick={handleMonitoring}
              buttonText="Enable Monitoring"
            />
          ) : null}
        </Layout>
      </Padding>
    );
  };

  return (
    <>
      {!loading && monitoringInput && showModal ? (
        <Modal
          open={displayModal}
          setOpen={() => {
            setDisplayModal(false);
          }}
          size="fit-content"
          outSideClose
          xClose
        >
          {renderContent()}
        </Modal>
      ) : null}
    </>
  );
};

export default TimedMonitoringModal;
