import React from "react";
import {
  PullPhoneReportParams,
  CombinedListEntry,
} from "../../../features/reports/reportTypes";
import {
  ContainerLineItem,
  Icon,
  IconList,
  Layout,
  LoadingPlaceholder,
  ReportLink,
  Text,
} from "@kidslivesafe/sc-react";
import {
  PullPeopleReportParams,
  usePeopleReportDispatch,
} from "../../../hooks/peopleReportHook";

import { usePhoneReportDispatch } from "../../../hooks/phoneReportHook";

import {
  PullPropertyReportParams,
  usePropertySearchDispatch,
} from "../../../hooks/propertyReportHook";

import { useCurrentBreakpoint } from "@kidslivesafe/sc-foundation";
import { useSocialSearchDispatch } from "../../../hooks/socialSearchHook";
import { useNeighborhoodReportDispatch } from "../../../hooks/neighborhoodReportHook";
import { useAppSelector } from "../../../hooks/hooks";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { useVehicleReportDispatch } from "../../../hooks/vehicleReportHook";

interface ListLineItemProps {
  report: CombinedListEntry | undefined;
  loading?: boolean;
  loadingKey?: string | number;
  onClick?: () => void;
  noPadding?: boolean;
}

const ListLineItem: React.FC<ListLineItemProps> = ({
  report,
  loading = false,
  onClick,
  loadingKey,
  noPadding = false,
}) => {
  const pullPeopleReport = usePeopleReportDispatch();
  const searchSocial = useSocialSearchDispatch();
  const pullPhoneReport = usePhoneReportDispatch();
  const pullPropertyReport = usePropertySearchDispatch();
  const pullNeighborhoodReport = useNeighborhoodReportDispatch();
  const pullVehicleReport = useVehicleReportDispatch();
  const brandInfo = useAppSelector(selectBrandInfo);

  const currentBreakpoint = useCurrentBreakpoint();

  const detailsSpacing = currentBreakpoint === "desktop" ? "md" : "xxs";
  // helper functions.

  const handleStarClick = () => {
    onClick && onClick();
  };

  // render history based on report type
  const renderListItems = (report: CombinedListEntry | undefined) => {
    if (!report) return null;
    switch (report.reportType) {
      case "people":
        return (
          <ContainerLineItem
            key={report.id}
            lineItemHeader={
              <Layout
                type="flex"
                flexProps={{ gap: "xxs", alignItems: "center" }}
              >
                <ReportLink<PullPeopleReportParams>
                  noWrap
                  handleClick={pullPeopleReport}
                  inputValue={report.reportToken}
                  text={report.displayName}
                />
              </Layout>
            }
            lineItemSubHeader={report.reportDetails}
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconPeople} />
                </Text>
                <div onClick={handleStarClick}>
                  <Icon
                    size={32}
                    name={IconList.IconStar}
                    iconColor="#F2B415"
                  />
                </div>
              </Layout>
            }
            noPadding={noPadding}
          />
        );
      case "property":
        return (
          <ContainerLineItem
            key={report.id}
            lineItemHeader={
              <ReportLink<PullPropertyReportParams>
                noWrap
                text={report.displayName}
                inputValue={{
                  addressLine1: report.addressLine1,
                  addressLine2: report.addressLine2,
                  state: report.state,
                  city: report.city,
                  zip: report.zip,
                  nearbySchoolRadius: 5,
                }}
                handleClick={pullPropertyReport}
              />
            }
            lineItemSubHeader={report.reportDetails}
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconProperty} />
                </Text>
                <div onClick={handleStarClick}>
                  <Icon
                    size={32}
                    name={IconList.IconStar}
                    iconColor="#F2B415"
                  />
                </div>
              </Layout>
            }
            noPadding={noPadding}
          />
        );
      case "social":
        const socialText = report.displayName;

        return (
          <ContainerLineItem
            key={report.id}
            lineItemHeader={
              <ReportLink
                noWrap
                inputValue={socialText}
                text={report.displayName}
                handleClick={searchSocial}
              />
            }
            lineItemSubHeader={report.reportDetails}
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconSocial} />
                </Text>
                <div onClick={handleStarClick}>
                  <Icon
                    size={32}
                    name={IconList.IconStar}
                    iconColor="#F2B415"
                  />
                </div>
              </Layout>
            }
            noPadding={noPadding}
          />
        );
      case "phone":
        return (
          <ContainerLineItem
            key={report.id}
            lineItemHeader={
              <ReportLink<PullPhoneReportParams>
                noWrap
                inputValue={report.phone}
                text={report.displayName}
                handleClick={pullPhoneReport}
              />
            }
            lineItemSubHeader={report.reportDetails}
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconPhone} />
                </Text>
                <div onClick={handleStarClick}>
                  <Icon
                    size={32}
                    name={IconList.IconStar}
                    iconColor="#F2B415"
                  />
                </div>
              </Layout>
            }
            noPadding={noPadding}
          />
        );
      case "neighborhood":
        return (
          <ContainerLineItem
            key={report.id}
            lineItemHeader={
              <ReportLink
                noWrap
                inputValue={{
                  addressSearch: {
                    address: report.addressLine1,
                    addressLine2: report.addressLine2,
                    city: report.city,
                    state: report.state,
                    zipCode: report.zip,
                  },
                  searchMethod: "address",
                }}
                text={report.displayName}
                handleClick={pullNeighborhoodReport}
              />
            }
            lineItemSubHeader={report.reportDetails}
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconNeighborhoodWatch} />
                </Text>
                <div onClick={handleStarClick}>
                  <Icon
                    size={32}
                    name={IconList.IconStar}
                    iconColor="#F2B415"
                  />
                </div>
              </Layout>
            }
            noPadding={noPadding}
          />
        );
      case "vehicle":
        return (
          <ContainerLineItem
            key={report.id}
            lineItemHeader={
              <ReportLink
                noWrap
                inputValue={{ vin: report.vin }}
                text={report.displayName}
                handleClick={pullVehicleReport}
              />
            }
            lineItemSubHeader={report.reportDetails}
            lineItemHeaderNoWrap
            lineItemSubheaderNoWrap
            lineItemDetails={
              <Layout
                type="flex"
                flexProps={{ gap: detailsSpacing, alignItems: "center" }}
              >
                <Text
                  tag="div"
                  color={["pf"].includes(brandInfo.acronym) ? "base" : "accent"}
                >
                  <Icon size={32} name={IconList.IconVehicle} />
                </Text>
                <div onClick={handleStarClick}>
                  <Icon
                    size={32}
                    name={IconList.IconStar}
                    iconColor="#F2B415"
                  />
                </div>
              </Layout>
            }
            noPadding={noPadding}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loading ? (
        <ContainerLineItem
          key={loadingKey}
          lineItemHeader={
            <LoadingPlaceholder width={"10rem"} height={"20px"} />
          }
          lineItemDetails={
            <Layout
              type="flex"
              flexProps={{ gap: detailsSpacing, alignItems: "center" }}
            >
              <LoadingPlaceholder width={"32px"} height={"32px"} />
              <div>
                <Layout
                  type="flex"
                  flexProps={{
                    gap: "xxs",
                    direction: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <LoadingPlaceholder width={"55px"} height={"13px"} />
                  <LoadingPlaceholder width={"50px"} height={"13px"} />
                </Layout>
              </div>
            </Layout>
          }
          lineItemSubHeader={<LoadingPlaceholder width={"12rem"} />}
        />
      ) : (
        renderListItems(report)
      )}
    </>
  );
};

export default ListLineItem;
