import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  Layout,
  Text,
  Padding,
  AnchorLink,
} from "@kidslivesafe/sc-react";

import {
  selectMemberData,
  selectMemberExperienceData,
} from "../../features/authentication/authenticationSlice";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { createCustomerRedisTokenAsync } from "../../features/admin/adminSlice";
import { selectBrandInfo } from "../../features/brand/brandSlice";
import { sendMemberEventAsync } from "../../features/admin/adminSlice";

import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../utils";

import {
  getSubscriptionDetailsAsync,
  retryPaymentAsync,
  selectPrimarySubscription,
} from "../../features/subscriptions/subscriptionSlice";

const RetryModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const brandInfo = useAppSelector(selectBrandInfo);
  const memberExperienceData = useAppSelector(selectMemberExperienceData);
  const subscriptionDetails = useAppSelector(selectPrimarySubscription);

  const { customerId, cardType, lastFour } = useAppSelector(selectMemberData);
  const [retryClicked, setRetryClicked] = useState<boolean>(false);
  const [retrySuccess, setRetrySuccess] = useState<boolean>(false);
  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const [retryLoading, setRetryLoading] = useState<boolean>(false);

  useEffect(() => {
    if (subscriptionDetails?.isInRebill) {
      setShowRetryModal(true);
    } else if (retryClicked && retrySuccess) {
      setShowRetryModal(true);
    } else {
      setShowRetryModal(false);
    }
  }, [subscriptionDetails?.isInRebill, retryClicked, retrySuccess]);

  const handleRetryPayment = async () => {
    setRetryLoading(true);
    try {
      const resultAction = await dispatch(retryPaymentAsync());
      if (retryPaymentAsync.fulfilled.match(resultAction)) {
        setRetryClicked(true);
        setRetrySuccess(true);
        setRetryLoading(false);

        // get subscription details
        const subscriptionAction = await dispatch(
          getSubscriptionDetailsAsync(),
        );
        if (getSubscriptionDetailsAsync.fulfilled.match(subscriptionAction)) {
          // console.log("Subscription details fetched successfully");
        }

        setTimeout(() => {
          navigate("/");
        }, 0);
      } else {
        setRetryClicked(true);
        setRetrySuccess(false);
        setRetryLoading(false);
      }
    } catch (error) {
      console.error("Retry failed:", error);
    }
  };

  const handleExternalNavigation = async () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Billing",
        EventAction: "Update Card Clicked",
      }),
    );
    const createCustomerRedisTokenAsyncResponse = await dispatch(
      createCustomerRedisTokenAsync({ customerId }),
    );
    if (
      createCustomerRedisTokenAsync.fulfilled.match(
        createCustomerRedisTokenAsyncResponse,
      )
    ) {
      const uuid = createCustomerRedisTokenAsyncResponse.payload.token;
      window.location.href =
        brandInfo.homeUrl + "/fix-payment?uuid=" + uuid + "&beta=true";
    }
  };

  return (
    <>
      {["spr"].includes(brandInfo.acronym) &&
      memberExperienceData &&
      memberExperienceData.variant &&
      ["m33_spr_dashboard_v1"].includes(memberExperienceData.variant) ? (
        <Modal
          open={showRetryModal}
          setOpen={() => {
            setShowRetryModal(true);
          }}
          size="base"
          xClose
          outSideClose
          scrollable={false}
        >
          <Padding space="sm">
            <Layout
              type="flex"
              flexProps={{
                direction: "column",
                gap: "md",
                alignItems: "center",
              }}
            >
              {!retryClicked &&
              !retrySuccess &&
              subscriptionDetails?.isInRebill ? (
                <>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <div>
                      <Text textAlign="center" tag="h2" fontWeight="semibold">
                        Attention Needed!{" "}
                      </Text>
                    </div>
                    <Text textAlign="center" size="sm">
                      We couldn’t process your{" "}
                      <Text tag="b">
                        {cardType} card ending in {lastFour},{" "}
                      </Text>
                      which has temporarily limited your account&nbsp;access.
                    </Text>
                    <Text textAlign="center" size="sm">
                      To avoid any interruptions to your subscription, please
                      update your payment details or retry your card
                      on&nbsp;file.
                    </Text>
                  </Layout>
                  <Button
                    buttonText="Retry Payment"
                    handleClick={handleRetryPayment}
                    clicked={retryClicked}
                    loading={retryLoading}
                  />
                  <AnchorLink
                    handleClick={handleExternalNavigation}
                    stayUnderline
                    role="button"
                  >
                    Update Payment Method
                  </AnchorLink>
                  <Text textAlign="center" size="sm">
                    For questions please contact member support at{" "}
                    <AnchorLink
                      inline
                      phoneNumber
                      hoverUnderline
                      href={brandInfo.phoneNumber}
                    >
                      {formatPhoneNumber(brandInfo.phoneNumber)}
                    </AnchorLink>
                    .
                  </Text>
                </>
              ) : retryClicked &&
                subscriptionDetails?.isInRebill &&
                !retrySuccess ? (
                <>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "sm",
                    }}
                  >
                    <div>
                      <Text textAlign="center" tag="h2" fontWeight="semibold">
                        We've encountered a problem!{" "}
                      </Text>
                    </div>
                    <Text textAlign="center" size="sm">
                      We couldn’t process your payment, possibly due to an
                      expired or declined card.
                    </Text>
                    <Text textAlign="center" size="sm">
                      Please update your payment details to maintain access to
                      your full subscription.
                    </Text>
                  </Layout>
                  <Button
                    buttonText="Update Payment Method"
                    handleClick={handleExternalNavigation}
                    size="md"
                    fill="fill"
                  />
                  <Text textAlign="center" size="sm">
                    For questions please contact member support at{" "}
                    <AnchorLink
                      inline
                      phoneNumber
                      hoverUnderline
                      href={brandInfo.phoneNumber}
                    >
                      {formatPhoneNumber(brandInfo.phoneNumber)}
                    </AnchorLink>
                    .
                  </Text>
                </>
              ) : retryClicked && retrySuccess && showRetryModal ? (
                <>
                  <Layout
                    type="flex"
                    flexProps={{
                      direction: "column",
                      gap: "lg",
                    }}
                  >
                    <div>
                      <Text textAlign="center" tag="h2" fontWeight="semibold">
                        Success!{" "}
                      </Text>
                    </div>
                    <Text textAlign="center" size="sm">
                      We were able to successfully process your payment.
                    </Text>
                  </Layout>
                  <Button
                    buttonText="Go to Dashboard"
                    handleClick={() => setShowRetryModal(false)}
                    color="success"
                  />
                </>
              ) : null}
            </Layout>
          </Padding>
        </Modal>
      ) : null}
    </>
  );
};

export default RetryModal;
