import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import PrintableComponent from "../../../components/UIComponents/PrintableComponent/PrintableComponent";

import "./print.css"; // used for print styling .page-break could be added
import "./people-report.css";

import {
  Layout,
  Sticky,
  NavigateToSection,
  NavigateToSectionItemProps,
  Padding,
  ReportTitle,
  Sheet,
  ReportSummary,
  LoadingPlaceholder,
} from "@kidslivesafe/sc-react";

import {
  selectIfReportIsOnList,
  selectPeopleReport,
  selectPeopleReportMonitoringDifferences,
  selectPeopleReportStatus,
} from "../../../features/reports/reportsSlice";

import {
  sumArraysWithinObjectsInArray,
  generateList,
  parseDate,
  generatePeopleReportHighlights,
  sortedAssignGenerationsAndCombine,
} from "../../../utils";

// People Report Sections
import ContactInformation from "./ContactInformation";
import PeopleConnections from "./PeopleConnections";
import CriminalRecords from "./CriminalRecords";
import FinancialInfo from "./FinancialInfo";
import CareerDetails from "./CareerDetails";
import LifestyleData from "./LifestyleData";
import MonitoringSection from "./MonitoringSection";
import { Address, Alias } from "../../../features/reports/reportTypes";
import { GridProps } from "@kidslivesafe/sc-react/lib/atoms/Layout/LayoutTypes";
import { checkCurrentlyMonitoring } from "../../../features/monitoring/monitoringSlice";
import {
  MonitoringInterface,
  useToggleMonitoringDispatch,
} from "../../../hooks/monitoringHook";
import useSiteJabberDispatch from "../../../hooks/siteJabberHook";
import { selectBrandInfo } from "../../../features/brand/brandSlice";
import { sendMemberEventAsync } from "../../../features/admin/adminSlice";
import TimedMonitoringModal from "../../../components/UIComponents/TimedMonitoringModal/TimedMonitoringModal";
import ListModal, {
  ListModalMode,
} from "../../../components/UIComponents/ListModal/ListModal";
import FcraCourtRecord from "../../../components/UIComponents/FcraCourtRecord/FcraCourtRecord";

import ReportPaddingWrapper from "../../../components/UIComponents/ReportPaddingWrapper/ReportPaddingWrapper";
import { selectMemberExperienceData } from "../../../features/authentication/authenticationSlice";
import SiteJabberModal from "../../../components/UIComponents/SiteJabberModal/SiteJabberModal";

const PeopleReport: React.FC = () => {
  // local state
  const [navigateProps, setNavigateProps] = useState<
    NavigateToSectionItemProps[]
  >([]);

  const [mode, setMode] = useState<ListModalMode>("add-report");
  const [showListModal, setShowListModal] = useState<boolean>(false);
  const [printReady, setPrintReady] = useState<boolean>(false);
  const contentToPrint = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<((value?: any) => void) | null>(null);
  const dispatch = useAppDispatch();

  let componentFormat: GridProps["templateColumns"] = printReady
    ? "1-column"
    : "1fr-2fr";

  useEffect(() => {
    if (printReady && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [printReady]);

  const { toggleMonitoring, monitoringSuccess } = useToggleMonitoringDispatch();
  const runSiteJabber = useSiteJabberDispatch();

  // store state
  const pulledReport = useAppSelector(selectPeopleReport);
  const currentlyMonitoring = useAppSelector(state =>
    checkCurrentlyMonitoring(state, pulledReport?.reportToken),
  );
  const brandInfo = useAppSelector(selectBrandInfo);
  const peopleReportMonitoringDifferences = useAppSelector(
    selectPeopleReportMonitoringDifferences,
  );
  const peopleReportStatus = useAppSelector(selectPeopleReportStatus);
  const loading = peopleReportStatus === "loading";

  const { foundFlag, list, reportId } = useAppSelector(state =>
    selectIfReportIsOnList(state, pulledReport),
  );

  // m34 experiment - logic start
  const [courtRecordConfirmed, setCourtRecordConfirmed] =
    useState<boolean>(false);
  const [timeoutLoading, setTimeoutLoading] = useState<boolean>(false);
  const memberExperienceData = useAppSelector(selectMemberExperienceData);

  const m34_Experiment =
    ["pdc"].includes(brandInfo.acronym) &&
    memberExperienceData &&
    memberExperienceData.variant &&
    ["m34_pdc_people-report_v1"].includes(memberExperienceData.variant);

  const handleCourtRecordsConfirm = () => {
    setTimeoutLoading(true);
    setCourtRecordConfirmed(true);
    dispatch(
      sendMemberEventAsync({
        EventCategory: "Button Click",
        EventAction: `Court Records Confirm`,
      }),
    );
    setTimeout(() => {
      setTimeoutLoading(false);
    }, 2000);
  };
  // m34 experiment - logic end

  // this is used when the report is pulled to determine if it is on a list
  useEffect(() => {
    if (list && reportId) {
      setMode("remove-report");
    } else {
      setMode("add-report");
    }
    // eslint-disable-next-line
  }, [pulledReport]);

  // this is used when utilizing the list modal and will switch as it is used
  useEffect(() => {
    if (list && reportId) {
      setTimeout(() => {
        setMode("remove-report");
      }, 2000);
    } else {
      setTimeout(() => {
        setMode("add-report");
      }, 2000);
    }
  }, [list, reportId]);

  // constants
  const contactInfoId = "contact-information";
  const peopleConnectionsId = "people-connections";
  const criminalRecordsId = "criminal-records";
  const financialInfoId = "financial-information";
  const careerDetailsId = "career-details";
  const lifestyleDataId = "lifestyle-data";
  const monitoringId = "monitoring-section";

  // effects
  useEffect(() => {
    // Show SiteJabber prompt only for select brands
    if (!loading) {
      runSiteJabber();
    }
  }, [loading]);

  /* generate Navigation Props */
  useEffect(() => {
    setNavigateProps([
      {
        navigateToSectionId: contactInfoId,
        navigateToSectionText: "Contact Information",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray(
              [pulledReport],
              ["addresses", "phoneNumbers", "emailAddresses", "aliases"],
            )
          : 0,
        newInfo: peopleReportMonitoringDifferences?.contactTitle,
        loading: loading,
      },
      {
        navigateToSectionId: peopleConnectionsId,
        navigateToSectionText: "People Connections",
        itemCount: pulledReport
          ? sumArraysWithinObjectsInArray(
              [pulledReport],
              ["associates", "neighbors"],
            ) + sortedAssignGenerationsAndCombine(pulledReport.relatives).length
          : 0,
        newInfo: peopleReportMonitoringDifferences?.peopleTitle,
        loading: loading,
      },
      ...(brandInfo.acronym !== "pf"
        ? [
            {
              navigateToSectionId: criminalRecordsId,
              navigateToSectionText: "Criminal Records",
              itemCount:
                pulledReport && pulledReport.criminalRecords
                  ? sumArraysWithinObjectsInArray(
                      pulledReport.criminalRecords, // note: criminalRecords is an array of objects already
                      ["warrants", "arrests", "crimes"],
                    )
                  : 0,
              newInfo: peopleReportMonitoringDifferences?.criminalTitle,
              loading: loading,
            },
            {
              navigateToSectionId: financialInfoId,
              navigateToSectionText: "Financial Information",
              itemCount: pulledReport
                ? sumArraysWithinObjectsInArray(
                    [pulledReport],
                    [
                      "bankruptcies",
                      "liens",
                      "judgments",
                      "evictions",
                      "propertyForeclosures",
                      "uccFilings",
                    ],
                  )
                : 0,
              newInfo: peopleReportMonitoringDifferences?.financialTitle,
              loading: loading,
            },
            {
              navigateToSectionId: careerDetailsId,
              navigateToSectionText: "Career Details",
              itemCount: pulledReport
                ? sumArraysWithinObjectsInArray(
                    [pulledReport],
                    [
                      "employers",
                      "businessAffiliations",
                      "professionalLicenses",
                      "corporateAffiliations",
                    ],
                  )
                : 0,
              newInfo: peopleReportMonitoringDifferences?.careerTitle,
              loading: loading,
            },
            {
              navigateToSectionId: lifestyleDataId,
              navigateToSectionText: "Lifestyle Data",
              itemCount: pulledReport
                ? sumArraysWithinObjectsInArray(
                    [pulledReport],
                    [
                      "vehicles",
                      "pilotLicenses",
                      "huntingPermits",
                      "concealedWeaponPermits",
                      "voterRegistrations",
                      "driversLicenses",
                    ],
                  )
                : 0,
              newInfo: peopleReportMonitoringDifferences?.lifestyleTitle,
              loading: loading,
            },
          ]
        : []),
    ]);
  }, [
    pulledReport,
    loading,
    peopleReportMonitoringDifferences,
    brandInfo.acronym,
  ]);

  const generateReportSummaryAddress = (address: Address) => {
    return (
      <>
        <div>{address.fullAddress}</div>
        <div>
          {address.city}, {address.state}{" "}
          {address.postalCode ? address.postalCode.split("-")[0] : ""}
        </div>
      </>
    );
  };

  const generateTopThreeAliases = (aliases: Alias[]) => {
    let aliasList = [];
    for (let i = 1; i < 4; i++) {
      if (aliases[i]) {
        aliasList.push({ value: aliases[i].fullName, label: "" });
      }
    }
    return aliasList.length > 0 ? `aka: ${generateList(aliasList, ", ")}` : "";
  };

  const sendMemberEvent = () => {
    dispatch(
      sendMemberEventAsync({
        EventCategory: "People Report",
        EventAction: "Download Express",
        MetaData: JSON.stringify({
          key: "pdfDownload",
          value: `Report Name: ${
            pulledReport && pulledReport.aliases
              ? pulledReport.aliases[0].fullName
              : ""
          }`,
        }),
      }),
    );
  };

  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current as HTMLElement,
    onBeforeGetContent: () => {
      return new Promise<void>(resolve => {
        const originalTitle = document.title;
        document.title =
          pulledReport && pulledReport.aliases && pulledReport.aliases[0]
            ? `${originalTitle} - ${pulledReport.aliases[0].fullName}`
            : originalTitle;
        setPrintReady(true);

        // Function to check if elements with the specific class still exist
        const checkIfElementsClosed = () => {
          const elements = document.querySelectorAll(
            ".sc-container-line-item__content--closed",
          );
          const showMoreElements = document.querySelectorAll(
            ".sc-show-more__icon--closed",
          );
          if (elements.length === 0 && showMoreElements.length === 0) {
            // No elements found, resolve the promise to proceed with printing
            resolve();
          } else {
            // Elements still found, check again after a short delay
            setTimeout(checkIfElementsClosed, 50);
          }
        };

        // Start the checking process
        checkIfElementsClosed();

        // Ensure the title is reset after printing is triggered
        setTimeout(() => {
          document.title = originalTitle; // Restore the original document title
        }, 5000);
      });
    },
    onAfterPrint: () => {
      setPrintReady(false);
    },
    copyStyles: true,
  });

  return (
    <>
      <PrintableComponent
        ref={contentToPrint}
        printReady={printReady}
        reportType="people"
        header={
          pulledReport && pulledReport.aliases
            ? pulledReport.aliases[0].fullName
            : undefined
        }
        subtitle={
          pulledReport
            ? generatePeopleReportHighlights(pulledReport)
            : undefined
        }
        date={
          pulledReport && pulledReport.reportSummary
            ? pulledReport.reportSummary.version
            : undefined
        }
      >
        <ReportTitle
          reportTitle="People Report"
          reportType="people"
          handleAddToList={() => setShowListModal(true)}
          addedToList={loading ? false : foundFlag}
          handleDownload={
            loading
              ? () => {
                  console.log("Wait for loading");
                }
              : () => {
                  sendMemberEvent();
                  handlePrint(null, () => null);
                }
          }
        />
        <Sheet>
          <Padding
            space="none"
            bottom="md"
            breakpointStyles={{
              desktop: {
                space: "md",
                top: "none",
              },
            }}
          >
            <Layout
              type="grid"
              gridProps={{ gap: "md", templateColumns: "1-column" }}
              breakpointStyles={{
                desktop: {
                  type: "grid",
                  gridProps: { templateColumns: componentFormat, gap: "md" },
                },
              }}
              shrinkChildren
            >
              <>
                <Layout
                  type="flex"
                  flexProps={{ gap: "md", direction: "column" }}
                  passThroughClassess="page-people-report__left-column"
                >
                  <Sticky
                    space="sm"
                    turnOffAtBreakPoint={["mobile"]}
                    direction="header-offset"
                  >
                    <ReportPaddingWrapper>
                      <Layout
                        type="flex"
                        flexProps={{ direction: "column", gap: "md" }}
                      >
                        <ReportSummary<MonitoringInterface>
                          reportDate={
                            loading ? (
                              <LoadingPlaceholder
                                height={"16px"}
                                width={"10rem"}
                              />
                            ) : pulledReport &&
                              pulledReport.reportSummary.version ? (
                              `Report Date ${parseDate(
                                pulledReport.reportSummary.version,
                                "MM/DD/YYYY",
                              )}`
                            ) : (
                              ""
                            )
                          }
                          reportTitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"25px"}
                                width={"15rem"}
                              />
                            ) : pulledReport && pulledReport.aliases ? (
                              pulledReport.aliases[0].fullName
                            ) : (
                              ""
                            )
                          }
                          reportSubtitle={
                            loading ? (
                              <LoadingPlaceholder
                                height={"14px"}
                                width={"10rem"}
                              />
                            ) : pulledReport && pulledReport.aliases ? (
                              generateTopThreeAliases(pulledReport.aliases)
                            ) : (
                              ""
                            )
                          }
                          reportHighlights={
                            loading ? (
                              <LoadingPlaceholder
                                height={"18px"}
                                width={"12rem"}
                              />
                            ) : pulledReport ? (
                              generatePeopleReportHighlights(pulledReport)
                            ) : null
                          }
                          reportDetails={
                            loading ? (
                              <LoadingPlaceholder
                                height={"24px"}
                                width={"10rem"}
                              />
                            ) : pulledReport && pulledReport.addresses[0] ? (
                              generateReportSummaryAddress(
                                pulledReport.addresses[0],
                              )
                            ) : null
                          }
                          newInfo={
                            peopleReportMonitoringDifferences?.anyReportDifference
                          }
                          monitoringInput={
                            pulledReport &&
                            pulledReport.reportSummary.version &&
                            pulledReport.reportToken
                              ? {
                                  reportToken: pulledReport.reportToken,
                                  version: pulledReport.reportSummary.version,
                                }
                              : undefined
                          }
                          monitoringInitialState={
                            loading ? false : currentlyMonitoring
                          }
                          handleMonitoring={
                            loading
                              ? () => {
                                  console.log("Wait for loading");
                                }
                              : toggleMonitoring
                          }
                          monitoringSuccess={monitoringSuccess}
                          monitoringWiggle
                        />
                        <NavigateToSection
                          items={navigateProps}
                          tooltipContent={`The ${brandInfo.name} People Report provides you with many different types of data and information. The Navigate to Section menu provides access and counts of records contained in the report for each category of data.`}
                        />
                      </Layout>
                    </ReportPaddingWrapper>
                  </Sticky>
                </Layout>
                <Padding
                  space="none"
                  breakpointStyles={{ desktop: { top: "md" } }}
                >
                  <Layout
                    type="flex"
                    flexProps={{ direction: "column", gap: "md" }}
                  >
                    {/* people report sections */}
                    <ReportPaddingWrapper>
                      <ContactInformation
                        sectionId={contactInfoId}
                        printReady={printReady}
                      />
                    </ReportPaddingWrapper>
                    <MonitoringSection
                      sectionId={monitoringId}
                      printReady={printReady}
                      toggleMonitoring={toggleMonitoring}
                      currentlyMonitoring={currentlyMonitoring}
                      monitoringSuccess={monitoringSuccess}
                    />
                    <ReportPaddingWrapper>
                      <PeopleConnections
                        sectionId={peopleConnectionsId}
                        printReady={printReady}
                      />
                    </ReportPaddingWrapper>

                    {brandInfo.name === "Property Focus" ? null : (
                      <>
                        {/* m34 experiment - start */}
                        {!courtRecordConfirmed &&
                        m34_Experiment &&
                        !loading &&
                        !printReady ? (
                          <FcraCourtRecord
                            handleClick={handleCourtRecordsConfirm}
                            sectionId={criminalRecordsId}
                          />
                        ) : (
                          <>
                            <ReportPaddingWrapper>
                              <CriminalRecords
                                sectionId={criminalRecordsId}
                                printReady={printReady}
                                handleTimeoutLoading={timeoutLoading}
                              />
                            </ReportPaddingWrapper>
                            <ReportPaddingWrapper>
                              <FinancialInfo
                                sectionId={financialInfoId}
                                printReady={printReady}
                                handleTimeoutLoading={timeoutLoading}
                              />
                            </ReportPaddingWrapper>
                          </>
                        )}
                        {/* m34 experiment - end */}
                        <ReportPaddingWrapper>
                          <CareerDetails
                            sectionId={careerDetailsId}
                            printReady={printReady}
                          />
                        </ReportPaddingWrapper>
                        <ReportPaddingWrapper>
                          <LifestyleData
                            sectionId={lifestyleDataId}
                            printReady={printReady}
                          />
                        </ReportPaddingWrapper>
                      </>
                    )}
                    {/* end of people report sections */}
                  </Layout>
                </Padding>
              </>
            </Layout>
          </Padding>
        </Sheet>
        {pulledReport &&
          pulledReport.reportToken &&
          pulledReport.reportSummary.version && (
            <TimedMonitoringModal<MonitoringInterface>
              reportTitle={
                pulledReport && pulledReport.aliases
                  ? pulledReport.aliases[0].fullName
                  : ""
              }
              toggleMonitoring={toggleMonitoring}
              monitoringInput={{
                reportToken: pulledReport.reportToken,
                version: pulledReport.reportSummary.version,
              }}
              currentlyMonitoring={currentlyMonitoring}
              monitoringSuccess={monitoringSuccess}
              loading={loading}
            />
          )}
      </PrintableComponent>
      <ListModal
        modalMode={mode}
        setModalMode={setMode}
        displayModal={showListModal}
        setDisplayModal={setShowListModal}
        report={pulledReport}
        reportId={reportId ? reportId : undefined}
        list={list ? list : undefined}
      />
      <SiteJabberModal />
      <div className="stjr-instant-feedback"></div>
    </>
  );
};

export default PeopleReport;
