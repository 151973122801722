import React, { useState } from "react";

import {
  Padding,
  Layout,
  Sheet,
  Container,
  CollapsableItemList,
  ReportTitle,
  Text,
  ContainerLineItem,
  AnchorLink,
} from "@kidslivesafe/sc-react";

import "./lists.css";

import { useAppSelector } from "../../../hooks/hooks";
import {
  selectListStatus,
  selectReportLists,
} from "../../../features/reports/reportsSlice";
import ListLineItem from "../../../components/UIComponents/ListLineItem/ListLineItem";
import ListModal, {
  ListModalMode,
} from "../../../components/UIComponents/ListModal/ListModal";
import { parseDate } from "../../../utils";
import { CombinedListEntry } from "../../../features/reports/reportTypes";

const Lists: React.FC = () => {
  const [mode, setMode] = useState<ListModalMode>("remove-report");
  const [showListModal, setShowListModal] = useState<boolean>(false);
  const [savedReportsList, setSavedReportsList] = useState<any>();
  const [savedReportId, setSavedReportId] = useState<number>(0);

  const lists = useAppSelector(selectReportLists);

  const setToggleModal = () => {
    setShowListModal((prevState: any) => !prevState);
  };

  const handleRemoveItem = async (report: CombinedListEntry) => {
    const selectedList = lists?.find(
      item => item.savedReportsListId === report.savedReportsListId,
    );
    if (selectedList) {
      setSavedReportsList(selectedList);
    }
    setSavedReportId(report.id);
    setMode("remove-report");
    setToggleModal();
  };

  const listStatus = useAppSelector(selectListStatus);
  const loading = listStatus === "loading";

  const renderFavoritesList = () => {
    const favoritesList = lists?.filter(list => list.listName === "Favorites");
    return (
      <>
        {favoritesList && favoritesList.length > 0 ? (
          favoritesList.map(list =>
            list ? (
              <ContainerLineItem
                lineItemHeader={<Text tag="h3">{list.listName}</Text>}
                lineItemSubHeader={
                  list.lastActivityDate
                    ? `Created ${parseDate(list.dateCreated, "MM/DD/YYYY")} | Modified ${parseDate(list.lastActivityDate, "MM/DD/YYYY")}`
                    : list.dateCreated
                }
                lineItemSubHeaderLine2={
                  list.savedReports?.length
                    ? `${list.savedReports.length} Reports`
                    : "0 Reports"
                }
                key={list.savedReportsListId}
              >
                <CollapsableItemList>
                  {list.savedReports?.length ? (
                    list.savedReports.map((report, index) =>
                      report ? (
                        <Padding
                          space="md"
                          key={report.id}
                          top={index > 0 ? "md" : "none"}
                        >
                          <ListLineItem
                            report={report}
                            onClick={() => handleRemoveItem(report)}
                            noPadding
                          />
                        </Padding>
                      ) : null,
                    )
                  ) : (
                    <Padding space="md">
                      <Text size="xs">
                        Your Favorites list is empty. Save any report to this
                        list from the report page.
                      </Text>
                    </Padding>
                  )}
                </CollapsableItemList>
              </ContainerLineItem>
            ) : null,
          )
        ) : (
          <ContainerLineItem
            lineItemHeader={<Text tag="h3">Favorites</Text>}
            key="favorites"
            lineItemSubHeader="0 Reports"
          >
            <Padding space="md" top="none">
              <Text size="xs">
                Your Favorites list is empty. Save any report to this list from
                the report page.
              </Text>
            </Padding>
          </ContainerLineItem>
        )}
      </>
    );
  };

  return (
    <>
      <ReportTitle reportTitle="Favorites and Lists" />
      <Sheet>
        <Padding space="md" bottom="lg">
          <Layout type="flex" flexProps={{ direction: "column", gap: "md" }}>
            {/* favorites list */}
            <Container>{renderFavoritesList()}</Container>
            {/* END favorites list */}
            {lists
              ? lists
                  .filter(list => list.listName !== "Favorites")
                  .sort((a, b) => {
                    if (a.lastActivityDate > b.lastActivityDate) {
                      return -1;
                    }
                    if (a.lastActivityDate < b.lastActivityDate) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((list, index) => (
                    <Container key={list.savedReportsListId}>
                      <ContainerLineItem
                        lineItemHeader={
                          <Layout
                            type="flex"
                            flexProps={{ alignItems: "center", gap: "xxs" }}
                          >
                            <Text tag="h3">{list.listName}</Text>
                            <AnchorLink
                              size="xxs"
                              color="primary"
                              role="button"
                              hoverUnderline
                              handleClick={() => {
                                setMode("rename-list");
                                setSavedReportsList(list);
                                setToggleModal();
                              }}
                            >
                              (rename)
                            </AnchorLink>
                          </Layout>
                        }
                        lineItemSubHeader={
                          list.lastActivityDate
                            ? `Created ${parseDate(list.dateCreated, "MM/DD/YYYY")} | Modified ${parseDate(list.lastActivityDate, "MM/DD/YYYY")}`
                            : list.dateCreated
                        }
                        lineItemSubHeaderLine2={
                          list.savedReports?.length
                            ? `${list.savedReports.length} Reports`
                            : "0 Reports"
                        }
                      >
                        <CollapsableItemList numberToShow={100}>
                          {loading ? (
                            Array(5)
                              .fill(null)
                              .map((_, index) => (
                                <ListLineItem
                                  report={undefined}
                                  loading={loading}
                                  loadingKey={index}
                                  key={index}
                                />
                              ))
                          ) : list.savedReports?.length ? (
                            list.savedReports.map((report, index) =>
                              report ? (
                                <Padding
                                  space="md"
                                  key={report.id}
                                  top={index > 0 ? "md" : "none"}
                                >
                                  <ListLineItem
                                    report={report}
                                    loadingKey={report.id}
                                    onClick={() => handleRemoveItem(report)}
                                    noPadding
                                  />
                                </Padding>
                              ) : null,
                            )
                          ) : (
                            <Padding space="md">
                              <Text>No Lists</Text>
                            </Padding>
                          )}
                        </CollapsableItemList>
                      </ContainerLineItem>
                    </Container>
                  ))
              : null}
          </Layout>
        </Padding>
      </Sheet>
      <ListModal
        modalMode={mode}
        setModalMode={setMode}
        displayModal={showListModal}
        setDisplayModal={setShowListModal}
        reportId={savedReportId}
        list={savedReportsList}
      />
    </>
  );
};

export default Lists;
