import { CombinedReports } from "../features/reports/reportsSlice";
import { ReportList } from "../features/reports/reportTypes";
import { RootState } from "../store";
import { renderSocialReportType } from "./renderSocialReportType";

export function checkIfReportIsOnList(
  state: RootState,
  report?: CombinedReports,
): {
  foundFlag: boolean;
  list: ReportList | undefined;
  reportId: number | undefined;
} {
  if (!report) {
    return {
      foundFlag: false,
      list: undefined,
      reportId: undefined,
    };
  }

  let foundFlag = false;
  let list: ReportList | undefined = undefined;
  let reportId: number | undefined = undefined;

  state.reports.reportLists?.forEach(reportList => {
    if (
      reportList.savedReports.some(
        savedReport => savedReport.reportType === report.reportType,
      )
    ) {
      switch (report.reportType) {
        case "people":
          reportList.savedReports.some(savedReport => {
            if (
              savedReport.reportType === report.reportType &&
              savedReport.reportToken === report.reportToken
            ) {
              list = reportList;
              foundFlag = true;
              reportId = savedReport.id;
              return true;
            }
            return false;
          });
          break;

        case "phone":
          reportList.savedReports.some(savedReport => {
            if (
              savedReport.reportType === report.reportType &&
              savedReport.phone === report.phoneReportSummary.phone
            ) {
              list = reportList;
              foundFlag = true;
              reportId = savedReport.id;
              return true;
            }
            return false;
          });
          break;

        case "property":
          reportList.savedReports.some(savedReport => {
            if (
              savedReport.reportType === report.reportType &&
              savedReport.addressLine1 ===
                report.propertyDetails?.addressLine1 &&
              savedReport.zip === report.propertyDetails?.postalCode &&
              savedReport.city === report.propertyDetails?.city &&
              savedReport.state === report.propertyDetails?.state
            ) {
              list = reportList;
              foundFlag = true;
              reportId = savedReport.id;
              return true;
            }
            return false;
          });
          break;

        case "social":
          reportList.savedReports.some(savedReport => {
            const socialReportType = renderSocialReportType(
              report.socialReportSummary,
            );

            if (
              socialReportType &&
              savedReport.reportType === report.reportType &&
              savedReport.socialToken ===
                report.socialReportSummary[socialReportType]
            ) {
              list = reportList;
              foundFlag = true;
              reportId = savedReport.id;
              return true;
            }
            return false;
          });
          break;

        case "neighborhood":
          reportList.savedReports.some(savedReport => {
            if (
              savedReport.reportType === report.reportType &&
              (report.addressObject.address // for neighborhoods without addressLine1
                ? report.addressObject.address === savedReport.addressLine1
                : true) &&
              savedReport.zip === report.addressObject?.zipCode &&
              savedReport.city === report.addressObject?.city &&
              savedReport.state === report.addressObject?.state
            ) {
              list = reportList;
              foundFlag = true;
              reportId = savedReport.id;
              return true;
            }
            return false;
          });
          break;
        case "vehicle":
          reportList.savedReports.some(savedReport => {
            if (
              savedReport.reportType === report.reportType &&
              savedReport.vin === report.vehicleSummary.vin
            ) {
              list = reportList;
              foundFlag = true;
              reportId = savedReport.id;
              return true;
            }
            return false;
          });
          break;
      }
    }
  });

  return { foundFlag, list, reportId };
}
