import React from "react";

import { useAppSelector } from "../../../hooks/hooks";
import {
  selectVehicleReportHistory,
  selectReportHistoryStatus,
} from "../../../features/reports/reportsSlice";

import {
  CollapsableItemList,
  Container,
  Padding,
  Text,
} from "@kidslivesafe/sc-react";
import ActivityHistoryLineItem from "../../../components/UIComponents/ActivityHistory/ActivityHistoryLineItem";

const VehicleReportHistoryContainer: React.FC = () => {
  const reportHistory = useAppSelector(selectVehicleReportHistory);
  const reportHistoryStatus = useAppSelector(selectReportHistoryStatus);

  const loading = reportHistoryStatus === "loading";

  return (
    <Padding left="md" right="md">
      <Container
        containerTitle="Vehicle Report History"
        containerId="dashboard__activity-history__container"
      >
        <CollapsableItemList>
          {loading ? (
            Array(5)
              .fill(null)
              .map((_, index) => (
                <ActivityHistoryLineItem
                  report={undefined}
                  loading={loading}
                  loadingKey={index}
                  key={index}
                />
              ))
          ) : reportHistory && reportHistory.length > 0 ? (
            reportHistory.map(report =>
              report ? (
                <ActivityHistoryLineItem
                  report={report}
                  loadingKey={report.score}
                  key={report.score}
                />
              ) : null,
            )
          ) : (
            <Padding space="md">
              <Text size="xs">
                Get started by searching an email, phone number, or Twitter
                handle above.
              </Text>
            </Padding>
          )}
        </CollapsableItemList>
      </Container>
    </Padding>
  );
};

export default VehicleReportHistoryContainer;
